export const FETCH_USER = "FETCH_USER";
export const FETCH_SIGHTS = "FETCH_SIGHTS";
export const SELECT_SIGHT = "SELECT_SIGHT";
export const FETCH_USER_DATA = "FETCH_USER_DATA";
export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_ROOMS = "FETCH_ROOMS";
export const FETCH_CALENDARS = "FETCH_CALENDARS";
export const FETCH_MANUALS = "FETCH_MANUALS";
export const FETCH_DEVICES = "FETCH_DEVICES";
export const FETCH_SCHEDULES = "FETCH_SCHEDULES";
export const FETCH_SETTINGS = "FETCH_SETTINGS";
export const FETCH_QR_CSV_DATA = "FETCH_QR_CSV_DATA";
export const FETCH_CLIENTS = "FETCH_CLIENTS";
export const SELECT_CLIENT = "SELECT_CLIENT";
export const GATEWAY_DISABLED_CHANGE = "GATEWAY_DISABLED_CHANGE";
export const GATEWAY_RESTART_CHANGE = "GATEWAY_RESTART_CHANGE";
