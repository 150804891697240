import React, { useState } from "react";
import Typography from "@mui/material/Typography";
// import { mdiAlphaEBoxOutline } from "@mdi/js";
// import Icon from "@mdi/react";
// import Tooltip from '@mui/material/Tooltip';

const STATUS_ONLINE = 0;
const STATUS_UNKNOWN_ERROR = 1;
const STATUS_SIGNAL_ERROR = 2;
const STATUS_MECHANICAL_ERROR = 3;
const STATUS_DEVICE_OFF = 4;
const STATUS_FLAKY_SIGNAL = 5;

function Device({ top, left, device, size, scale }) {
  const [showId, setShowId] = useState(false);
  const deviceScale = scale ? scale : 1;
  const status = device.status;
  const isReachable = device && device.status === STATUS_ONLINE;
  const title =
    status === STATUS_UNKNOWN_ERROR
      ? "Connection timeout"
      : status === STATUS_SIGNAL_ERROR
      ? "Signal error"
      : status === STATUS_FLAKY_SIGNAL
      ? "Weak signal"
      : status === STATUS_MECHANICAL_ERROR
      ? "Device offline due to mechanical error"
      : status === STATUS_DEVICE_OFF
      ? "The device is turned off"
      : "";
  // console.log(showId)
  return (
    <div
      onMouseEnter={() => {
        // console.log("enter");
        setShowId(true);
      }}
      onMouseLeave={() => {
        // console.log("out");
        setShowId(false);
      }}
      style={{
        position: "absolute",
        zIndex: 3,
        top: 8,
        left: 8,
        width: 20 / deviceScale,
        height: 20 / deviceScale,
        color: !isReachable ? (status === STATUS_FLAKY_SIGNAL ? "orange" : "red") : "#1a6e31",
      }}
    >
      <div
        style={{
          top: top - 5 / deviceScale,
          left: left - 5 / deviceScale,
          color: !isReachable && "red",
          width: 40 / deviceScale,
          height: 40 / deviceScale,
          position: "absolute",
          borderRadius: "50%",
          zIndex: 3,
        }}
      ></div>
      {isReachable ? (
        <img
          src="/favicon.ico"
          alt="device"
          style={{
            position: "absolute",
            zIndex: 3,
            width: 25 / deviceScale,
            height: 25 / deviceScale,
            top: top,
            left: left,
            color: !isReachable ? (status === STATUS_FLAKY_SIGNAL ? "orange" : "red") : "#1a6e31",
          }}
        />
      ) : (
        <>
          {/* Need a red EcoSync logo here */}
          <img
            src={status === STATUS_FLAKY_SIGNAL ? "/ecosync_logo_orange.png" : "/ecosync_logo_red2.png"}
            alt="device"
            style={{
              position: "absolute",
              zIndex: 3,
              width: 25 / deviceScale,
              height: 25 / deviceScale,
              top: top,
              left: left,
              color: !isReachable ? (status === STATUS_FLAKY_SIGNAL ? "orange" : "red") : "#1a6e31",
            }}
          />
        </>
      )}
      <div
        style={{
          flexDirection: "column",
          display: "flex",
          position: "absolute",
          top: top + 30 / deviceScale,
          width: 220 / deviceScale,
          left: left - 80 / deviceScale,
        }}
      >
        {showId && (
          <Typography
            style={{
              zIndex: 3,
              fontWeight: "bold",
              fontSize: 18 / deviceScale,
              color: !isReachable ? (status === STATUS_FLAKY_SIGNAL ? "orange" : "red") : "#1a6e31",
            }}
          >
            {device.name}
          </Typography>
        )}
        {showId && !isReachable && (
          <Typography
            style={{
              zIndex: 3,
              fontWeight: "bold",
              fontSize: 18 / deviceScale,
              color: status === STATUS_FLAKY_SIGNAL ? "orange" : "red",
            }}
          >
            {title}
          </Typography>
        )}
      </div>
    </div>
  );
}

export default Device;
