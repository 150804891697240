import React from "react";
import moment from "moment";
import "moment-timezone";
import CircularProgress from "@material-ui/core/CircularProgress";
import { functions } from "../../config/firebase";
import { eventTitle } from "../../constants/events";

import {
  // LineChart,
  ComposedChart,
  Line,
  YAxis,
  Scatter,
  XAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  Bar,
  Area,
} from "recharts";
import { fetchSettings } from "../../actions";

const {
  FIRE,
  OPEN_WINDOW,
  NO_HEATING,
  NO_HEATING_CONTROL,
  STUCK_PIN,
  OFFLINE_TRV,
  OFFLINE_GW,
  MECHANICAL_ERROR,
  CELLULAR_GW,
  REFERENCE_RUN,
  MANUAL_REFERENCE_RUN,
  EXTRA_HEAT_SOURCE,
  AWAY_LOW_TEMP,
} = require("../../constants/events");

class TempGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      graphData: [],
      min: 0,
      max: 100,
      roomId: this.props.roomId,
      homeId: this.props.homeId,
      isLoading: true,
      date_begin: this.props.date_begin,
      date_end: this.props.date_end,
      date_type: this.date_type,
      bookedHistory: this.props.bookedHistory,
      timezone: this.props.timezone, // default timezone: "Europe/London"
      FIRE: FIRE,
      OPEN_WINDOW: OPEN_WINDOW,
      NO_HEATING: NO_HEATING,
      NO_HEATING_CONTROL: NO_HEATING_CONTROL,
      STUCK_PIN: STUCK_PIN,
      OFFLINE_TRV: OFFLINE_TRV,
      OFFLINE_GW: OFFLINE_GW,
      MECHANICAL_ERROR: MECHANICAL_ERROR,
      CELLULAR_GW: CELLULAR_GW,
      REFERENCE_RUN: REFERENCE_RUN,
      MANUAL_REFERENCE_RUN: MANUAL_REFERENCE_RUN,
      EXTRA_HEAT_SOURCE: EXTRA_HEAT_SOURCE,
      AWAY_LOW_TEMP: AWAY_LOW_TEMP,
    };
  }

  dateFormat = (time) => {
    const timezone = this.state.timezone;
    if (this.state.date_type === "isoWeek") {
      return moment.unix(time).tz(timezone).format("ddd, DD");
    }
    if (this.state.date_type === "month") {
      return moment.unix(time).tz(timezone).format("MMM DD");
    }
    if (this.state.date_type === "year") {
      return moment.unix(time).tz(timezone).format("MMMM");
    }
    return moment.unix(time).tz(timezone).format("HH:mm");
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.roomId !== this.props.roomId) {
      this.setState(
        {
          roomId: nextProps.roomId,
          isLoading: true,
          homeId: nextProps.homeId,
          bookedHistory: nextProps.bookedHistory,
          timezone: nextProps.timezone,
        },
        () => this.loadData()
      );
    }

    if (nextProps.date_begin !== this.props.date_begin) {
      this.setState(
        {
          date_begin: nextProps.date_begin,
          date_end: nextProps.date_end,
          isLoading: true,
        },
        () => this.loadData()
      );
    }
    if (nextProps.date_type !== this.props.date_type) {
      this.setState({
        date_type: nextProps.date_type,
        isLoading: true,
      });
    }
  }

  async componentDidMount() {
    //set selects
    await this.loadData();
  }

  async loadData() {
    let scale = "max";

    if (this.state.date_type === "isoWeek") {
      scale = "3hours";
    } else if (this.state.date_type === "month") {
      scale = "1day";
    } else if (this.state.date_type === "year") {
      scale = "1week";
    }

    if (this.state.roomId) {
      const getRoomMeasure = functions.httpsCallable("getRoomMeasureCall");
      const getRoomEvents = functions.httpsCallable("getRoomEventsCall");
      try {
        const data = await getRoomMeasure({
          scale: scale,
          room_id: this.state.roomId,
          home_id: this.state.homeId,
          date_begin: this.state.date_begin,
          date_end: this.state.date_end,
        });
        let last_t_sp = null;
        let values = data.data.map((d) => {
          if (last_t_sp && d.t_sp === null) d.t_sp = last_t_sp;
          last_t_sp = d.t_sp;
          return [parseFloat(d.t), parseFloat(d.t_sp), parseFloat(d.t_fl), parseFloat(d.t_s)];
        });

        values = values.flat();
        values = values.filter((v) => !isNaN(v));
        let max = Math.round(Math.max(...values) + 2);
        let min = Math.round(Math.min(...values) - 2);
        max = parseFloat(max.toFixed(2));
        min = parseFloat(min.toFixed(2));
        let dataScaled = data.data.map((d) => {
          d.barHeight = ((max - min) * d.t_hpr) / 100 + min;
          d.hBarHeight = ((max - min) * d.t_h) / 100 + min;
          return d;
        });

        try {
          const eventData = await getRoomEvents({
            room_id: this.state.roomId,
            home_id: this.state.homeId,
            date_begin: this.state.date_begin,
            date_end: this.state.date_end,
          });
          if (typeof eventData === "undefined") {
            console.error([
              "getRoomEventsCall error",
              {
                room_id: this.state.roomId,
                home_id: this.state.homeId,
                date_begin: this.state.date_begin,
                date_end: this.state.date_end,
              },
            ]);
          }
          const isSuperAdmin = this.props.isSuperAdmin;
          const visibleNotifications = this.props.visibleNotifications;
          Object.keys(eventData.data).forEach((eventTimestamp) => {
            if (typeof eventData.data[eventTimestamp] != "undefined" && eventData.data[eventTimestamp] !== null) {
              if (isSuperAdmin) {
                dataScaled.push({
                  timestamp: eventTimestamp,
                  type: eventData.data[eventTimestamp].type,
                  scatterHeight: min,
                });
              } else {
                visibleNotifications.forEach((vn) => {
                  if (
                    this.state[vn] === eventData.data[eventTimestamp].type &&
                    eventData.data[eventTimestamp].live === true
                  ) {
                    dataScaled.push({
                      timestamp: eventTimestamp,
                      type: eventData.data[eventTimestamp].type,
                      scatterHeight: min,
                    });
                  }
                });
              }
            }
          });
        } catch (e) {
          console.log(e);
        }

        dataScaled = dataScaled.sort(function (a, b) {
          return a.timestamp - b.timestamp;
        });

        dataScaled.forEach((ds, index) => {
          if (
            typeof ds != "undefined" &&
            typeof ds.type !== "undefined" &&
            (!ds.t || !ds.t_fl || !ds.t_s || !ds.t_hpr || !ds.t_h || !ds.t_sp) &&
            typeof dataScaled[index - 1] !== "undefined"
          ) {
            dataScaled[index - 1].type = ds.type;
            dataScaled[index - 1].scatterHeight = min;
          }
        });
        dataScaled = dataScaled.filter((ds) => ds.t || ds.t_fl || ds.t_s || ds.t_hpr || ds.t_h || ds.t_sp);

        if (this.state.bookedHistory && this.state.bookedHistory[this.state.homeId]) {
          const bookedHome = this.state.bookedHistory[this.state.homeId];
          const bookedMap = bookedHome[this.state.roomId];
          if (bookedMap) {
            bookedMap.forEach((bookH) => {
              dataScaled.forEach((ds) => {
                if (bookH.start <= parseInt(ds.timestamp) * 1000 && bookH.end >= parseInt(ds.timestamp) * 1000) {
                  ds.oc = max;
                  // console.log(ds.timestamp);
                }
              });
            });
          }
        }

        this.setState({
          graphData: dataScaled,
          isLoading: false,
          min: min,
          max: max,
        });
      } catch (e) {
        console.log(e);
      }
    }
  }

  render() {
    const notificationTexts = this.props.notificationTexts;
    const { graphData, isLoading, min, max } = this.state;
    if (isLoading) {
      return <CircularProgress className="progress" />;
    }

    return (
      <div>
        <ComposedChart width={1000} height={500} data={graphData} margin={{ top: 30, right: 30, left: 0, bottom: 5 }}>
          <XAxis dataKey="timestamp" tickFormatter={this.dateFormat} name="Time" />
          <YAxis type="number" domain={[min, max]} />
          <Tooltip content={<CustomTooltip notificationTexts={notificationTexts} timezone={this.state.timezone} />} />

          <CartesianGrid strokeDasharray="3 3" />
          <Legend />
          <Area dataKey="oc" fill="#c0f5ba" name="Booked" />
          <Bar dataKey="barHeight" fill="#c5c2f0" name="Valve On %" barSize={2} />
          {graphData[0] && graphData[0].t_h && (
            <Bar dataKey="hBarHeight" fill="#ADD8E6" name="Sensor Humidity" barSize={2} />
          )}
          <Scatter dataKey="scatterHeight" fill="#e68c0e" name="Event" />

          <Line
            type="monotone"
            name="Temperature"
            dataKey="t"
            stroke="#8884d8"
            strokeWidth={2}
            dot={false}
            isAnimationActive={false}
          />
          {graphData[0] && graphData[0].t_sp && (
            <Line
              type="monotone"
              name="Setpoint Temp"
              dataKey="t_sp"
              stroke="#e68c0e"
              dot={false}
              isAnimationActive={false}
            />
          )}
          {graphData[0] && graphData[0].t_fl && (
            <Line
              type="monotone"
              name="Flow Temp"
              dataKey="t_fl"
              stroke="#00838f"
              strokeWidth={2}
              dot={false}
              isAnimationActive={false}
            />
          )}
          {graphData[0] && graphData[graphData.length - 1].t_s && (
            <Line
              type="monotone"
              name="Sensor Temp"
              dataKey="t_s"
              stroke="#000000"
              strokeWidth={2}
              dot={false}
              isAnimationActive={false}
            />
          )}
        </ComposedChart>
      </div>
    );
  }
}

TempGraph.propTypes = {
  //classes: PropTypes.object.isRequired
};

const CustomTooltip = ({ active, payload, label, notificationTexts, timezone }) => {
  if (active && payload) {
    const payload2 = payload[0].payload;
    const date = moment(parseInt(payload[0].payload.timestamp) * 1000);
    const time = date.tz(timezone).format("HH:mm");
    if (payload2) {
      let event = "";
      if (payload2.type) {
        event = eventTitle(payload2.type, notificationTexts);
      }
      return (
        <div style={{ backgroundColor: "white", border: "0.5px solid black" }}>
          <div style={{ marginTop: "10px", marginBottom: "10px", marginLeft: "10px", marginRight: "40px" }}>
            <p>{time}</p>
            <p style={{ color: "#c5c2f0" }}>Heating On {payload2.t_hpr} %</p>
            <p style={{ color: "#8884d8" }}>Temperature: {payload2.t} °C</p>
            <p style={{ color: "#e68c0e" }}>Setpoint Temp: {payload2.t_sp} °C</p>
            <p style={{ color: "#00838f" }}>Flow Temp: {payload2.t_fl} °C</p>
            {payload2.t_s && <p style={{ color: "#000000" }}>Sensor Temp: {payload2.t_s} °C</p>}
            {payload2.t_h && <p style={{ color: "#ADD8E6" }}>Sensor Humid: {payload2.t_h} %</p>}
            {payload2.oc && <p style={{ color: "#3ca130" }}>Booked</p>}
            {payload2.type && <p style={{ color: "#e68c0e" }}>Event: {event.eventTitle}</p>}
          </div>
        </div>
      );
    }
  }

  return null;
};

export default TempGraph;
