import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Room from "./Room";

const styles = (theme) => ({
  grid: { width: 1200 },
  gridcont: {
    flexGrow: 1,
  },
  progress: {
    margin: "auto",
  },
  root: {
    flexGrow: 1,
  },
});

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  didMount: () =>
    dispatch({
      type: "PAGE_CHANGED",
      payload: "Rooms",
    }),
});

class Rooms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAdmin: false,
      isSuperAdmin: false,
    };
  }

  componentDidMount() {
    this.props.didMount();
  }

  render() {
    const { classes, roomsData = {} } = this.props;
    const { rooms = [], calendars = {} } = roomsData;
    return (
      <div className={classes.root}>
        {rooms.length === 0 ? (
          <div>
            <CircularProgress className={classes.progress} />
          </div>
        ) : (
          <div className={classes.grid}>
            <Grid
              container
              className={classes.gridcont}
              direction="row"
              justify="flex-start"
              spacing={2}
              alignItems="stretch"
            >
              {rooms.map((room) => (
                <Grid item xs={3} key={room.id}>
                  <Room
                    key={room.id}
                    calendar={calendars[room.id]}
                    room={room}
                    isSummer={this.props.sightsData && this.props.sightsData.summerModeActive}
                    isAdmin={this.props.userData && (this.props.userData.isAdmin || this.props.userData.isSuperAdmin)}
                    viewOnly={this.props.userData.viewOnly}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

Rooms.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Rooms));
