import React from "react";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import { functions } from "../../config/firebase";

import { LineChart, Line, YAxis, XAxis, Tooltip, Legend, CartesianGrid } from "recharts";

class TempGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      graphdata: [],
      roomId: this.props.roomId,
      homeId: this.props.homeId,
      isLoading: true,
      date_begin: this.props.date_begin,
      date_end: this.props.date_end,
      date_type: this.date_type,
    };
  }

  dateFormat = (time) => {
    if (this.state.date_type === "isoWeek") {
      return moment.unix(time).format("ddd, DD");
    }
    if (this.state.date_type === "month") {
      return moment.unix(time).format("MMM DD");
    }
    if (this.state.date_type === "year") {
      return moment.unix(time).format("MMMM");
    }
    return moment.unix(time).format("HH:mm");
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.roomId !== this.props.roomId) {
      this.setState(
        {
          roomId: nextProps.roomId,
          isLoading: true,
          homeId: nextProps.homeId,
        },
        () => this.loadData()
      );
    }

    if (nextProps.date_begin !== this.props.date_begin) {
      this.setState(
        {
          date_begin: nextProps.date_begin,
          date_end: nextProps.date_end,
          isLoading: true,
        },
        () => this.loadData()
      );
    }
    if (nextProps.date_type !== this.props.date_type) {
      this.setState({
        date_type: nextProps.date_type,
        isLoading: true,
      });
    }
  }

  componentDidMount() {
    //set selects
    this.loadData();
  }

  async loadData() {
    var scale = "max";

    if (this.state.date_type === "isoWeek") {
      scale = "3hours";
    } else if (this.state.date_type === "month") {
      scale = "1day";
    } else if (this.state.date_type === "year") {
      scale = "1week";
    }

    if (this.state.roomId) {
      const getRoomMeasure = functions.httpsCallable("getRoomMeasureCall");

      try {
        const data = await getRoomMeasure({
          scale: scale,
          room_id: this.state.roomId,
          home_id: this.state.homeId,
          date_begin: this.state.date_begin,
          date_end: this.state.date_end,
        });

        this.setState({
          graphdata: data.data,
          isLoading: false,
        });
      } catch (e) {
        console.log(e);
      }
    }
  }

  render() {
    const { graphdata, isLoading } = this.state;

    if (isLoading) {
      return <CircularProgress className="progress" />;
    }

    return (
      <div>
        <LineChart width={1000} height={500} data={graphdata} margin={{ top: 30, right: 30, left: 0, bottom: 5 }}>
          <XAxis dataKey="timestamp" tickFormatter={this.dateFormat} name="Time" />
          <YAxis type="number" domain={["dataMin-2", "dataMax+2"]} />
          <Tooltip cursor={{ stroke: "red", strokeWidth: 1 }} />
          <CartesianGrid strokeDasharray="3 3" />
          <Legend />
          <Line
            type="monotone"
            name="Temperature"
            dataKey="t"
            stroke="#8884d8"
            strokeWidth={2}
            dot={false}
            isAnimationActive={false}
          />
          {graphdata[0] && graphdata[0].t_sp && (
            <Line
              type="monotone"
              name="Setpoint Temp"
              dataKey="t_sp"
              stroke="#00000055"
              dot={false}
              isAnimationActive={false}
            />
          )}
          {this.props.isSuperAdmin && graphdata[0] && graphdata[0].t_fl && (
            <Line
              type="monotone"
              name="Flow Temp"
              dataKey="t_fl"
              stroke="#00838f"
              strokeWidth={2}
              dot={false}
              isAnimationActive={false}
            />
          )}
        </LineChart>
      </div>
    );
  }
}

TempGraph.propTypes = {
  //classes: PropTypes.object.isRequired
};

export default TempGraph;
