import { FETCH_DEVICES } from "../actions/types";

const initialState = {
  devices: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEVICES:
      let devices = action.payload;
      let sightID = action.sightID;
      let positions = action.positions;

      let currentDevices = [];
      if (devices) {
        Object.keys(devices).forEach(function (key) {
          if (devices[key].sight_id === sightID) {
            if (positions[key]) {
              devices[key].position = positions[key];
            } else {
              devices[key].position = {
                left: 0,
                top: 0,
              };
            }
            currentDevices.push(devices[key]);
          }
        });

        //sort currentDevices by name
        currentDevices.sort(compare);

        return {
          ...state,
          devices: currentDevices,
        };
      } else {
        return initialState;
      }

    default:
      return state;
  }
};

function compare(a, b) {
  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
  return 0;
}
