// import Box from "@mui/material/Box";
import Iframe from "react-iframe";
import React from "react";
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";

const useStyles = (theme) => ({
  btn: {
    backgroundColor: "#00838f !important",
    color: "#fff !important",
    "&:hover": {
      backgroundColor: "#00838a !important",
    },
  },
});
const CarbonMeter = (props) => {
  const { classes } = props;
  const building_id = props.selectedSite;

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // let params = {
  //   "chosen_building_id": props.selectedSite
  // };
  // let paramsAsString = JSON.stringify(params);
  // let encodedParams = encodeURIComponent(paramsAsString);
  /*<Iframe url={`https://datastudio.google.com/embed/reporting/78469444-a381-498f-aae3-f1bf6b6df678/page/BPi4C?params=${encodedParams}`}*/
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button className={classes.btn} onClick={handleClickOpen}>
          {">>> Carbon Meter Tutorial Video <<<"}
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth={"md"}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Carbon Meter Tutorial Video"}</DialogTitle>
          <DialogContent>
            <iframe
              width="100%"
              height="400"
              src="https://www.youtube.com/embed/HeFsab7alsw"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Iframe
        url={`https://datastudio.google.com/embed/reporting/78469444-a381-498f-aae3-f1bf6b6df678/page/BPi4C?params=%7B%22df28%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${building_id}%22%7D`}
        styles={{
          height: "91vh",
          width: "95vw",
          marginLeft: "-23px",
          marginRight: "-23px",
          marginBottom: "-29px",
          padding: "0",
          marginTop: "20px",
        }}
      />
    </>
  );
};

export default withStyles(useStyles)(CarbonMeter);
