import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./store";
import "./index.css";
import App from "./components/App";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import pink from "@material-ui/core/colors/pink";

//import registerServiceWorker from './registerServiceWorker';
const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: "#00838f",
      contrastText: "#fff",
    },
    secondary: pink,
  },
});

theme.shadows[4] =
  "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.02),0px 1px 10px 0px rgba(0,0,0,0.12)";

ReactDOM.render(
  <Provider store={configureStore()}>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("root")
);
//registerServiceWorker();
