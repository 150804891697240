import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import Device from "./Device";
import Grid from "@material-ui/core/Grid";

const styles = (theme) => ({
  grid: {},
  gridcont: {
    flexGrow: 1,
  },
  root: {
    flexGrow: 1,
  },
});

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  didMount: () =>
    dispatch({
      type: "PAGE_CHANGED",
      payload: "Devices",
    }),
});

class Devices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gateways: [],
    };
  }

  componentDidMount() {
    this.props.didMount();
  }

  orderCompare = (a, b) => {
    return a.hardware === "micropelt"
      ? typeof a.room_name !== "undefined" && typeof b.room_name !== "undefined"
        ? a.room_name.localeCompare(b.room_name)
        : typeof a.room_name === "undefined"
        ? 1
        : -1
      : a.name.localeCompare(b.name);
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {!this.props.devicesData.devices ? (
          <CircularProgress className="progress" />
        ) : (
          <div className={classes.grid}>
            <Grid item xs={12} container className={classes.gridcont}>
              <Grid container spacing={2}>
                {Object.keys(this.props.sightsData.deviceData).map((key, index) => {
                  if (
                    this.props.sightsData.deviceData[key].type === "gateway" &&
                    this.props.sightsData.deviceData[key].homeId === this.props.sightsData.selectedSite
                  ) {
                    return <Device device={this.props.sightsData.deviceData[key]} key={key} />;
                  } else {
                    return null;
                  }
                })}
                {this.props.devicesData.devices.sort(this.orderCompare).map((device) => (
                  <Device device={device} key={device.id} authData={this.props.authData} />
                ))}
              </Grid>
            </Grid>
          </div>
        )}
      </div>
    );
  }
}

Devices.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Devices));
