import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
// import _ from "lodash";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import InfoTooltip from "../Common/InfoTooltip";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionActions from "@material-ui/core/AccordionActions";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Button from "@material-ui/core/Button";
import {
  FIRE,
  OPEN_WINDOW,
  NO_HEATING,
  NO_HEATING_CONTROL,
  STUCK_PIN,
  OFFLINE_TRV,
  OFFLINE_GW,
  OFFLINE_GW_MOSQUITTO,
  OFFLINE_GW_GATEWAY,
  DEVICE_PINGED,
  MECHANICAL_ERROR,
  CELLULAR_GW,
  EXTRA_HEAT_SOURCE,
  REFERENCE_RUN,
  MANUAL_REFERENCE_RUN,
  AWAY_LOW_TEMP,
  SET_CUSTOM_SCHEDULE,
  USER_ACTION_SET_TEMP,
  USER_ACTION_SET_FREEZE_ON,
  USER_ACTION_SET_FREEZE_OFF,
  USER_ACTION_ADMIN_SET_TEMP,
  USER_ACTION_ADMIN_SET_CUSTOM_TEMP,
  USER_ACTION_ADMIN_SET_CUSTOM_TEMP_OFF,
  USER_ACTION_ADMIN_SET_TEMP_BULK,
  USER_ACTION_ADMIN_SET_FREEZE_ON,
  USER_ACTION_ADMIN_SET_FREEZE_ON_BULK,
  USER_ACTION_ADMIN_SET_FREEZE_OFF,
  USER_ACTION_ADMIN_SET_FREEZE_OFF_BULK,
  USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_ON,
  USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_ON_BULK,
  USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_OFF,
  USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_OFF_BULK,
  USER_ACTION_SLACK_SET_TEMP,
  USER_ACTION_BOOST,
} from "../../constants/events";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    fontSize: 16,
    marginBottom: 20,
  },
  table: {
    minWidth: 650,
    marginBottom: 20,
    flexGrow: 1,
  },
  heading: {
    fontSize: 14,
    fontWeight: 500,
    textTransform: "uppercase",
  },
  secondaryHeading: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    display: "block",
  },
  column: {
    flexBasis: "33.33%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 300,
    marginTop: -10,
    marginBottom: 20,
  },
  select: {
    minWidth: 120,
  },
  body: {
    fontSize: 12,
    marginBottom: 20,
    display: "block",
  },
});

class NotificationsSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      selectedSettings: {},
      isChanged: false,
    };
  }

  componentDidMount() {
    //console.log(this.props.notifyEmails);
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedSite = 0 } = this.props;

    const { selectedSite: prevSelectedSite = 0 } = prevProps;

    if (selectedSite !== prevSelectedSite) {
      this.setState({
        email: "",
        selectedSettings: {},
      });
    }
  }

  handleEmailChange = (event) => {
    const email = event.target.value;

    if (email) {
      const em = email.replace(/\./g, ",");

      var selectedSettings;

      if (this.props.notificationsSettings[em]) {
        selectedSettings = this.props.notificationsSettings[em];
        this.props.defaultSettings.forEach((key, value) => {
          if (typeof selectedSettings[key] === "undefined") {
            if (parseInt(key) && typeof value !== "undefined") {
              selectedSettings[key] = value;
            }
          }
        });
      } else {
        selectedSettings = this.props.defaultSettings;
      }

      this.setState({
        email: event.target.value,
        selectedSettings: selectedSettings,
        isChanged: true,
      });
    }
    this.props.setHasChanges(true);
  };

  handleChange = (event) => {
    var t = event.target.name.split("_");

    var settings = this.state.selectedSettings;

    if (t[0] === "on") {
      settings[t[1]].frequency = event.target.value;
    }
    if (t[0] === "off") {
      settings[t[1]].heatingOffFrequency = event.target.value;
    }
    this.setState({
      selectedSettings: settings,
      isChanged: true,
    });
    this.props.setHasChanges(true);
  };

  handleSave = (isDefault = false) => {
    const em = this.state.email.replace(/\./g, ",");
    if (isDefault) {
      this.props.saveSettings(null, em);
    } else {
      this.props.saveSettings(this.state.selectedSettings, em);
    }
    this.setState({
      email: "",
      selectedSettings: {},
      isChanged: false,
    });
    this.props.setHasChanges(false);
    window.location.reload();
  };

  render() {
    const {
      classes,
      notifyEmails,
      // defaultSettings,
      // notificationsSettings,
      notificationTexts,
      isAdmin,
      isSuperAdmin,
    } = this.props;

    const { email, selectedSettings } = this.state;
    // console.log(selectedSettings);
    return (
      <div className={classes.root}>
        {/*


        <Accordion defaultExpanded={false} elevation={0} variant="outlined">
          <AccordionSummary
            aria-controls="panel1c-content"
            id="panel1c-header"
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.column}>
              <Typography className={classes.heading}>
                Default Notification Settings
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Notification Type</TableCell>
                  <TableCell align="right">Heating Season ON</TableCell>
                  <TableCell align="right">Heating Season OFF</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(defaultSettings).map((key) => (
                  <TableRow key={"nn_" + key}>
                    <TableCell component="th" scope="row">
                      {defaultSettings[key].name}
                    </TableCell>
                    <TableCell align="right">
                      {defaultSettings[key].frequency}
                    </TableCell>
                    <TableCell align="right">
                      {defaultSettings[key].heatingOffFrequency}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
                */}
        <Accordion defaultExpanded elevation={0} variant="outlined">
          <AccordionSummary aria-controls="panel1c-content" id="panel1c-header" expandIcon={<ExpandMoreIcon />}>
            <div className={classes.column}>
              <Typography className={classes.heading}>Custom Notification Settings</Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <div className={classes.root}>
              <Typography className={classes.body} variant="body1">
                Choose who should receive notifications and how often. Press “Save Custom settings” after changing the
                frequency of any notifications
              </Typography>
              <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Email</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={email}
                  onChange={this.handleEmailChange}
                >
                  {notifyEmails.map((email) => (
                    <MenuItem value={email} key={email}>
                      {email}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <Table className={classes.table} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Notification Type</TableCell>
                  <TableCell align="right">Heating Season ON</TableCell>
                  <TableCell align="right">Heating Season OFF</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(selectedSettings).map((key) => (
                  <TableRow key={"nxc_" + key}>
                    <TableCell component="th" scope="row">
                      <Typography>
                        {" "}
                        {notificationTexts[key]["notiTitle"]}
                        {notificationTexts[key]["notiInfo"] && (
                          <div style={{ display: "inline-block", marginLeft: 5, marginTop: -10, height: 14 }}>
                            <InfoTooltip small={true} text={selectedSettings[key].info} />
                          </div>
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Select
                        id="on-select"
                        value={selectedSettings[key].frequency}
                        name={"on_" + key}
                        onChange={this.handleChange}
                        className={classes.select}
                      >
                        <MenuItem value="none">none</MenuItem>
                        <MenuItem value={"immediately"}>immediately</MenuItem>
                        <MenuItem value={"daily"}>daily</MenuItem>
                        <MenuItem value={"weekly"}>weekly</MenuItem>
                      </Select>
                    </TableCell>
                    <TableCell align="right">
                      <Select
                        id="off-select"
                        value={selectedSettings[key].heatingOffFrequency}
                        name={"off_" + key}
                        onChange={this.handleChange}
                        className={classes.select}
                      >
                        <MenuItem value="none">none</MenuItem>
                        <MenuItem value={"immediately"}>immediately</MenuItem>
                        <MenuItem value={"daily"}>daily</MenuItem>
                        <MenuItem value={"weekly"}>weekly</MenuItem>
                      </Select>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </AccordionDetails>
          <Divider />

          <AccordionActions>
            {(isAdmin || isSuperAdmin) && (
              <Button
                size="small"
                disabled={!email || this.props.viewOnly}
                onClick={() => {
                  this.handleSave(true);
                }}
              >
                Set back to default
              </Button>
            )}
            {(isAdmin || isSuperAdmin) && (
              <Button
                size="small"
                color="primary"
                disabled={!email || this.props.viewOnly}
                onClick={() => {
                  this.handleSave();
                }}
              >
                Save Custom Settings
              </Button>
            )}
          </AccordionActions>
        </Accordion>
      </div>
    );
  }
}

export default withStyles(styles)(NotificationsSettings);
