import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import {
  fetchQRCsvData,
  fetchSettings,
  saveBuildingDataSettings,
  saveBoostDataSettings,
  saveFreezeTemperature,
  saveMobileViewSettings,
  saveCalendarSettings,
} from "../../actions";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionActions from "@material-ui/core/AccordionActions";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@mui/material";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import validator from "validator";
import List from "@material-ui/core/List";
import { CSVLink } from "react-csv";

// import Snackbar from "@material-ui/core/Snackbar";

// import _ from "lodash";
import { functions } from "../../config/firebase";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    float: "right",
    marginLeft: 20,
    marginTop: 20,
  },
  downloadBtn: {
    marginLeft: 10,
    marginTop: 10,
  },
  rightIcon: {
    marginLeft: 10,
    marginTop: -3,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  listTitle: {
    marginRight: 30,
    width: 240,
    fontSize: 18,
  },
  heading: {
    fontSize: 14,
    fontWeight: 500,
    textTransform: "uppercase",
  },
  secondaryHeading: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "left",
  },
  column: {
    flexBasis: "33.33%",
  },
  body: {
    fontSize: 12,
    marginBottom: 20,
    display: "block",
  },
  block: {
    flexGrow: 1,
  },
  csvLink: {
    marginRight: 20,
  },
});

const ButtonType = {
  EXPIRING: "expiring",
  NON_EXPIRING: "non_expiring",
  EXPIRING_ALL: "expiring_all",
  NON_EXPIRING_ALL: "non_expiring_all",
};

const mapDispatchToProps = (dispatch) => ({
  fetchSettings: () => dispatch(fetchSettings()),
  fetchQRCsvData: () => dispatch(fetchQRCsvData()),
  saveMobileViewSettings: (val) => dispatch(saveMobileViewSettings(val)),
  saveCalendarSettings: (val) => dispatch(saveCalendarSettings(val)),
  saveFreezeTemperature: (val) => dispatch(saveFreezeTemperature(val)),
  saveBuildingDataSettings: (val) => dispatch(saveBuildingDataSettings(val)),
  saveBoostDataSettings: (val) => dispatch(saveBoostDataSettings(val)),
});

class BuildingSettings extends Component {
  constructor(props) {
    super(props);
    // const selectedSight = props.selectedSight;
    this.state = {
      isAdmin: false,
      isSuperAdmin: false,
      isReceptionist: false,
      isChanged: false,
      isChangedM: false,
      isChangedB: false,
      emailError: "",
      isBarsOnMobile: false,
      isFreezeAvailable: false,
      calendarSetAwayTemp: false,
      QRCsvData: "",
      freeze: 10,
      minTemp: 10,
      maxTemp: 24,
      youtube: "",
      leaderboard: "",
      leaderboardOff: false,
      boostEnabled: false,
      boostRoomTypes: [],
      contactEmail: "",
      maintenanceMessage: "",
      selectedSight: {},
      fixedLoading: false,
      expiredLoading: false,
      fixedAllLoading: false,
      expiredAllLoading: false,
    };
    if (this.props.roomTypes) {
      Object.keys(this.props.roomTypes).forEach((roomType) => {
        this.state[roomType] = false;
      });
    }
  }

  handleChange = (event) => {
    if (event.target.name === "contactEmail") {
      if (event.target.value && !validator.isEmail(event.target.value)) {
        this.setState({ emailError: "Please insert a valid email address!" });
      } else {
        this.setState({ emailError: "" });
      }
    }
    this.setState({
      ...this.state,
      [event.target.name]:
        event.target.name === "isBarsOnMobile" ||
        event.target.name === "isFreezeAvailable" ||
        event.target.name === "calendarSetAwayTemp" ||
        event.target.name === "leaderboardOff" ||
        event.target.name === "boostEnabled"
          ? event.target.checked
          : event.target.value,
      isChanged: event.target.name === "freeze",
      isChangedM: !(
        event.target.name === "freeze" ||
        event.target.name === "youtube" ||
        event.target.name === "leaderboard"
      ),
      isChangedC: event.target.name === "calendarSetAwayTemp",
      isChangedB:
        event.target.name === "youtube" ||
        event.target.name === "leaderboard" ||
        event.target.name === "leaderboardOff" ||
        event.target.name === "boostEnabled",
      isChangedBoost: Object.keys(this.props.roomTypes).indexOf(event.target.name) > -1,
    });
    if (Object.keys(this.props.roomTypes).indexOf(event.target.name) > -1) {
      let boostRoomTypes = this.state.boostRoomTypes;
      if (event.target.checked) {
        boostRoomTypes.push(event.target.name);
      } else {
        boostRoomTypes = boostRoomTypes.filter((elem) => elem !== event.target.name);
      }
      this.setState({ boostRoomTypes: boostRoomTypes });
    }
    this.props.setHasChanges(true);
  };

  componentDidUpdate(props, prevProps) {
    if (this.props.selectedSight !== prevProps.selectedSight) {
      const selectedSight = this.props.selectedSight;
      this.setState({
        isBarsOnMobile: selectedSight.settings.isBarsOnMobile,
        isFreezeAvailable: selectedSight.settings.isFreezeAvailable,
        calendarSetAwayTemp: selectedSight.settings.calendarSetAwayTemp,
        freeze: selectedSight.settings.freezeProtectionTemp,
        minTemp: selectedSight.settings.mobileViewMinTemp,
        maxTemp: selectedSight.settings.mobileViewMaxTemp,
        youtube: selectedSight.settings.youtube,
        leaderboard: selectedSight.settings.leaderboard,
        leaderboardOff: selectedSight.settings.leaderboardOff,
        boostEnabled: selectedSight.settings.boostEnabled,
        contactEmail: selectedSight.settings.contactEmail,
        maintenanceMessage: selectedSight.settings.maintenanceMessage,
        boostRoomTypes: selectedSight.settings.boostRoomTypes || [],
        selectedSight: selectedSight,
        isChanged: this.state.isChanged,
        isChangedB: this.state.isChangedB,
        isChangedM: this.state.isChangedM,
        isChangedBoost: this.state.isChangedBoost,
        isAdmin: this.props.props.userData.isAdmin,
        isSuperAdmin: this.props.props.userData.isSuperAdmin,
        isReceptionist: this.props.props.userData.isReceptionist,
      });
    }
  }

  getData() {
    this.props.fetchSettings();
    this.props.fetchQRCsvData();
  }

  componentDidMount() {
    this.getData();
  }

  saveCalendarSettings = async () => {
    this.setState({
      isChanged: false,
    });
    await this.props.saveCalendarSettings(this.state.calendarSetAwayTemp);
    window.location.reload();
  };

  saveFreezeTemperature = async () => {
    this.setState({
      isChanged: false,
    });
    await this.props.saveFreezeTemperature(this.state.freeze);
    window.location.reload();
  };

  saveMobileViewSettings = async () => {
    this.setState({
      isChangedM: false,
    });
    await this.props.saveMobileViewSettings({
      minTemp: this.state.minTemp,
      maxTemp: this.state.maxTemp,
      isBarsOnMobile: this.state.isBarsOnMobile,
      isFreezeAvailable: this.state.isFreezeAvailable,
      contactEmail: this.state.contactEmail,
      maintenanceMessage: this.state.maintenanceMessage,
    });
    window.location.reload();
  };

  rebootGWs = async () => {
    let restartSiteGws = functions.httpsCallable("restartSiteGws");
    try {
      await restartSiteGws({
        sight_id: this.props.selectedSight.id,
      });
      alert("Reboot command sent!");
    } catch (e) {
      // const sendAlert = functions.httpsCallable('sendAlertToSlackCall')
      // await sendAlert({ text: 'setFreeze failed on mobile view', id: this.state.room.id, functionName: 'setFreeze', obj: {} })
      // alert("An error occured, please try again later")
    }
  };

  saveBuildingDataSettings = async () => {
    this.setState({
      isChangedB: false,
    });
    await this.props.saveBuildingDataSettings({
      youtube: this.state.youtube ? this.state.youtube : "",
      leaderboard: this.state.leaderboard,
      leaderboardOff: this.state.leaderboardOff ? this.state.leaderboardOff : "",
      boostEnabled: this.state.boostEnabled,
    });
    window.location.reload();
  };

  saveBoostDataSettings = async () => {
    this.setState({
      isChangedBoost: false,
    });
    await this.props.saveBoostDataSettings(this.state.boostRoomTypes);
    window.location.reload();
  };

  downloadQrPDF = async (type) => {
    const { sightsData } = this.props.props;
    if (sightsData.selectedSite) {
      let selectedBuilding = sightsData.sights.find((building) => building.id === sightsData.selectedSite);

      let selectedBuildingName = selectedBuilding ? selectedBuilding.name : null;
      let fixedArray;
      let fixed = false;

      switch (type) {
        case ButtonType.EXPIRING:
          this.setState({ expiredLoading: true });
          fixedArray = sightsData.QRCsvData.slice(1);
          break;
        case ButtonType.NON_EXPIRING:
          this.setState({ fixedLoading: true });
          fixed = true;
          fixedArray = sightsData.QRCsvFixData.slice(1);
          break;
        case ButtonType.NON_EXPIRING_ALL:
          this.setState({ fixedAllLoading: true });
          fixed = true;
          fixedArray = sightsData.QRAllCsvFixData.slice(1);
          break;
        case ButtonType.EXPIRING_ALL:
          this.setState({ expiredAllLoading: true });
          fixedArray = sightsData.QRAllCsvData.slice(1);
          break;
      }

      let nums = [];
      let names = [];

      fixedArray.forEach((item) => {
        if (item[1] !== undefined) {
          let url = item[0];
          names.push(item[1]);
          const urlToAppend = url?.split("/").reverse()[0];
          const splitUrl = fixed ? "eco_rmbf/" + urlToAppend : "ers/" + urlToAppend;
          nums.push(encodeURIComponent(encodeURIComponent(splitUrl)));
        }
      });

      try {
        let callQrGenerateApi = functions.httpsCallable("callQrGenerateApi");
        const {
          data: { pdfBase64 },
        } = await callQrGenerateApi({ nums, names, fixed, selectedBuildingName });

        const response = await fetch(`data:application/pdf;base64,${pdfBase64}`);
        await this.displayPDF(response, type);
      } catch (error) {
        console.error("Error when calling QR API:", error);
      }
    }
  };

  displayPDF = async (response, type) => {
    return response.blob().then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.classList.add("downloadPDF");
      link.href = url;
      link.setAttribute(
        "download",
        type === ButtonType.NON_EXPIRING || type === ButtonType.NON_EXPIRING_ALL
          ? "qr_code_labels.pdf"
          : "qr_code_labels_expiring.pdf"
      );
      document.body.appendChild(link);
      link.click();

      switch (type) {
        case ButtonType.EXPIRING:
          this.setState({ expiredLoading: false });
          break;
        case ButtonType.NON_EXPIRING:
          this.setState({ fixedLoading: false });
          break;
        case ButtonType.NON_EXPIRING_ALL:
          this.setState({ fixedAllLoading: false });
          break;
        case ButtonType.EXPIRING_ALL:
          this.setState({ expiredAllLoading: false });
          break;
      }
    });
  };

  render() {
    const {
      classes,
      roomTypes = [],
      userData: {
        // isAdmin = false,
        // isReceptionist = false,
        // isSuperAdmin = false,
      } = {},
    } = this.props;

    const selectedSight = this.props.selectedSight;
    const { sightsData } = this.props.props;
    let menus = [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];

    let menusMin = [10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18];
    let menusMax = [18.5, 18.5, 19, 19.5, 20, 20.5, 21, 21.5, 22, 22.5, 23, 23.5, 24, 24.5, 25, 25.5, 26];

    return (
      <div>
        <Accordion defaultExpanded elevation={0} variant="outlined">
          <AccordionSummary aria-controls="panel1c-content" id="panel1c-header" expandIcon={<ExpandMoreIcon />}>
            <div className={classes.column}>
              <Typography className={classes.heading}>Freeze Protection Temperature</Typography>
            </div>
            <div className={classes.column}>
              <Typography className={classes.secondaryHeading}></Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <div>
              <Typography className={classes.body} variant="body1">
                You can set the Freeze Protection temperature below (Default: 10°C)
              </Typography>

              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="away-simple">Temperature</InputLabel>
                <Select
                  value={this.state.freeze}
                  onChange={this.handleChange}
                  inputProps={{
                    name: "freeze",
                    id: "freeze",
                  }}
                >
                  {menus.map((m) => (
                    <MenuItem key={"d" + m} value={m}>
                      {m} °C
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </AccordionDetails>
          <Divider />
          <AccordionActions>
            {(this.state.isAdmin || this.state.isSuperAdmin) && (
              <Button
                size="small"
                color="primary"
                disabled={!this.state.isChanged || this.props.viewOnly}
                onClick={() => {
                  this.saveFreezeTemperature();
                }}
              >
                Save
              </Button>
            )}
          </AccordionActions>
        </Accordion>

        <Accordion defaultExpanded elevation={0} variant="outlined">
          <AccordionSummary aria-controls="panel7c-content" id="panel7c-header" expandIcon={<ExpandMoreIcon />}>
            <div className={classes.column}>
              <Typography className={classes.heading}>Calendar integration settings</Typography>
            </div>
            <div className={classes.column}>
              <Typography className={classes.secondaryHeading}></Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <div>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.calendarSetAwayTemp}
                    onChange={this.handleChange}
                    name="calendarSetAwayTemp"
                  />
                }
                label="Set AWAY temperature when room is not booked"
              />
            </div>
          </AccordionDetails>
          <AccordionDetails className={classes.details}>
            <Divider style={{ marginBottom: 20, marginTop: 20 }} />
            <div className={classes.block}>
              <Typography className={classes.body} variant="body1">
                When enabled, room will revert to AWAY temperature. When disabled, room will be set to 2 degrees lower
                then COMFORT temperature when not booked.
              </Typography>
            </div>
          </AccordionDetails>
          <Divider />
          <AccordionActions>
            {(this.state.isAdmin || this.state.isSuperAdmin) && (
              <Button
                size="small"
                color="primary"
                disabled={!this.state.isChangedC || this.props.viewOnly}
                onClick={() => {
                  this.saveCalendarSettings();
                }}
              >
                Save
              </Button>
            )}
          </AccordionActions>
        </Accordion>
        <Accordion defaultExpanded elevation={0} variant="outlined">
          <AccordionSummary aria-controls="panel1c-content" id="panel1c-header" expandIcon={<ExpandMoreIcon />}>
            <div className={classes.column}>
              <Typography className={classes.heading}>Mobile View Settings</Typography>
            </div>
            <div className={classes.column}>
              <Typography className={classes.secondaryHeading}></Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <div className={classes.block}>
              <Typography className={classes.body} variant="body1">
                Mobile view temperature settings
              </Typography>

              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="away-simple">Min. Temp.</InputLabel>
                <Select
                  value={this.state.minTemp}
                  onChange={this.handleChange}
                  inputProps={{
                    name: "minTemp",
                    id: "minTemp",
                  }}
                >
                  {menusMin.map((m) => (
                    <MenuItem key={"m" + m} value={m}>
                      {m} °C
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="away-simple">Max Temp.</InputLabel>
                <Select
                  value={this.state.maxTemp}
                  onChange={this.handleChange}
                  inputProps={{
                    name: "maxTemp",
                    id: "maxTemp",
                  }}
                >
                  {menusMax.map((m) => (
                    <MenuItem key={"mm" + m} value={m}>
                      {m} °C
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div>
              <FormControlLabel
                control={
                  <Checkbox checked={this.state.isBarsOnMobile} onChange={this.handleChange} name="isBarsOnMobile" />
                }
                label="Show Bar instead of temperature"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.isFreezeAvailable}
                    onChange={this.handleChange}
                    name="isFreezeAvailable"
                  />
                }
                label="Enable AWAY setting"
              />
            </div>
          </AccordionDetails>
          <AccordionDetails className={classes.details}>
            <Divider style={{ marginBottom: 20, marginTop: 20 }} />
            <div className={classes.block}>
              <Typography className={classes.body} variant="body1">
                Issue reporting email address. "Report issue" button will only show if email is set. Separate multiple
                email addresses with commas.
              </Typography>

              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="contactEmail-ref">Your email address</InputLabel>
                <Input
                  type="text"
                  value={this.state.contactEmail}
                  onChange={this.handleChange}
                  name="contactEmail"
                  id="contactEmail-ref"
                  size="small"
                />
                <span
                  style={{
                    fontWeight: "bold",
                    color: "red",
                    fontSize: "small",
                  }}
                >
                  {this.state.emailError}
                </span>
              </FormControl>
            </div>
          </AccordionDetails>
          <AccordionDetails className={classes.details}>
            <Divider style={{ marginBottom: 20, marginTop: 20 }} />
            <div className={classes.block}>
              <Typography className={classes.body} variant="body1">
                Maintenance message will display on all mobile pages under the room number.
              </Typography>

              <FormControl className={classes.formControl}>
                <TextareaAutosize
                  type="textarea"
                  value={this.state.maintenanceMessage}
                  onChange={this.handleChange}
                  name="maintenanceMessage"
                  id="maintenanceMessage-ref"
                  placeholder="Message"
                  style={{ width: 500 }}
                />
              </FormControl>
            </div>
          </AccordionDetails>
          <Divider />
          <AccordionActions>
            {(this.state.isAdmin || this.state.isSuperAdmin) && (
              <Button
                size="small"
                color="primary"
                disabled={!this.state.isChangedM || this.props.viewOnly}
                onClick={() => {
                  this.saveMobileViewSettings();
                }}
              >
                Save
              </Button>
            )}
          </AccordionActions>
        </Accordion>
        <Accordion defaultExpanded elevation={0} variant="outlined">
          <AccordionSummary aria-controls="panel4c-content" id="panel4c-header" expandIcon={<ExpandMoreIcon />}>
            <div className={classes.column}>
              <Typography className={classes.heading}>Youtube tutorial</Typography>
            </div>
            <div className={classes.column}>
              <Typography className={classes.secondaryHeading}></Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <div className={classes.block}>
              <Typography className={classes.body} variant="body1">
                Only add <strong>hzhGmOD58Jg</strong> from a link like:
                https://www.youtube.com/watch?v=hzhGmOD58Jg&t=0.23
              </Typography>

              <FormControl className={classes.formControl}>
                <InputLabel htmlFor="youtube-ref">Youtube ref code</InputLabel>
                <Input
                  type="text"
                  value={this.state.youtube}
                  onChange={this.handleChange}
                  name="youtube"
                  id="youtube-ref"
                />
              </FormControl>
            </div>
          </AccordionDetails>
          <Divider />
          <AccordionActions>
            <Button
              size="small"
              color="primary"
              disabled={!this.state.isChangedB || this.props.viewOnly}
              onClick={() => {
                this.saveBuildingDataSettings();
              }}
            >
              Save
            </Button>
          </AccordionActions>
        </Accordion>
        {this.state.isSuperAdmin && (
          <Accordion defaultExpanded elevation={0} variant="outlined">
            <AccordionSummary aria-controls="panel5c-content" id="panel5c-header" expandIcon={<ExpandMoreIcon />}>
              <div className={classes.column}>
                <Typography className={classes.heading}>Leaderboard</Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <div className={classes.block}>
                <FormControlLabel
                  control={
                    <Checkbox checked={this.state.leaderboardOff} onChange={this.handleChange} name="leaderboardOff" />
                  }
                  label="Disable leaderboard"
                />
              </div>
            </AccordionDetails>
            <Divider />
            <AccordionActions>
              <Button
                size="small"
                color="primary"
                disabled={!this.state.isChangedB || this.props.viewOnly}
                onClick={() => {
                  this.saveBuildingDataSettings();
                }}
              >
                Save
              </Button>
            </AccordionActions>
          </Accordion>
        )}
        {this.state.isAdmin && (
          <Accordion defaultExpanded elevation={0} variant="outlined">
            <AccordionSummary aria-controls="panel9c-content" id="panel9c-header" expandIcon={<ExpandMoreIcon />}>
              <div className={classes.column}>
                <Typography className={classes.heading}>Boosting</Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <div className={classes.block}>
                <FormControlLabel
                  control={
                    <Checkbox checked={this.state.boostEnabled} onChange={this.handleChange} name="boostEnabled" />
                  }
                  label="Enable Boosting"
                />
              </div>
            </AccordionDetails>
            <Divider />
            <AccordionActions>
              <Button
                size="small"
                color="primary"
                disabled={!this.state.isChangedB || this.props.viewOnly}
                onClick={() => {
                  this.saveBuildingDataSettings();
                }}
              >
                Save
              </Button>
            </AccordionActions>
          </Accordion>
        )}
        {this.state.boostEnabled && (
          <Accordion defaultExpanded elevation={0} variant="outlined">
            <AccordionSummary aria-controls="panel10c-content" id="panel10c-header" expandIcon={<ExpandMoreIcon />}>
              <div className={classes.column}>
                <Typography className={classes.heading}>Boost by room type</Typography>
              </div>
              <div className={classes.column}>
                <Typography className={classes.secondaryHeading}></Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <List component="nav">
                {Object.keys(roomTypes).map((index) => {
                  let type = roomTypes[index];
                  return (
                    <div>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value="1"
                            checked={this.state.boostRoomTypes && this.state.boostRoomTypes.indexOf(index) > -1}
                            onChange={this.handleChange}
                            name={index}
                          />
                        }
                        label={type.label}
                      />
                    </div>
                  );
                })}
              </List>
            </AccordionDetails>
            <AccordionDetails className={classes.details}>
              <Divider style={{ marginBottom: 20, marginTop: 20 }} />
              <div className={classes.block}>
                <Typography className={classes.body} variant="body1">
                  Selected room types will have the boosting button available during Away/Night periods.
                </Typography>
              </div>
            </AccordionDetails>
            <Divider />
            <AccordionActions>
              {(this.state.isAdmin || this.state.isSuperAdmin) && (
                <Button
                  size="small"
                  color="primary"
                  disabled={!this.state.isChangedBoost || this.props.viewOnly}
                  onClick={() => {
                    this.saveBoostDataSettings();
                  }}
                >
                  Save
                </Button>
              )}
            </AccordionActions>
          </Accordion>
        )}
        {this.state.isSuperAdmin && (
          <>
            <Accordion defaultExpanded elevation={0} variant="outlined">
              <AccordionSummary aria-controls="panel6c-content" id="panel6c-header" expandIcon={<ExpandMoreIcon />}>
                <div className={classes.column}>
                  <Typography className={classes.heading}>Download QR codes</Typography>
                </div>
                <div className={classes.column}>
                  <Typography className={classes.secondaryHeading}></Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <div className={classes.block}>
                  {this.props.props.sightsData.QRCsvData ? (
                    <Button
                      className={classes.downloadBtn}
                      variant="contained"
                      color="primary"
                      disabled={this.state.expiredLoading}
                      onClick={() => this.downloadQrPDF(ButtonType.EXPIRING)}
                    >
                      Expiring QR codes
                      {this.state.expiredLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        <DownloadIcon className={classes.rightIcon} />
                      )}
                    </Button>
                  ) : null}
                  {this.props.props.sightsData.QRCsvFixData ? (
                    <Button
                      className={classes.downloadBtn}
                      variant="contained"
                      color="primary"
                      disabled={this.state.fixedLoading}
                      onClick={() => this.downloadQrPDF(ButtonType.NON_EXPIRING)}
                    >
                      Fixed QR codes
                      {this.state.fixedLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        <DownloadIcon className={classes.rightIcon} />
                      )}
                    </Button>
                  ) : null}
                </div>
                {(this.state.isAdmin || this.state.isSuperAdmin) && this.props.props.sightsData.QRAllCsvData ? (
                  <div className={classes.block}>
                    <Button
                      className={classes.downloadBtn}
                      variant="contained"
                      color="primary"
                      disabled={this.state.expiredAllLoading}
                      onClick={() => this.downloadQrPDF(ButtonType.EXPIRING_ALL)}
                    >
                      Expiring QR codes for all rooms
                      {this.state.expiredAllLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        <DownloadIcon className={classes.rightIcon} />
                      )}
                    </Button>
                    <Button
                      className={classes.downloadBtn}
                      variant="contained"
                      color="primary"
                      disabled={this.state.fixedAllLoading}
                      onClick={() => this.downloadQrPDF(ButtonType.NON_EXPIRING_ALL)}
                    >
                      Fixed QR codes for all rooms
                      {this.state.fixedAllLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : (
                        <DownloadIcon className={classes.rightIcon} />
                      )}
                    </Button>
                  </div>
                ) : null}
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded elevation={0} variant="outlined">
              <AccordionSummary aria-controls="panel6c-content" id="panel6c-header" expandIcon={<ExpandMoreIcon />}>
                <div className={classes.column}>
                  <Typography className={classes.heading}>Download CSV for QR</Typography>
                </div>
                <div className={classes.column}>
                  <Typography className={classes.secondaryHeading}></Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <div className={classes.block}>
                  {this.props.props.sightsData.QRCsvData ? (
                    <CSVLink
                      className={classes.csvLink}
                      data={this.props.props.sightsData.QRCsvData}
                      filename={selectedSight.name + ".csv"}
                      enclosingCharacter={""}
                    >
                      Download changing URL list
                    </CSVLink>
                  ) : null}
                  {this.props.props.sightsData.QRCsvFixData ? (
                    <CSVLink
                      data={this.props.props.sightsData.QRCsvFixData}
                      filename={selectedSight.name + "_fix.csv"}
                      enclosingCharacter={""}
                    >
                      Download fix URL list
                    </CSVLink>
                  ) : null}
                </div>
                {(this.state.isAdmin || this.state.isSuperAdmin) && this.props.props.sightsData.QRAllCsvData ? (
                  <div className={classes.block}>
                    <CSVLink
                      className={classes.csvLink}
                      data={this.props.props.sightsData.QRAllCsvData}
                      filename={selectedSight.name + ".csv"}
                      enclosingCharacter={""}
                    >
                      Download changing URL list for all rooms
                    </CSVLink>
                    <CSVLink
                      className={classes.csvLink}
                      data={this.props.props.sightsData.QRAllCsvFixData}
                      filename={selectedSight.name + ".csv"}
                      enclosingCharacter={""}
                    >
                      Download Fix URL list for all rooms
                    </CSVLink>
                  </div>
                ) : null}
              </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded elevation={0} variant="outlined">
              <AccordionSummary aria-controls="panel8c-content" id="panel8c-header" expandIcon={<ExpandMoreIcon />}>
                <div className={classes.column}>
                  <Typography className={classes.heading}>Send reboot sms to GW(s)</Typography>
                </div>
                <div className={classes.column}>
                  <Typography className={classes.secondaryHeading}></Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => {
                    this.rebootGWs();
                  }}
                >
                  Reboot GWs
                </Button>
              </AccordionDetails>
            </Accordion>
          </>
        )}
      </div>
    );
  }
}

BuildingSettings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(null, mapDispatchToProps)(withStyles(styles)(BuildingSettings));
