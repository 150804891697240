import { FETCH_USER_DATA } from "../actions/types";

const initialState = {
  isAdmin: false,
  isSuperAdmin: false,
  isClientAdmin: false,
  isReceptionist: false,
  client: "",
  isLoading: true,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_DATA:
      if (action.payload) {
        return {
          ...state,
          isLoading: false,
          partner_logo: action.payload.partner_logo,
          partner: action.payload.partner,
          viewOnly: action.payload.viewOnly,
          isAdmin: action.payload.isAdmin,
          isSuperAdmin: action.payload.isSuperAdmin,
          isClientAdmin: action.payload.isClientAdmin,
          isReceptionist: action.payload.isReceptionist,
          client: action.payload.client,
        };
      } else {
        return { ...state };
      }
    default:
      return state;
  }
};
