import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import { saveSchedules, fetchScheduleData, addRoomEventFrontend } from "../../actions";
import { functions } from "../../config/firebase";
// import moment from "moment";
import TextField from "@mui/material/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Checkbox from "@material-ui/core/Checkbox";
import InfoTooltip from "../Common/InfoTooltip";
import Divider from "@material-ui/core/Divider";
import Snackbar from "@material-ui/core/Snackbar";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Box from "@material-ui/core/Box";
import _ from "lodash";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  USER_ACTION_ADMIN_SET_TEMP,
  USER_ACTION_ADMIN_SET_TEMP_BULK,
  USER_ACTION_ADMIN_SET_FREEZE_OFF,
  USER_ACTION_ADMIN_SET_FREEZE_OFF_BULK,
  USER_ACTION_ADMIN_SET_FREEZE_ON,
  USER_ACTION_ADMIN_SET_FREEZE_ON_BULK,
  USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_OFF,
  USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_OFF_BULK,
  USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_ON,
  USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_ON_BULK,
} from "../../constants/events";
import structuredClone from "realistic-structured-clone";
import { useForkRef } from "@material-ui/core";

const parentStyle = {
  overflow: "hidden",
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    float: "right",
    marginLeft: 20,
    marginTop: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  listTitle: {
    marginRight: 30,
    width: 240,
    fontSize: 18,
  },
  freezeProtection: {
    color: "#00838f",
  },
  paginationRow: {
    gap: "20px",
    display: "flex",
    width: "100%",
    justifyContent: "right",
  },
  newFeature: {
    color: "red",
  },
  arrow: {
    fontSize: "10px",
  },
  search: {
    marginTop: "20px",
    display: "flex",
  },
  toggleBtn: {
    minWidth: "65px",
  },
});

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  saveSchedules: (updates) => dispatch(saveSchedules(updates)),
  didMount: (id) => dispatch(fetchScheduleData(id)),
});

class SettingsZone extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open_window: false,
      leaderboard: false,
      rooms: null,
      oldRooms: null,
      open: false,
      schedules: null,
      homes: [],
      isAdmin: false,
      isSuperAdmin: false,
      isBulkSettings: false,
      main_away: false,
      main_comfort: false,
      main_freeze: null,
      main_open_window: false,
      main_leaderboard: null,
      isComfort: false,
      isAway: false,
      isFreezeSettings: false,
      mainFreeze: false,
      freezeSettingsTemp: 6,
      isChanged: false,
      page: 1,
      rowsPerPage: 25,
      pageNr: 0,
      searchText: "",
    };
  }

  handleChange = (event) => {
    let [beg, n, id] = event.target.name.split("_");

    if (this.state.searchText) {
      const rooms = this.state.rooms;
      const room = rooms.filter((room) => room.id === id);
      n = rooms.indexOf(room[0]);
    } else if (this.state.page !== 1) {
      n = parseInt(n) + (this.state.page - 1) * this.state.rowsPerPage;
    }
    if (n >= 0 || beg === "main") {
      let r = this.state.rooms;
      if (beg.indexOf("comfort") === 0) {
        r[n].zones[0].temp = event.target.value;
      } else if (beg.indexOf("away") === 0) {
        r[n].zones[1].temp = event.target.value;
      } else if (beg.indexOf("freeze") === 0) {
        r[n].isFreezeProtection = event.target.checked;
      } else if (beg.indexOf("checkbox") === 0) {
        r[n].isChecked = event.target.checked;
      } else if (beg.indexOf("main") === 0) {
        this.setState({
          [event.target.name]:
            ["main_leaderboard", "main_freeze"].indexOf(event.target.name) > -1
              ? event.target.checked
              : event.target.value,
        });
      } else if (beg.indexOf("open") === 0) {
        this.setState({
          [event.target.name]: event.target.name === "open_window" ? event.target.checked : event.target.value,
        });
      } else if (beg.indexOf("roomopenwindow") === 0) {
        r[n].open_window_disabled = event.target.checked;
      } else if (beg.indexOf("roomleaderboard") === 0) {
        r[n].leaderboard = event.target.checked;
      }

      this.setState({
        rooms: r,
        isChanged: true,
      });
      this.props.setHasChanges(true);
    }
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  handleChangeBulk = () => (event, isExpanded) => {
    this.setState({
      isBulkSettings: isExpanded,
    });
  };

  selectAll = (selected) => {
    const r = this.state.rooms;
    r.forEach((item) => {
      item.isChecked = selected;
    });

    this.setState({
      rooms: r,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    //new data comes randomly
    if (
      this.props.roomsData !== prevProps.roomsData &&
      prevProps.roomsData.firstRoomHomeId !== this.props.roomsData.firstRoomHomeId
    ) {
      this.getData();
    }
    if (this.props.schedulesData.schedules !== prevProps.schedulesData.schedules) {
      this.getSchedulesData();
    }
    if (this.props.sightsData.selectedSite !== prevProps.sightsData.selectedSite) {
      this.props.didMount(this.props.sightsData.selectedSite);
    }
  }

  getData() {
    let isMicropelt = false;
    if (this.props.roomsData.rooms[0]) {
      isMicropelt = this.props.roomsData.rooms && this.props.roomsData.rooms[0].home_id.startsWith("micropelt");
    }
    this.setState({
      rooms: this.props.roomsData.rooms,
      isSummer: this.props.sightsData.summerModeActive,
      oldRooms: structuredClone(this.props.roomsData.rooms),
      isMicropelt: isMicropelt,
      pageNr: Math.ceil(Object.keys(this.props.roomsData.rooms).length / this.state.rowsPerPage),
    });
  }

  getSchedulesData() {
    this.setState({
      schedules: this.props.schedulesData.schedules,
    });
  }

  componentDidMount() {
    this.props.didMount(this.props.sightsData.selectedSite);
    this.getData();
    this.getSchedulesData();
  }

  getOldRoomData(roomId) {
    return _.find(this.state.oldRooms, (room) => room.id === roomId);
  }

  saveData = async () => {
    if (!this.state.isBulkSettings) {
      let roomsToChange = {};
      let home_id = "";
      Object.values(this.state.rooms).forEach((room) => {
        if (room && room.home_id && roomsToChange) {
          home_id = room.home_id;
          roomsToChange = {
            ...roomsToChange,
            [room.id]: {
              leaderboard: room.leaderboard,
              isFreezeProtection: room.isFreezeProtection,
              zones: room.zones,
            },
          };
        }
      });

      const saveZonesSettings = functions.httpsCallable("saveZonesSettings");

      const resp = await saveZonesSettings({
        buildingId: home_id,
        roomsToChange,
      });
    } else {
      let home_id = "";
      const rooms = Object.values(this.state.rooms).map((room) => {
        home_id = room.home_id;
        return room.id;
      });
      const saveBulkSettings = functions.httpsCallable("saveBulkSettings");

      const resp = await saveBulkSettings({
        buildingId: home_id,
        rooms,
        comfortTemp: this.state.main_comfort,
        awayTemp: this.state.main_away,
        isFreeze: this.state.main_freeze,
        leaderboard: this.state.main_leaderboard,
      });
    }
  };

  mainFreeChange = (event, newValue) => {
    if (newValue !== null) {
      this.setState({
        main_freeze: newValue === "on",
      });
    } else {
      this.setState({
        main_freeze: null,
      });
    }
  };

  mainLeaderBoardChange = (event, newValue) => {
    if (newValue !== null) {
      this.setState({
        main_leaderboard: newValue === "on",
      });
    } else {
      this.setState({
        main_leaderboard: null,
      });
    }
  };

  getFreezeProtectionMessage() {
    if (this.state.main_freeze !== null) {
      return this.state.main_freeze ? " Turn freeze protection on" : " Turn freeze protection off";
    } else {
      return "";
    }
  }

  getLeaderBoardMessage() {
    if (this.state.main_leaderboard === null) {
      return "";
    }

    const message = this.state.main_leaderboard ? "Show on leaderboard" : "Do not show on leaderboard";
    return this.state.main_freeze === null ? message : ` | ${message}`;
  }

  render() {
    const { classes } = this.props;
    const { rooms, isBulkSettings, searchText, main_freeze, main_leaderboard } = this.state;
    let menus = [
      10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5, 17, 17.5, 18, 18.5, 19, 19.5, 20, 20.5, 21,
      21.5, 22, 22.5, 23, 23.5, 24, 24.5, 25, 25.5, 26, 26.5, 27, 27.5, 28, 28.5, 29, 29.5, 30,
    ];
    const roomsToShow = searchText
      ? rooms.filter((room) => room.name.toLowerCase().includes(searchText.toLowerCase()))
      : rooms
      ? isBulkSettings
        ? rooms
        : rooms
            .sort((a, b) => a.name > b.name)
            .slice(
              (this.state.page - 1) * this.state.rowsPerPage,
              (this.state.page - 1) * this.state.rowsPerPage + this.state.rowsPerPage
            )
      : [];
    menus = menus.reverse();
    return (
      <div style={parentStyle}>
        <InfoTooltip text="Use this tab to set default temperatures for your zones" />

        <Accordion elevation={0} onChange={this.handleChangeBulk()} expanded={isBulkSettings}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <Typography className={classes.heading}>Bulk Settings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                display: "block",
                width: "100%",
              }}
            >
              <Typography>Please set Comfort and/or Away temperature, then select the relevant Rooms.</Typography>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 20,
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="main_comfort">
                      Comfort/Room in use{" "}
                      <InfoTooltip
                        inline={true}
                        text="Set the default temperature for any zones in use for optimal comfort. Please note that users with access to QR codes can adjust this default temperature between pre-set limits"
                      />
                    </InputLabel>

                    <Select
                      value={this.state.main_comfort}
                      onChange={this.handleChange}
                      inputProps={{
                        name: "main_comfort",
                        id: "main_comfort",
                      }}
                    >
                      <MenuItem key={"d"} value={false}>
                        -
                      </MenuItem>
                      {menus.map((m) => (
                        <MenuItem key={m} value={m}>
                          {m} °C
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="main_away">
                      Away/Night
                      <InfoTooltip
                        inline={true}
                        text="Set the default temperature for zones outside of “Comfort” periods defined under Default schedule”. Use this for offices outside of office hours, at night in accommodation rooms or in-between meetings in your meeting rooms"
                      />
                    </InputLabel>
                    <Select
                      value={this.state.main_away}
                      onChange={this.handleChange}
                      inputProps={{
                        name: "main_away",
                        id: "main_away",
                      }}
                    >
                      <MenuItem key={"d"} value={false}>
                        -
                      </MenuItem>
                      {menus.map((m) => (
                        <MenuItem key={"d" + m} value={m}>
                          {m} °C
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <div>
                  <div onClick={(e) => e.stopPropagation()} style={{ cursor: "pointer" }}>
                    Freeze Protection
                  </div>
                  <ToggleButtonGroup
                    value={main_freeze === null ? null : main_freeze ? "on" : "off"}
                    onChange={this.mainFreeChange}
                    exclusive
                  >
                    <ToggleButton className={classes.toggleBtn} value="on" key="on">
                      ON
                    </ToggleButton>
                    <ToggleButton className={classes.toggleBtn} value="off" key="off">
                      OFF
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                {this.state.isMicropelt && false && (
                  <FormControlLabel
                    className={classes.formControl}
                    control={
                      <Switch
                        checked={this.state.main_open_window}
                        onChange={this.handleChange}
                        name={"open_window"}
                        color="primary"
                      />
                    }
                    label="Disable open window detection"
                    style={{
                      marginTop: 20,
                    }}
                  />
                )}
                <div>
                  <div onClick={(e) => e.stopPropagation()} style={{ cursor: "pointer" }}>
                    Show rooms on leaderboard
                  </div>
                  <ToggleButtonGroup
                    value={main_leaderboard === null ? null : main_leaderboard ? "on" : "off"}
                    onChange={this.mainLeaderBoardChange}
                    exclusive
                  >
                    <ToggleButton className={classes.toggleBtn} value="on" key="on">
                      ON
                    </ToggleButton>
                    <ToggleButton className={classes.toggleBtn} value="off" key="off">
                      OFF
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>
              <Divider
                style={{
                  marginBottom: 20,
                  marginTop: 20,
                }}
              />
              <Button
                onClick={() => {
                  this.selectAll(true);
                }}
                style={{
                  marginRight: 30,
                }}
                variant="outlined"
                size="small"
              >
                Select all
              </Button>
              <Button
                onClick={() => {
                  this.selectAll(false);
                }}
                variant="outlined"
                size="small"
              >
                Deselect all
              </Button>
            </div>
          </AccordionDetails>
        </Accordion>

        <Divider />

        {!isBulkSettings && (
          <Box className={classes.search}>
            <TextField
              label="Search for room"
              variant="outlined"
              onChange={(e) =>
                this.setState({
                  searchText: e.target.value,
                })
              }
            />
            <Box className={classes.paginationRow}>
              <p>Page:</p>
              <Select
                value={this.state.page}
                onChange={(e) =>
                  this.setState({
                    page: e.target.value,
                  })
                }
                inputProps={{
                  name: "page",
                  id: "page",
                }}
              >
                {_.times(this.state.pageNr).map((v) => (
                  <MenuItem key={v + 1} value={v + 1}>
                    {v + 1}
                  </MenuItem>
                ))}
              </Select>
              <p>Rows per page:</p>

              <Select
                value={this.state.rowsPerPage}
                onChange={(e) =>
                  this.setState({
                    rowsPerPage: e.target.value,
                    pageNr: Math.ceil(rooms.length / e.target.value),
                    page: 1,
                  })
                }
                inputProps={{
                  name: "rowsPerPage",
                  id: "rowsPerPage",
                }}
              >
                <MenuItem key={25} value={25}>
                  25
                </MenuItem>
                <MenuItem key={50} value={50}>
                  50
                </MenuItem>
                <MenuItem key={100} value={100}>
                  100
                </MenuItem>
              </Select>
            </Box>
          </Box>
        )}

        <List component="nav">
          {!rooms ? (
            <CircularProgress className="progress" />
          ) : (
            roomsToShow.map((room, index) => (
              <div key={room.id}>
                <ListItem divider>
                  {isBulkSettings && (
                    <Checkbox
                      checked={room.isChecked || false}
                      onChange={this.handleChange}
                      //inputProps={{ "name": "primary checkbox" }}
                      name={"checkbox_" + index}
                    />
                  )}
                  <Typography variant="h6" className={classes.listTitle}>
                    <Typography>{room.name}</Typography>
                    <Typography className={classes.freezeProtection}>
                      {isBulkSettings && room.isFreezeProtection ? "Freeze protection is ON" : ""}
                    </Typography>
                  </Typography>
                  <Typography variant="body1">
                    {
                      isBulkSettings &&
                        room.isChecked &&
                        (this.state.main_comfort ? "Comfort: " + this.state.main_comfort : "") +
                          (this.state.main_away ? " Away: " + this.state.main_away + "°C" : "") +
                          this.getFreezeProtectionMessage() +
                          this.getLeaderBoardMessage()
                      /*(this.state.open_window ? " Open window disabled" : "")*/
                    }
                  </Typography>
                  <div>
                    {!isBulkSettings && (
                      <>
                        {room.zones && room.zones[0] && room.zones[1] && (
                          <>
                            <FormControl className={classes.formControl}>
                              <InputLabel htmlFor="temp-simple">
                                Comfort/Room in use{" "}
                                <InfoTooltip
                                  inline={true}
                                  text="Set the default temperature for any zones in use for optimal comfort. Please note that users with access to QR codes can adjust this default temperature between pre-set limits"
                                />
                              </InputLabel>

                              <Select
                                value={room.zones[0].temp}
                                onChange={this.handleChange}
                                disabled={isBulkSettings}
                                inputProps={{
                                  name: "comfort_" + index + "_" + room.id,
                                  id: "temp-simple" + index + "_" + room.id,
                                }}
                              >
                                {menus.map((m) => (
                                  <MenuItem key={m} value={m}>
                                    {m} °C
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>

                            <FormControl className={classes.formControl}>
                              <InputLabel htmlFor="away-simple">
                                Away/Night
                                <InfoTooltip
                                  inline={true}
                                  text="Set the default temperature for zones outside of “Comfort” periods defined under Default schedule”. Use this for offices outside of office hours, at night in accommodation rooms or in-between meetings in your meeting rooms"
                                />
                              </InputLabel>
                              <Select
                                value={room.zones[1].temp}
                                onChange={this.handleChange}
                                disabled={isBulkSettings}
                                inputProps={{
                                  name: "away_" + index + "_" + room.id,
                                  id: "away-simple" + index + "_" + room.id,
                                }}
                              >
                                {menus.map((m) => (
                                  <MenuItem key={"d" + m} value={m}>
                                    {m} °C
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </>
                        )}
                        <FormControlLabel
                          className={classes.formControl}
                          control={
                            <Switch
                              checked={room.isFreezeProtection}
                              onChange={this.handleChange}
                              name={"freeze_" + index + "_" + room.id}
                              color="primary"
                              disabled={isBulkSettings}
                            />
                          }
                          label="Freeze Protection"
                          style={{
                            marginTop: 20,
                          }}
                        />
                        <FormControlLabel
                          className={classes.formControl}
                          control={
                            <Switch
                              checked={
                                (room.type === "bedroom" && typeof room.leaderboard === "undefined") ||
                                (typeof room.leaderboard !== "undefined" && room.leaderboard)
                              }
                              onChange={this.handleChange}
                              name={"roomleaderboard_" + index + "_" + room.id}
                              color="primary"
                              disabled={isBulkSettings}
                            />
                          }
                          label="Show on leaderboard"
                          style={{
                            marginTop: 20,
                          }}
                        />
                        {this.state.isMicropelt && false && room.type !== "bedroom" && (
                          <FormControlLabel
                            className={classes.formControl}
                            control={
                              <Switch
                                checked={room.open_window_disabled}
                                onChange={this.handleChange}
                                name={"roomopenwindow_" + index + "_" + room.id}
                                color="primary"
                                disabled={isBulkSettings}
                              />
                            }
                            label="Disable open window detection"
                            style={{
                              marginTop: 20,
                            }}
                          />
                        )}
                      </>
                    )}
                  </div>
                </ListItem>
              </div>
            ))
          )}
        </List>
        {!isBulkSettings && (
          <Box className={classes.paginationRow}>
            <p>Page:</p>
            <Select
              value={this.state.page}
              onChange={(e) =>
                this.setState({
                  page: e.target.value,
                })
              }
              inputProps={{
                name: "page",
                id: "page",
              }}
            >
              {_.times(this.state.pageNr).map((v) => (
                <MenuItem key={v + 1} value={v + 1}>
                  {v + 1}
                </MenuItem>
              ))}
            </Select>
            <p>Rows per page:</p>
            <Select
              value={this.state.rowsPerPage}
              onChange={(e) =>
                this.setState({
                  rowsPerPage: e.target.value,
                  pageNr: Math.ceil(rooms.length / e.target.value),
                  page: 1,
                })
              }
              inputProps={{
                name: "rowsPerPage",
                id: "rowsPerPage",
              }}
            >
              <MenuItem key={25} value={25}>
                25
              </MenuItem>
              <MenuItem key={50} value={50}>
                50
              </MenuItem>
              <MenuItem key={100} value={100}>
                100
              </MenuItem>
            </Select>
          </Box>
        )}

        {this.props.userData &&
          (this.props.userData.isAdmin || this.props.userData.isSuperAdmin || this.props.userData.isReceptionist) && (
            <Button
              onClick={this.saveData}
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={this.props.viewOnly}
            >
              Save
            </Button>
          )}

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={this.state.open}
          onClose={this.handleClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">Default zones have been saved.</span>}
          autoHideDuration={3000}
        />
      </div>
    );
  }
}

SettingsZone.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SettingsZone));
