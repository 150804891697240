import { FETCH_SCHEDULES } from "../actions/types";

const initialState = {
  schedule: null,
  schedules: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SCHEDULES:
      let schedule = action.payload;
      let schedules = action.schedules;
      let sightID = action.sightID;

      var currentSchedules = [];

      if (schedule && schedules) {
        Object.keys(schedules).forEach(function (key) {
          if (schedules[key].sight_id === sightID.toString()) {
            currentSchedules.push(schedules[key]);
          }
        });

        return {
          ...state,
          schedule: schedule,
          schedules: currentSchedules,
        };
      } else {
        return initialState;
      }

    default:
      return state;
  }
};
