import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/InfoOutlined";

const styles = (theme) => ({
  info: {
    marginRight: 0,
    textAlign: "right",
  },
  inline: {
    display: "inline-block",
    marginTop: -12,
    position: "absolute",
    top: 7,
    marginLeft: 5,
  },
});

class InfoTooltip extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={this.props.inline ? classes.inline : classes.info}>
        <Tooltip placement="left" arrow title={this.props.text}>
          <InfoIcon fontSize={this.props.small ? "small" : "medium"} />
        </Tooltip>
      </div>
    );
  }
}

export default withStyles(styles)(InfoTooltip);
