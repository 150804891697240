import React from "react";
import { connect } from "react-redux";
import "./App.css";
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from "react-router-dom";
import Navigation from "../Navigation";
import * as ROUTES from "../../constants/routes";

import Settings from "../Settings";
import Manuals from "../Manuals";
import Devices from "../Devices";

import History from "../History";

import Home from "../Home";
import Rooms from "../Rooms";
// import Calculator from "../Calculator";
import Login from "../Login";
import Reset from "../Reset";
import MobileRoom from "../MobileRoom";

import CircularProgress from "@material-ui/core/CircularProgress";
import LogRocket from "logrocket";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItemButton from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import RoomIcon from "@material-ui/icons/MeetingRoom";
import HomeIcon from "@material-ui/icons/TableChart";
import DeviceIcon from "@material-ui/icons/DeveloperBoard";
import ChartIcon from "@material-ui/icons/Today";
// import EuroIcon from "@material-ui/icons/Iso";
import SettingsIcon from "@material-ui/icons/Settings";
import ManualIcon from "@material-ui/icons/FileCopy";
import EmailIcon from "@material-ui/icons/Email";
// import SpeedIcon from "@mui/icons-material/Speed";
import AssessmentIcon from "@material-ui/icons/Assessment";
import Tooltip from "@material-ui/core/Tooltip";
import { fetchUser, fetchSights, fetchClients, fetchUserData, fetchManuals } from "../../actions";
import CarbonMeter from "../CarbonMeter";
import CarbonCounter from "../CarbonCounter";

const drawerWidth = 245;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  rootcalc: {
    width: 1100,
    margin: "auto",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    //marginLeft: drawerWidth,
    //width: `calc(100% - ${drawerWidth}px)`,
    /*transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })*/
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 12,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(6) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(8) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  listIcon: {
    marginLeft: -5,
    [theme.breakpoints.up("sm")]: {
      marginLeft: 4,
    },
  },
});

const mapStateToProps = (state) => ({
  ...state,
});

const NotFoundRedirect = () => <Redirect to="/" />;

class App extends React.Component {
  state = {
    open: false,
    authenticated: null,
  };

  componentDidMount() {
    this.props.fetchUser();
  }

  handleDrawerOpen = () => {
    this.setState({ open: !this.state.open });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.authData !== this.props.authData) {
      if (this.props.authData && this.props.authData.isAnonymous === false) {
        this.setState(
          {
            authenticated: true,
          },
          function () {
            this.props.fetchClients(this.props.authData.uid);
            this.props.fetchSights(this.props.authData.uid);
            this.props.fetchUserData(this.props.authData.uid);
          }
        );
      } else {
        this.setState({
          authenticated: false,
        });
      }
    }
    if (this.props.sightsData.selectedSite !== prevProps.sightsData.selectedSite) {
      this.props.fetchManuals(this.props.sightsData.selectedSite);
    }
    // console.log(this.props.aut)
    if (
      this.state.authenticated &&
      window.location.href.indexOf("localhost") === -1 &&
      !this.props.userData.isSuperAdmin
    ) {
      //console.log(this.props.authData)
      LogRocket.init(process.env.REACT_APP_LOGROCKET_ID);
      LogRocket.identify(process.env.REACT_APP_LOGROCKET_ID, {
        uid: this.props.authData.uid ? this.props.authData.uid : null,
        name: this.props.authData.displayName ? this.props.authData.displayName : this.props.authData.email,
        email: this.props.authData.email,
        role: this.props.userData.isAdmin
          ? "admin"
          : this.props.userData.isSuperAdmin
            ? "superadmin"
            : this.props.userData.isClientAdmin
              ? "clientadmin"
              : this.props.userData.isReceptionist
                ? "receptionist"
                : "anonymous",
      });
    } else if (
      (window.location.href.includes("eco_room_set") ||
        window.location.href.includes("eco_rmbf") ||
        window.location.href.includes("ers")) &&
      window.location.href !== "localhost"
    ) {
      console.log("Logrocket init without email");
      LogRocket.init(process.env.REACT_APP_LOGROCKET_ID);
      LogRocket.identify(process.env.REACT_APP_LOGROCKET_ID, {
        uid: "anonymous",
        name: "Anonymous",
        email: "anonymous",
      });
    }
  }

  render() {
    const { classes, theme, pageData } = this.props;
    const { isAdmin, isSuperAdmin } = this.props.userData;
    return (
      <Router>
        <div className={classes.root}>
          <CssBaseline />
          {this.props.authData === "loading" && (
            //loading screen
            <div className="loader-cont">
              <CircularProgress className={classes.progress} />
            </div>
          )}
          {this.state.authenticated === false && (
            //login screen router
            <div className={classes.rootcalc}>
              <Switch>
                <Route exact path="/" render={(props) => <Login {...props} />} />
                {/* PASSWORD RESET */}
                <Route exact path="/reset" render={(props) => <Reset {...props} />} />
                {/*<Route*/}
                {/*  exact*/}
                {/*  path={ROUTES.CALCULATOR}*/}
                {/*  render={(props) => <Calculator {...props} />}*/}
                {/*/>*/}
                <Route exact path="/ers/:uid" render={(props) => <MobileRoom {...props} />} />
                {/*THIS ONE EXPIRES */}
                <Route exact path="/eco_room_set/:id" render={(props) => <MobileRoom {...props} />} />
                {/*THIS ONE IS THE BASIC */}
                <Route
                  exact
                  path="/eco_rmbf/:id"
                  render={(props) => <MobileRoom {...props} isFreezeProtection={true} />}
                />
                <Route exact path="/eco_carbon_counter/:client_id?" render={(props) => <CarbonCounter {...props} />} />

                {/*THIS ONE IS WITH BOOST AND FREEZE PROTECTION */}
                <Route component={NotFoundRedirect} />
              </Switch>
            </div>
          )}

          {this.state.authenticated && (
            <div className={classes.root}>
              <AppBar
                position="fixed"
                className={classNames(classes.appBar, {
                  [classes.appBarShift]: this.state.open,
                })}
              >
                <Toolbar disableGutters={true}>
                  <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={this.handleDrawerOpen}
                    className={classNames(classes.menuButton)}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Navigation />
                </Toolbar>
              </AppBar>
              <Drawer
                variant="permanent"
                className={classNames(classes.drawer, {
                  [classes.drawerOpen]: this.state.open,
                  [classes.drawerClose]: !this.state.open,
                })}
                classes={{
                  paper: classNames({
                    [classes.drawerOpen]: this.state.open,
                    [classes.drawerClose]: !this.state.open,
                  }),
                }}
                open={this.state.open}
              >
                <div className={classes.toolbar}>
                  <IconButton onClick={this.handleDrawerClose}>
                    {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                  </IconButton>
                </div>

                <List>
                  <Tooltip
                    title="Home"
                    disableHoverListener={this.state.open}
                    disableTouchListener={this.state.open}
                    disableFocusListener={this.state.open}
                    placement="right"
                  >
                    <ListItemButton component={Link} to={ROUTES.HOME} selected={pageData.name === "Dashboard"}>
                      <ListItemIcon className={classes.listIcon}>
                        <HomeIcon />
                      </ListItemIcon>
                      <ListItemText primary="Home" />
                    </ListItemButton>
                  </Tooltip>
                  {(isAdmin || isSuperAdmin) && (
                    <Tooltip
                      title="Settings"
                      disableHoverListener={this.state.open}
                      disableTouchListener={this.state.open}
                      disableFocusListener={this.state.open}
                      placement="right"
                    >
                      <ListItemButton component={Link} to={ROUTES.SETTINGS} selected={pageData.name === "Settings"}>
                        <ListItemIcon className={classes.listIcon}>
                          <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Settings" />
                      </ListItemButton>
                    </Tooltip>
                  )}

                  <Tooltip
                    title="Rooms"
                    disableHoverListener={this.state.open}
                    disableTouchListener={this.state.open}
                    disableFocusListener={this.state.open}
                    placement="right"
                  >
                    <ListItemButton component={Link} to={ROUTES.ROOMS} selected={pageData.name === "Rooms"}>
                      <ListItemIcon className={classes.listIcon}>
                        <RoomIcon />
                      </ListItemIcon>
                      <ListItemText primary="Rooms" />
                    </ListItemButton>
                  </Tooltip>
                  {this.props.userData.isSuperAdmin && (
                    <Tooltip
                      title="Devices"
                      disableHoverListener={this.state.open}
                      disableTouchListener={this.state.open}
                      disableFocusListener={this.state.open}
                      placement="right"
                    >
                      <ListItemButton component={Link} to={ROUTES.DEVICES} selected={pageData.name === "Devices"}>
                        <ListItemIcon className={classes.listIcon}>
                          <DeviceIcon />
                        </ListItemIcon>
                        <ListItemText primary="Devices" />
                      </ListItemButton>
                    </Tooltip>
                  )}
                  {(isAdmin || isSuperAdmin) && (
                    <Tooltip
                      title="History"
                      disableHoverListener={this.state.open}
                      disableTouchListener={this.state.open}
                      disableFocusListener={this.state.open}
                      placement="right"
                    >
                      <ListItemButton component={Link} to={ROUTES.HISTORY} selected={pageData.name === "History"}>
                        <ListItemIcon className={classes.listIcon}>
                          <ChartIcon />
                        </ListItemIcon>
                        <ListItemText primary="History" />
                      </ListItemButton>
                    </Tooltip>
                  )}
                  {(isSuperAdmin || isAdmin) && (
                    <Tooltip
                      title="Manuals"
                      disableHoverListener={this.state.open}
                      disableTouchListener={this.state.open}
                      disableFocusListener={this.state.open}
                      placement="right"
                    >
                      <ListItemButton component={Link} to={ROUTES.MANUALS} selected={pageData.name === "Manuals"}>
                        <ListItemIcon className={classes.listIcon}>
                          <ManualIcon />
                        </ListItemIcon>
                        <ListItemText primary="Manuals" />
                      </ListItemButton>
                    </Tooltip>
                  )}
                  <Tooltip
                    title="Carbon Meter - BETA"
                    disableHoverListener={this.state.open}
                    disableTouchListener={this.state.open}
                    disableFocusListener={this.state.open}
                    placement="right"
                  >
                    <ListItemButton component={Link} to={ROUTES.CARBON} selected={pageData.name === "CarbonMeter"}>
                      <ListItemIcon className={classes.listIcon}>
                        <AssessmentIcon />
                      </ListItemIcon>
                      <ListItemText primary="CarbonMeter" />
                    </ListItemButton>
                  </Tooltip>
                  <Tooltip
                    title="Contact us"
                    disableHoverListener={this.state.open}
                    disableTouchListener={this.state.open}
                    disableFocusListener={this.state.open}
                    placement="right"
                  >
                    <ListItemButton component="a" href="https://ecosync.energy/report-issue/">
                      <ListItemIcon className={classes.listIcon}>
                        <EmailIcon />
                      </ListItemIcon>
                      <ListItemText primary="Contact us" />
                    </ListItemButton>
                  </Tooltip>
                  {/*<Tooltip*/}
                  {/*  title="Calculator"*/}
                  {/*  disableHoverListener={this.state.open}*/}
                  {/*  disableTouchListener={this.state.open}*/}
                  {/*  disableFocusListener={this.state.open}*/}
                  {/*  placement="right"*/}
                  {/*>*/}
                  {/*  <ListItem*/}
                  {/*    button*/}
                  {/*    component={Link}*/}
                  {/*    to={ROUTES.CALCULATOR}*/}
                  {/*    selected={pageData.name === "Calculator"}*/}
                  {/*  >*/}
                  {/*    <ListItemIcon className={classes.listIcon}>*/}
                  {/*      <EuroIcon />*/}
                  {/*    </ListItemIcon>*/}
                  {/*    <ListItemText primary="Calculator" />*/}
                  {/*  </ListItemButton>*/}
                  {/*</Tooltip>*/}
                </List>
              </Drawer>
              <main className={classes.content}>
                <div className={classes.toolbar} />
                <Switch>
                  <Route exact path={ROUTES.HOME} render={(props) => <Home {...props} />} />
                  {/*<Route*/}
                  {/*  exact*/}
                  {/*  path={ROUTES.CALCULATOR}*/}
                  {/*  render={(props) => <Calculator {...props} />}*/}
                  {/*/>*/}
                  <Route exact path={ROUTES.SETTINGS} render={(props) => <Settings {...props} />} />
                  <Route exact path={ROUTES.HISTORY} render={(props) => <History {...props} />} />
                  <Route exact path={ROUTES.MANUALS} render={(props) => <Manuals {...props} />} />
                  <Route
                    exact
                    path={ROUTES.CARBON}
                    render={(props) => <CarbonMeter selectedSite={this.props.sightsData.selectedSite} />}
                  />
                  <Route exact path={ROUTES.ROOMS} render={(props) => <Rooms {...props} />} />
                  <Route exact path={ROUTES.DEVICES} render={(props) => <Devices {...props} />} />
                  <Route component={NotFoundRedirect} />
                </Switch>
              </main>
            </div>
          )}
        </div>
      </Router>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, {
  fetchUser,
  fetchSights,
  fetchClients,
  fetchUserData,
  fetchManuals,
})(withStyles(styles, { withTheme: true })(App));
