import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import moment from "moment-timezone";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TempGraph from "./TempGraph";
import HeatGraph from "./HeatGraph";
import HeatGraphMicropelt from "./HeatGraphMicropelt";
import EventHistory from "./EventHistory";
import "moment/locale/en-gb";
import _ from "lodash";
import { DatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { fetchSettings } from "../../actions";

moment.locale("en-gb");

const styles = (theme) => ({
  root: theme.mixins.gutters({
    marginBottom: 16,
    minWidth: 1000,
    paddingTop: 12,
    paddingBottom: 12,
  }),
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  datePicker: {
    marginTop: 24,
  },
});

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSettings: () => dispatch(fetchSettings()),
  didMount: () =>
    dispatch({
      type: "PAGE_CHANGED",
      payload: "History",
    }),
});

class History extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      youtube: "",
      timezone: "Europe/London",
      roomId: 0,
      homeId: "",
      rooms: [],
      date_begin: moment().tz("Europe/London").startOf("day").format("X"),
      date_type: "day",
      selectedDate: moment(),
      date_end: moment().tz("Europe/London").endOf("day").format("X"),
      homes: this.props.homes,
    };
  }

  componentDidMount() {
    this.props.didMount();
    this.setup();
  }

  handleDateChange = (date) => {
    this.setState({
      selectedDate: date,
      date_begin: moment(date).tz(this.state.timezone).startOf(this.state.date_type).format("X"),
      date_end: moment(date).tz(this.state.timezone).endOf(this.state.date_type).format("X"),
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.roomsData.firstRoomId !== prevProps.roomsData.firstRoomId) {
      this.setup();
    }
  }

  setup = () => {
    this.props.fetchSettings();

    this.setState({
      timezone: this.props.sightsData.timezone ? this.props.sightsData.timezone : "Europe/London",
      roomId: this.props.roomsData.firstRoomId,
      homeId: this.props.roomsData.firstRoomHomeId,
      roomName:
        this.props.roomsData.rooms && this.props.roomsData.rooms.length ? this.props.roomsData.rooms[0].name : "",
    });
  };

  handleChange = (event, child) => {
    this.setState({ [event.target.name]: event.target.value });

    if (event.target.name === "roomId") {
      var h = _.find(this.props.roomsData.rooms, { id: event.target.value });
      this.setState({ homeId: h.home_id, roomName: h.name });
    }

    if (event.target.name === "date_type") {
      this.setState({
        date_begin: moment(this.selectedDate).tz(this.state.timezone).startOf(event.target.value).format("X"),
        date_end: moment(this.selectedDate).tz(this.state.timezone).endOf(event.target.value).format("X"),
      });
    }
  };

  render() {
    const {
      classes,
      roomsData,
      userData,
      sightsData,
      sightsData: { notificationTexts, timezone },
    } = this.props;
    const { selectedDate, homeId } = this.state;
    const isSuperAdmin = userData.isSuperAdmin;
    const isMicropelt = homeId && homeId.startsWith("micropelt");

    return (
      <div id="sectionHolder">
        <Paper className={classes.root}>
          <form className={classes.container} autoComplete="off">
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="room-simple">Room</InputLabel>

              <Select
                value={this.state.roomId}
                onChange={this.handleChange}
                inputProps={{
                  name: "roomId",
                  id: "room-simple",
                }}
              >
                {roomsData.rooms &&
                  roomsData.rooms.map((room) => (
                    <MenuItem key={room.id} value={room.id} id={room.home_id}>
                      {room.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="date_type-simple">Timeline</InputLabel>
              <Select
                value={this.state.date_type}
                onChange={this.handleChange}
                inputProps={{
                  name: "date_type",
                  id: "date_type-simple",
                }}
              >
                <MenuItem value={"day"}>Day</MenuItem>
                <MenuItem value={"isoWeek"}>Week</MenuItem>
                <MenuItem value={"month"}>Month</MenuItem>
                <MenuItem value={"year"}>Year</MenuItem>
              </Select>
            </FormControl>

            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                value={selectedDate}
                onChange={this.handleDateChange}
                animateYearScrolling={false}
                maxDate={moment()}
                className={classes.datePicker}
              />
            </MuiPickersUtilsProvider>
          </form>
        </Paper>

        {/*<HistoryExport
          roomId={this.state.roomId}
          homeId={this.state.homeId}
          date_begin={this.state.date_begin}
          roomName={this.state.roomName}
        />*/}

        {!isMicropelt ? (
          <>
            <Paper className={classes.root}>
              <TempGraph
                roomId={this.state.roomId}
                homeId={this.state.homeId}
                date_begin={this.state.date_begin}
                date_end={this.state.date_end}
                date_type={this.state.date_type}
                isSuperAdmin={isSuperAdmin}
              />
            </Paper>
            <Paper className={classes.root}>
              <HeatGraph
                roomId={this.state.roomId}
                homeId={this.state.homeId}
                date_begin={this.state.date_begin}
                date_end={this.state.date_end}
                date_type={this.state.date_type}
              />
            </Paper>
          </>
        ) : (
          <>
            <Paper className={classes.root}>
              <HeatGraphMicropelt
                roomId={this.state.roomId}
                homeId={this.state.homeId}
                date_begin={this.state.date_begin}
                date_end={this.state.date_end}
                date_type={this.state.date_type}
                visibleNotifications={this.props.sightsData.visibleNotifications}
                notificationTexts={notificationTexts}
                isSuperAdmin={isSuperAdmin}
                bookedHistory={sightsData.bookedHistory}
                timezone={timezone}
              />
            </Paper>

            <EventHistory
              roomId={this.state.roomId}
              homeId={this.state.homeId}
              date_begin={this.state.date_begin}
              date_end={this.state.date_end}
              date_type={this.state.date_type}
              classes={this.props.classes}
              isSuperAdmin={isSuperAdmin}
              notificationTexts={notificationTexts}
              visibleEventsInHistory={this.props.sightsData.visibleEventsInHistory}
              timezone={timezone}
            />
          </>
        )}
      </div>
    );
  }
}
History.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(History));
