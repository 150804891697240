import React from "react";
import PropTypes from "prop-types";
import JSONPretty from "react-json-pretty";
import Card from "@material-ui/core/Card";
import Chip from "@material-ui/core/Chip";
import { Tooltip, withStyles } from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Check from "@material-ui/icons/Check";
import Typography from "@material-ui/core/Typography";
import Error from "@material-ui/icons/Error";
import WifiOff from "@material-ui/icons/SignalWifiOff";
import WifiGood from "@material-ui/icons/SignalWifi4Bar";
import WifiMedium from "@material-ui/icons/SignalWifi3Bar";
import WifiLow from "@material-ui/icons/SignalWifi1Bar";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import BatteryFull from "@material-ui/icons/BatteryFull";
import BatteryHigh from "@material-ui/icons/Battery90";
import BatteryMedium from "@material-ui/icons/Battery60";
import BatteryLow from "@material-ui/icons/Battery30";
import BatteryVeryLow from "@material-ui/icons/Battery20";
import BatteryAlert from "@material-ui/icons/BatteryAlert";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import axios from "axios";

import GatewayDialog from "../GatewayDialog";
import { Button } from "@mui/material";
import { functions } from "../../config/firebase";

const STATUS_ONLINE = 0;
const STATUS_UNKNOWN_ERROR = 1;
const STATUS_SIGNAL_ERROR = 2;
const STATUS_MECHANICAL_ERROR = 3;
const STATUS_DEVICE_OFF = 4;
const STATUS_FLAKY_SIGNAL = 5;

const styles = (theme) => ({
  mytile: {
    minHeight: 180,
    width: 300,
    margin: 10,
  },
  celsius: {
    marginTop: 10,
    marginBottom: 10,
    color: "#673AB7",
  },
  heating: {
    marginTop: -50,
    marginBottom: 10,
    float: "right",
    fontSize: 50,
  },
  cardtitle: {
    marginBottom: 10,
  },
  header: {
    paddingBottom: 0,
  },
  headertitle: {
    fontSize: 17,
    width: 240,
  },
  chip: {
    marginRight: 10,
    marginBottom: 10,
  },
  dialog: {
    minwidth: "300",
  },
  dialogContent: {
    display: "flex",
    marginBottom: 15,
    marginLeft: 10,
    marginRight: 10,
    padding: 10,
  },
  info: {
    fontSize: 14,
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
    },
  },
  info2: {
    fontSize: 14,
    marginLeft: 5,
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
    },
  },
  waitCursor: {
    cursor: "wait",
  },
  buttonLoading: {
    cursor: "wait !important",
  },
});

class Device extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      device: this.props.device,
      gwOpen: false,
      connectivity: "",
    };
  }

  componentDidMount() {
    const getConnectivity = async () => {
      const data = await axios
        .get(`https://room.mt/gw_online/?debug=1&ami=${this.props.device.name.toLowerCase()}`)
        .then((resp) => {
          return resp;
        })
        .catch((err) => {
          console.log(err);
        });
      if (data) {
        if (data.data.includes("CELLULAR")) {
          this.setState({ connectivity: "CELLULAR" });
        } else if (data.data.includes("ETHERNET")) {
          this.setState({ connectivity: "ETHERNET" });
        } else {
          this.setState({ connectivity: "Nothing connected!" });
        }
      }
    };
    getConnectivity();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.device !== this.props.device) {
      this.setState({
        device: this.props.device,
      });
    }
  }

  getRfStrength(rf) {
    if (rf) {
      if (rf > 90) {
        return (
          <Chip
            label="RF: Low"
            icon={<WifiLow />}
            className={this.props.classes.chip}
            variant="outlined"
            color="secondary"
          />
        );
      } else if (rf <= 90 && rf > 80) {
        return (
          <Chip
            label="RF: Medium"
            icon={<WifiMedium />}
            variant="outlined"
            color="primary"
            className={this.props.classes.chip}
          />
        );
      } else {
        return (
          <Chip
            label="RF: High"
            icon={<WifiGood />}
            variant="outlined"
            color="primary"
            className={this.props.classes.chip}
          />
        );
      }
    }
  }

  getBattery(device) {
    if (device.battery_state) {
      if (device.battery_state === "full") {
        return (
          <Chip
            label={"Full (" + device.battery_level + ")"}
            icon={<BatteryFull />}
            className={this.props.classes.chip}
            variant="outlined"
            color="primary"
          />
        );
      }

      if (device.battery_state === "high") {
        return (
          <Chip
            label={"High (" + device.battery_level + ")"}
            icon={<BatteryHigh />}
            className={this.props.classes.chip}
            variant="outlined"
            color="primary"
          />
        );
      }
      if (device.battery_state === "medium") {
        return (
          <Chip
            label={"Medium (" + device.battery_level + ")"}
            icon={<BatteryMedium />}
            className={this.props.classes.chip}
            color="secondary"
            variant="outlined"
          />
        );
      }
      if (device.battery_state === "low") {
        return (
          <Chip
            label={"Low (" + device.battery_level + ")"}
            icon={<BatteryLow />}
            className={this.props.classes.chip}
            color="secondary"
          />
        );
      }
      if (device.battery_state === "very_low") {
        return (
          <Chip
            label={"Very Low (" + device.battery_level + ")"}
            icon={<BatteryVeryLow />}
            className={this.props.classes.chip}
            color="secondary"
          />
        );
      }
    } else {
      return <Chip label="No Battery" icon={<BatteryAlert />} className={this.props.classes.chip} color="secondary" />;
    }
  }

  getDeviceName(device) {
    return device.hardware === "micropelt" || device.hardware === "elsys" ? device.room_name : device.name;
  }

  getDeviceId(device) {
    if (device.hardware === "micropelt" || device.hardware === "elsys") {
      return device.name;
    }
    if (device.type === "gateway") {
      return device.deviceEUI;
    }
    return "";
  }

  getFailure(device) {
    let failures = [];
    if (device.valve_sensor_failure) {
      failures.push("Valve Sensor Failure");
    }
    if (device.motor_failure) {
      failures.push("Motor Failure");
    }
    if (device.ambient_sensor_failure) {
      failures.push("Ambient Sensor Failure");
    }
    return failures.join(", ");
  }

  handleReferenceRun = async () => {
    document.body.classList.add(this.props.classes.waitCursor);

    this.setState({ isLoading: true });
    const { id, room_id, home_id } = this.props.device; // Adjust as per your data structure
    const by = this.props.authData.email;

    const manualReferenceRun = functions.httpsCallable("manualReferenceRun");

    try {
      const result = await manualReferenceRun({
        deviceID: id,
        room_id,
        home_id,
        by,
      });

      if (result.data === "success") {
        alert("The reference run has been requested, please check back in 10 minutes to see the results");
      } else if (result.data === "timerestriction") {
        alert("ERROR: you can only issue 1 reference run every 30 minutes");
      }
    } catch (error) {
      console.error("Error calling manualReferenceRun:", error);
      // Handle any other errors appropriately
    } finally {
      document.body.classList.remove(this.props.classes.waitCursor);
      this.setState({ isLoading: false });
    }
  };

  render() {
    const { classes } = this.props;
    const { device } = this.state;
    const { isLoading } = this.state;

    return (
      <div>
        <Card className={classes.mytile} key={device.id}>
          <CardHeader
            title={this.getDeviceName(device) + " (" + device.type + ")"}
            classes={{
              root: classes.header, // class name, e.g. `classes-nesting-root-x`
              title: classes.headertitle,
              subheader: classes.subheadertitle,
            }}
            subheader={this.getDeviceId(device)}
          />
          <CardContent>
            {(device.type === "NRV" || device.type === "MRV") && (
              <span>
                {device.reachable ? (
                  <Chip
                    label="Reachable"
                    icon={<Check />}
                    variant="outlined"
                    color="primary"
                    className={classes.chip}
                  />
                ) : (
                  <Chip label="Not reachable!" icon={<Error />} color="secondary" className={classes.chip} />
                )}

                {device.rf_strength && device.status !== STATUS_FLAKY_SIGNAL ? (
                  this.getRfStrength(device.rf_strength)
                ) : device.hardware === "micropelt" && device.reachable ? (
                  <Chip
                    label="RF: Low"
                    icon={<WifiLow />}
                    className={classes.chip}
                    variant="outlined"
                    color="secondary"
                  />
                ) : (
                  <Chip label="No signal" icon={<WifiOff />} color="secondary" className={classes.chip} />
                )}

                {device.hardware === "micropelt" &&
                  (device.valve_sensor_failure || device.motor_failure || device.ambient_sensor_failure ? (
                    <Tooltip title={this.getFailure(device)} interactive>
                      <Chip label="Sensor Failure" color="secondary" className={classes.chip} />
                    </Tooltip>
                  ) : null)}

                {this.getBattery(device)}
              </span>
            )}
            {device.type === "ERS" && <span>{this.getBattery(device)}</span>}
            {device.type === "gateway" && (
              <Chip
                label={device.isOffline ? "Offline" : "Online"}
                icon={device.isOffline ? <Error /> : <Check />}
                variant="outlined"
                color={device.isOffline ? "secondary" : "primary"}
                className={classes.chip}
              />
            )}
            {this.state.connectivity === "CELLULAR" && (
              <Chip
                label={this.state.connectivity}
                icon={<PhoneAndroidIcon />}
                variant="outlined"
                color="primary"
                className={classes.chip}
              />
            )}
            <Typography component="p">
              {device.wifi_strength && <span>WIFI Strength: {device.wifi_strength}</span>}
            </Typography>

            {(device.hardware === "micropelt" || device.hardware === "elsys") && (
              <>
                <Tooltip title={<JSONPretty id="json-pretty" data={device}></JSONPretty>} interactive>
                  <InfoIcon />
                </Tooltip>
                {device.type === "MRV" && (
                  <div className={classes.actions}>
                    <Button
                      onClick={this.handleReferenceRun}
                      className={isLoading ? classes.buttonLoading : classes.setTerm}
                      size="small"
                    >
                      Request Reference Run
                    </Button>
                  </div>
                )}
              </>
            )}

            {device.type === "gateway" && <InfoIcon onClick={() => this.setState({ gwOpen: !this.state.gwOpen })} />}
          </CardContent>

          {/*
            <CardActions>
            {device.room_id && (
              <Button
                size="small"
                color="primary"
                component={Link}
                to={"/rooms/" + device.room_id}
              >
                Room
              </Button>
            )}

            {device.modules_bridged &&
              device.type !== "NAPlug" &&
              device.modules_bridged.map((module, index) => (
                <Button
                  size="small"
                  color="primary"
                  component={Link}
                  to={"/devices/" + module}
                  key={module}
                >
                  Module {index + 1}
                </Button>
              ))}
          </CardActions>
          */}
        </Card>
        <GatewayDialog
          gateway={this.state.device}
          isSuperAdmin={true}
          isOpen={this.state.gwOpen}
          setDialog={() => this.setState({ gwOpen: false })}
        />
      </div>
    );
  }
}

Device.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Device);
