import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import ImageIcon from "@material-ui/icons/Warning";
import DoneIcon from "@material-ui/icons/Done";
import Notifications from "@material-ui/icons/Notifications";
import NotificationsOff from "@material-ui/icons/NotificationsOff";
import pink from "@material-ui/core/colors/pink";
import green from "@material-ui/core/colors/green";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { resolutionType } from "./resolutions";
import { subscribeToSightNotifications, resolveNotification } from "../../actions";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import {
  FIRE,
  OPEN_WINDOW,
  NO_HEATING,
  NO_HEATING_CONTROL,
  STUCK_PIN,
  OFFLINE_TRV,
  OFFLINE_GW,
  MECHANICAL_ERROR,
  CELLULAR_GW,
  REFERENCE_RUN,
  MANUAL_REFERENCE_RUN,
  EXTRA_HEAT_SOURCE,
  AWAY_LOW_TEMP,
} from "../../constants/events";

const styles = (theme) => ({
  root: {
    width: 500,
    backgroundColor: "#ECEFF1",
  },
  flex: {
    flex: 1,
  },
  pinkAvatar: {
    margin: 5,
    color: "#fff",
    backgroundColor: pink[500],
  },
  greenAvatar: {
    margin: 5,
    color: "#fff",
    backgroundColor: green[500],
  },
  padd: {
    paddingLeft: 12,
    paddingRight: 12,
    marginTop: 12,
    marginBottom: 12,
  },
});

function sortByCreatedAt(a, b) {
  let comparison = 0;
  if (a.created_at > b.created_at) {
    comparison = -1;
  } else if (a.created_at < b.created_at) {
    comparison = 1;
  }
  return comparison;
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  subscribeToSightNotifications: (id) => dispatch(subscribeToSightNotifications(id)),
  resolveNotification: (obj) => dispatch(resolveNotification(obj)),
});

class NotificationsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      notifications: [],
      notifications_ack: [],
      notificationTexts: [],
      visibleNotifications: [],
      isNotificationEnabled: true,
      dialogOpen: false,
      selectedNotificationId: null,
      FIRE: FIRE,
      OPEN_WINDOW: OPEN_WINDOW,
      NO_HEATING: NO_HEATING,
      NO_HEATING_CONTROL: NO_HEATING_CONTROL,
      STUCK_PIN: STUCK_PIN,
      OFFLINE_TRV: OFFLINE_TRV,
      OFFLINE_GW: OFFLINE_GW,
      MECHANICAL_ERROR: MECHANICAL_ERROR,
      CELLULAR_GW: CELLULAR_GW,
      REFERENCE_RUN: REFERENCE_RUN,
      MANUAL_REFERENCE_RUN: MANUAL_REFERENCE_RUN,
      EXTRA_HEAT_SOURCE: EXTRA_HEAT_SOURCE,
      AWAY_LOW_TEMP: AWAY_LOW_TEMP,
      selectedNotification: {
        text: "",
        type: 0,
      },
    };
  }

  componentDidMount() {
    // console.log(this.props.sightsData)
    this.setNotifications();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.sightsData.selectedSite !== prevProps.sightsData.selectedSite) {
      this.setNotifications();
    }

    if (this.props.sightsData.notificationsCount !== prevProps.sightsData.notificationsCount) {
      this.setNotifications();
    }
  }

  setNotifications() {
    var obj = this.props.sightsData;
    //console.log("setnotifications");

    if (obj) {
      var notifications = [];
      var notifications_ack = [];

      if (obj.notifications) {
        Object.keys(obj.notifications).forEach(function (key) {
          obj.notifications[key].id = key;

          notifications.push(obj.notifications[key]);
        });
      }
      if (obj.notifications_ack) {
        Object.keys(obj.notifications_ack).forEach(function (key) {
          obj.notifications_ack[key].id = key;

          notifications_ack.push(obj.notifications_ack[key]);
        });

        notifications_ack.sort(sortByCreatedAt);

        if (notifications_ack.length >= 30) {
          notifications_ack = notifications_ack.slice(0, 30);
        }
      }

      this.setState({
        notifications: notifications.sort(sortByCreatedAt),
        notifications_ack: notifications_ack,
        isNotificationEnabled: obj.notificationsEnabled,
        visibleNotifications: obj.visibleNotifications,
        notificationTexts: obj.notificationTexts,
      });
    } else {
      this.setState({
        notifications: [],
        notifications_ack: [],
      });
    }
  }

  handleResolve = async () => {
    document.body.style.cursor = "wait";
    let obj = this.state.selectedNotification;
    obj.resolved_at = moment().unix();
    obj.by = this.props.authData.email;
    obj.sightId = this.props.sightsData.selectedSite;
    obj.id = this.state.selectedNotificationId;

    try {
      await this.props.resolveNotification(obj);
      this.setState({
        dialogOpen: false,
      });
    } catch (error) {
      alert("error" + error);
    } finally {
      // Change cursor back to default version
      document.body.style.cursor = "default";
    }
  };

  handleClose = () => {
    this.setState({ dialogOpen: false });
  };

  handleToggle = (value) => () => {
    const currentIndex = value;
    let not = this.state.notifications[currentIndex];
    this.setState({
      dialogOpen: true,
      selectedNotificationId: not.id,
      selectedNotification: not,
    });
  };

  render() {
    const { classes, userData } = this.props;
    const {
      notifications,
      notifications_ack,
      selectedNotification,
      isNotificationEnabled,
      visibleNotifications,
      notificationTexts,
    } = this.state;
    const isSuperAdmin = userData.isSuperAdmin;
    const isMicropelt = this.props.sightsData.selectedSite.toString().startsWith("micropelt");
    let counter = 0;
    let counter_r = 0;
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" color="inherit">
              Notifications
            </Typography>
            {isNotificationEnabled ? <Notifications /> : <NotificationsOff />}
          </Toolbar>
        </AppBar>
        <div className={classes.padd}>
          <Typography variant="h6">Active</Typography>
          <Paper>
            <List>
              {notifications.length > 0 &&
                notifications.map((item, index) => {
                  if (!isSuperAdmin && isMicropelt) {
                    return visibleNotifications.map((notificationType) => {
                      if (item.id.toString().startsWith(this.state[notificationType].toString())) {
                        counter++;
                        return (
                          <ListItem
                            key={item.id}
                            button
                            className={classes.listItem}
                            onClick={this.handleToggle(index)}
                          >
                            <Avatar className={classes.pinkAvatar}>
                              <ImageIcon />
                            </Avatar>
                            <ListItemText
                              primary={`${item.text}`}
                              secondary={` ${moment.unix(item.created_at).fromNow()}`}
                            />
                          </ListItem>
                        );
                      } else {
                        return null;
                      }
                    });
                  } else {
                    counter++;
                    return (
                      <ListItem key={item.id} button className={classes.listItem} onClick={this.handleToggle(index)}>
                        <Avatar className={classes.pinkAvatar}>
                          <ImageIcon />
                        </Avatar>
                        <ListItemText
                          primary={`${item.text}`}
                          secondary={` ${moment.unix(item.created_at).fromNow()}`}
                        />
                      </ListItem>
                    );
                  }
                })}
              {(!notifications.length || !counter) && (
                <ListItem key={"nope"} className={classes.listItem} disabled>
                  <ListItemText primary="No active notifications" />
                </ListItem>
              )}
            </List>
          </Paper>

          <br />
          <Typography variant="h6">Resolved</Typography>
          <Paper>
            <List>
              {notifications_ack.length > 0 &&
                notifications_ack.map((value, index) => {
                  if (!isSuperAdmin && isMicropelt) {
                    return visibleNotifications.map((notificationType) => {
                      if (value.type.toString() === this.state[notificationType].toString()) {
                        counter_r++;
                        return (
                          <div key={value.id}>
                            <ListItem key={value.id} className={classes.listItem} dense>
                              <Avatar className={classes.greenAvatar}>
                                <DoneIcon />
                              </Avatar>
                              <ListItemText
                                primary={`${value.text}`}
                                secondary={`Resolved by: ${value.by} at ${moment
                                  .utc(value.resolved_at.value)
                                  .format("HH:mm - DD. MMM YYYY")}`}
                              />
                            </ListItem>
                            <Divider />
                          </div>
                        );
                      } else {
                        return null;
                      }
                    });
                  } else {
                    counter_r++;
                    return (
                      <div key={value.id}>
                        <ListItem key={value.id} className={classes.listItem} dense>
                          <Avatar className={classes.greenAvatar}>
                            <DoneIcon />
                          </Avatar>
                          <ListItemText
                            primary={`${value.text}`}
                            secondary={`Resolved by: ${value.by} at ${moment
                              .utc(value.resolved_at.value)
                              .format("HH:mm - DD. MMM YYYY")}`}
                          />
                        </ListItem>
                        <Divider />
                      </div>
                    );
                  }
                })}
              {(!notifications_ack.length || !counter_r) && (
                <ListItem key={"nope"} className={classes.listItem} disabled>
                  <ListItemText primary="No resolved notifications" />
                </ListItem>
              )}
            </List>
          </Paper>
        </div>

        <Dialog
          open={this.state.dialogOpen}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{selectedNotification.text}</DialogTitle>
          <DialogContent>
            <DialogContent id="alert-dialog-description">
              {resolutionType(selectedNotification.type, notificationTexts)}
            </DialogContent>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
            {userData && (userData.isAdmin || userData.isSuperAdmin) && (
              <Button
                onClick={this.handleResolve}
                color="secondary"
                variant="contained"
                autoFocus
                disabled={userData.viewOnly}
              >
                Mark as resolved
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

NotificationsPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NotificationsPage));
