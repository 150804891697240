import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import "./LoginDialog.css";
import TextField from "@material-ui/core/TextField";

import { login } from "../../actions";

const styles = {
  card: {
    maxWidth: 320,
    height: 360,
  },
  media: {
    height: 120,
  },
  loginButton: {
    marginLeft: "auto !important",
  },
};

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  login: (email, password) => dispatch(login(email, password)),
});

class Login extends React.Component {
  state = {
    email: "",
    password: "",
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  startLogin = () => {
    if (this.state.email && this.state.password) {
      this.props.login(this.state.email, this.state.password);
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div className="cont">
        <Card className={classes.card}>
          <CardMedia
            className={classes.media}
            image="https://images.unsplash.com/photo-1495192432427-e9010da2fbc7?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=4a760112bc72e72c43a78c855ec04732&auto=format&fit=crop&w=400&h=200&q=80"
            title="EcoSync"
          />
          <CardContent>
            <Typography variant="h5" component="h2">
              EcoSync Login
            </Typography>
            <TextField
              autoFocus
              margin="dense"
              id="email"
              label="Email Address"
              type="email"
              fullWidth
              onChange={this.handleChange("email")}
            />
            <TextField
              margin="dense"
              id="password"
              label="Password"
              type="password"
              fullWidth
              onChange={this.handleChange("password")}
            />
          </CardContent>
          <CardActions>
            <div>
              <Link to="/reset">Forgotten Password</Link>
            </div>
            <Button variant="contained" color="primary" onClick={this.startLogin} className={classes.loginButton}>
              Login
            </Button>
          </CardActions>
        </Card>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));
