import React from "react";
import { useParams } from "react-router";
import { withStyles } from "@material-ui/core/styles";
import { Card, CardMedia, Typography, Box, CircularProgress } from "@mui/material";

import { auth, functions, database } from "../../config/firebase";

const styles = (theme) => ({
  "@global": {
    body: {
      backgroundColor: "#fff !important",
    },
    "#root": {
      backgroundColor: "#fff !important",
    },
  },
  card: {
    maxWidth: 480,
    height: "80px !important",
    margin: "auto",
    marginTop: 0,
    boxShadow: "0px 0px 0px 0px white !important",
  },
  media: {
    height: 80,
    backgroundPosition: "top center",
  },
  text: {
    margin: "auto",
    marginTop: 0,
    marginBottom: 10,
    fontSize: 14.4,
    textAlign: "center",
    paddingLeft: 10,
    paddingRight: 10,
    fontFamily: "Arvo, serif !important",
    fontWeight: "400px !important",
    letterSpacing: "0.02em !important",
    color: "#5c5b5b !important",
    lineHeight: 1,
  },
});

const CarbonCounter = (props) => {
  const { client_id } = useParams();
  const { classes } = props;
  const [isLoading, setIsLoading] = React.useState(true);
  const [counter, setCounter] = React.useState(0);
  const [totalSavings, setTotalSavings] = React.useState(0);
  const [trees, setTrees] = React.useState(0);
  const [todaySavingCO2, setTodaySavingCO2] = React.useState(0);
  const [totalSavingCO2, setTotalSavingCO2] = React.useState(0);
  const [client, setClient] = React.useState("");
  const kiloPerTree = 21;

  React.useEffect(() => {
    login();
    if (client_id) {
      getClient();
    }
  }, []);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (counter % 16 === 0) {
        getCarbonCounter();
      } else {
        const midTime = new Date(new Date().setHours(0, 0, 0, 0));
        const currentTime = new Date();
        const secondsSinceMidnight = parseInt((currentTime.getTime() - midTime.getTime()) / 1000);
        let total = totalSavingCO2 + (todaySavingCO2 * secondsSinceMidnight) / (24 * 60 * 60);
        setTotalSavings(total);
        setTrees(Math.ceil(total / kiloPerTree));
      }
      setCounter((count) => count + 1);
    }, 1000);
    return () => clearTimeout(timer);
  }, [counter]);

  const login = () => {
    auth.signInAnonymously().catch(function (error) {
      console.log(error);
    });
  };

  const getClient = async () => {
    const client = database.ref("clients/" + client_id);

    const snapshot = await client.once("value");
    if (snapshot.exists()) {
      setClient(snapshot.val().name);
    }
  };

  const getCarbonCounter = async () => {
    let carbonCounter = functions.httpsCallable("getCarbonCounter");
    try {
      await carbonCounter({
        client_id: client_id,
      }).then((res) => {
        setTodaySavingCO2(res.data.todaySavingCO2);
        setTotalSavingCO2(res.data.totalSavingCO2);
        setIsLoading(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const formatNumberWithSpaces = (number) => {
    return number.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  return (
    <>
      <Card className={classes.card} sx={{ minHeight: "95vh" }}>
        <Box>
          {isLoading ? (
            <CircularProgress className="progress" />
          ) : totalSavings !== null && totalSavings > 0 ? (
            <>
              {client_id ? (
                <Typography className={classes.text} variant="body1" align="center">
                  Congratulations you've saved <strong>{totalSavings.toFixed(8)} kg</strong> of carbon. Please take a
                  look at the carbon counter section to see a complete breakdown.
                </Typography>
              ) : (
                <Typography className={classes.text} variant="body1" align="center">
                  Here at EcoSync we've helped our clients save a combined
                  <br />
                  <strong>{formatNumberWithSpaces(totalSavings)} kg</strong> of carbon
                  <br />
                  which is equivalent to {trees} trees since (January 11, 2018)
                </Typography>
              )}
            </>
          ) : (
            <CircularProgress className="progress" />
          )}
        </Box>
      </Card>
    </>
  );
};

export default withStyles(styles)(CarbonCounter);
