import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import LinearProgress from "@material-ui/core/LinearProgress";
import { TimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import Circle from "@material-ui/icons/FiberManualRecord";
import EventIcon from "@material-ui/icons/Event";
import CircleOut from "@material-ui/icons/FiberManualRecordOutlined";
import Error from "@material-ui/icons/Error";
import MomentUtils from "@date-io/moment";
import { functions } from "../../config/firebase";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";

const styles = (theme) => ({
  mytile: {
    height: "100%",
  },
  celsius: {
    color: "#00838f",
    fontSize: 36,
    display: "block",
    marginBottom: 10,
  },
  heating_off: {
    fontSize: 13,
    color: "#aaa",
    display: "inline",
    marginLeft: 3,
    position: "relative",
  },
  heating_on: {
    fontSize: 13,
    color: "green",
    display: "inline",
    marginLeft: 3,
    position: "relative",
  },
  heating_off_icon: {
    color: "#ddd",
    width: 25,
    height: 25,
    display: "inline",
  },
  heating_on_icon: {
    color: "green",
    width: 25,
    height: 20,
    display: "inline",
  },
  button: {
    margin: theme.spacing(1),
  },
  dialogContent: {
    display: "flex",
    justifyContent: "space-around",
  },
  progress: {
    margin: 20,
  },
  time: {
    marginLeft: 0,
    marginTop: 0,
  },
  griditem: {},
  header: {
    paddingBottom: 0,
  },
  headertitle: {
    fontSize: 17,
  },
  setpoint: {
    color: "#999",
    fontSize: 14,
    display: "block",
  },
  chip: {
    marginLeft: 10,
    marginTop: 10,
  },
  heatdeatils: {
    display: "flex",
    flexDirection: "row",
    marginTop: 10,
    alignItems: "center",
    width: "100%",
  },
  content: {
    paddingBottom: 0,
    minHeight: 90,
  },
  actions: {
    display: "flex",
  },

  calchip: {
    marginLeft: 10,
  },
  upArrow: {
    transform: "rotate(-60deg)",
    color: "#00838f",
    zIndex: 10,
  },
  downArrow: {
    transform: "rotate(60deg)",
    color: "#00838f",
    zIndex: 10,
  },
});

class Room extends React.Component {
  constructor(props) {
    super(props); //console.log(this.props.room)

    this.state = {
      room: this.props.room,
      isAdmin: this.props.isAdmin,
      isSuperAdmin: this.props.isSuperAdmin,
      home_id: this.props.home_id,
      dialogOpen: false,
      comfortTemp: parseFloat(
        this.props.room.zones && this.props.room.zones[0] && this.props.room.zones[0].temp !== "TODO"
          ? this.props.room.zones[0].temp
          : 12,
        10
      ).toFixed(1),
      loading: false,
      setpointTime: moment().add(1, "hours").toDate(),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.room !== this.props.room) {
      this.setState({
        room: this.props.room,
      });
    }
  }

  async setData(isDefault) {
    const setTemp = functions.httpsCallable("setComfortTemp");
    const resp = await setTemp({
      roomId: this.state.room.id,
      temp: parseFloat(this.state.comfortTemp),
      siteId: this.state.room.home_id,
    });
    this.setState({ open: false, loading: false });
  }

  handleClickOpen = () => {
    this.setState({
      dialogOpen: true,
      setpointTime: moment().add(1, "hours").toDate(),
    });
  };

  handleClose = () => {
    this.setState({ dialogOpen: false });
  };
  handleChange = (time) => {
    this.setState({
      setpointTime: time,
    });
  };

  setTemp = () => {
    this.setState({ loading: true });
    this.setData();
  };

  setDefault = () => {
    this.setState({ loading: true });
    this.setData(true);
  };

  increaseTemp = () => {
    this.setState((prevState) => {
      if (prevState.comfortTemp < 25)
        return {
          comfortTemp: (parseFloat(prevState.comfortTemp, 10) + 0.5).toFixed(1),
        };
    });
  };

  decreaseTemp = () => {
    this.setState((prevState) => {
      if (prevState.comfortTemp > 12)
        return {
          comfortTemp: (parseFloat(prevState.comfortTemp, 10) - 0.5).toFixed(1),
        };
    });
  };

  render() {
    const { classes, calendar = {}, isSummer, viewOnly } = this.props;
    const { room, loading } = this.state;
    const { calendarmap, updatedAt } = calendar;
    const {
      name = "",
      type = "",
      id,
      therm_measured_temperature,
      reachable = true,
      isFreezeProtection = false,
      home_id,
    } = room;
    const isMicropelt = home_id.startsWith("micropelt");

    return (
      <>
        <Card className={classes.mytile} key={id}>
          <CardHeader
            title={name}
            subheader={type}
            classes={{
              root: classes.header, // class name, e.g. `classes-nesting-root-x`
              title: classes.headertitle,
              subheader: classes.subheadertitle,
            }}
          />

          {therm_measured_temperature && (
            <CardContent className={classes.content}>
              <div className={classes.griditem}>
                <Grid container direction="row" justify="space-between" alignItems="flex-start">
                  <Grid item>
                    <Typography variant="h3" className={classes.celsius}>
                      {therm_measured_temperature}°
                    </Typography>
                  </Grid>
                  {!isSummer && (
                    <Grid item xs>
                      <div className={classes.heatdeatils}>
                        {room.heating_power_request > 0 ? (
                          <Circle className={classes.heating_on_icon} style={{ fontSize: 50 }} />
                        ) : (
                          <CircleOut className={classes.heating_off_icon} style={{ fontSize: 50 }} />
                        )}

                        <Typography
                          className={room.heating_power_request > 0 ? classes.heating_on : classes.heating_off}
                          variant="body1"
                        >
                          {room.heating_power_request > 0
                            ? "heating on (~" + room.heating_power_request + "%)"
                            : "heating off"}
                        </Typography>
                        {room.heating_power_request > 0 &&
                          room.is_heating_on === true &&
                          room.heating_power_request < 100 && (
                            <Tooltip
                              title={`The room is heating up, the valve${
                                room.modules && room.modules.length > 1 ? "s are" : " is"
                              } opening`}
                            >
                              <ArrowRightAltIcon className={classes.upArrow} />
                            </Tooltip>
                          )}
                        {room.heating_power_request > 0 &&
                          room.is_heating_on === false &&
                          room.heating_power_request < 100 && (
                            <Tooltip
                              title={`The room is cooling down, the valve${
                                room.modules && room.modules.length > 1 ? "s are" : " is"
                              } closing`}
                            >
                              <ArrowRightAltIcon className={classes.downArrow} />
                            </Tooltip>
                          )}
                      </div>
                    </Grid>
                  )}
                </Grid>

                <Typography className={classes.setpoint} variant="body1">
                  {"set to " +
                    room.therm_setpoint_temperature +
                    "° " +
                    (isFreezeProtection ? " -  ❄️ Freeze Protection" : "")}
                </Typography>
                {isSummer && (
                  <>
                    <Typography className={classes.setpoint} variant="body1">
                      Summer mode. No heating in the building
                    </Typography>
                  </>
                )}
              </div>
            </CardContent>
          )}

          {!isSummer && !reachable && (
            <Chip label="Not reachable!" color="secondary" className={classes.chip} icon={<Error />} size="small" />
          )}

          <CardActions>
            <div className={classes.actions} style={{ flexDirection: "column" }}>
              {calendarmap &&
                calendarmap.length > 0 &&
                calendarmap.slice(0, 3).map((cal, i) => (
                  <div style={{ marginBottom: "10px" }}>
                    <Chip
                      variant="outlined"
                      className={classes.calchip}
                      size="small"
                      icon={<EventIcon />}
                      label={
                        (moment(cal.start).format("YYYYMMDD") !== moment(cal.end).format("YYYYMMDD") &&
                        moment(cal.end).format("HH:mm") !== "00:00" &&
                        !moment().isSame(moment(cal.start), "day")
                          ? moment(cal.start).format("MM/DD") + " - " + moment(cal.end).format("MM/DD")
                          : !moment().isSame(moment(cal.start), "day")
                          ? moment(cal.start).format("MM/DD/YYYY HH:mm") + " - " + moment(cal.end).format("HH:mm")
                          : moment(cal.start).format("HH:mm") + " - " + moment(cal.end).format("HH:mm")) +
                        (cal.text ? " " + cal.text : "")
                      }
                      key={i}
                    />
                  </div>
                ))}
              {updatedAt && !calendarmap && (
                <Chip
                  variant="outlined"
                  className={classes.calchip}
                  size="small"
                  icon={<EventIcon />}
                  label={"No event"}
                />
              )}
            </div>
          </CardActions>
          <CardActions>
            <div className={classes.actions}>
              {therm_measured_temperature && (
                <Button className={classes.setTerm} size="small" onClick={this.handleClickOpen}>
                  Manage
                </Button>
              )}
            </div>
          </CardActions>
        </Card>

        <Dialog
          open={this.state.dialogOpen}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{room.name} Setpoint Temperature</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Button
              onClick={this.decreaseTemp}
              disabled={room.isFreezeProtection || isSummer ? true : room.open_window}
            >
              <Icon
                color={!room.isFreezeProtection && !isSummer ? "secondary" : ""}
                style={{
                  fontSize: 36,
                  color:
                    (room.isFreezeProtection !== undefined && room.isFreezeProtection !== false) ||
                    isSummer ||
                    (room.open_window && "gray"),
                }}
              >
                remove_circle
              </Icon>
            </Button>
            <Typography variant="h2" component="h2">
              {this.state.comfortTemp}
            </Typography>

            <Button
              onClick={this.increaseTemp}
              disabled={room.isFreezeProtection || isSummer ? true : room.open_window}
            >
              <Icon
                color={!room.isFreezeProtection && !isSummer ? "secondary" : ""}
                style={{
                  fontSize: 36,
                  color:
                    (room.isFreezeProtection !== undefined && room.isFreezeProtection !== false) ||
                    isSummer ||
                    (room.open_window && "gray"),
                }}
              >
                add_circle
              </Icon>
            </Button>
          </DialogContent>
          {!isMicropelt && (
            <DialogContent>
              <Typography variant="body1" component="p">
                Set until:
              </Typography>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <TimePicker
                  ampm={false}
                  value={this.state.setpointTime}
                  onChange={(time) => this.handleChange(time)}
                  className={classes.time}
                />
              </MuiPickersUtilsProvider>
            </DialogContent>
          )}

          <div>{loading && <LinearProgress className={classes.progress} />}</div>
          <DialogActions>
            {!isMicropelt && (
              <Button onClick={this.setDefault} color="primary" disabled={loading || viewOnly}>
                Set back to Schedule temp.
              </Button>
            )}
            <Button onClick={this.handleClose} disabled={loading}>
              Close
            </Button>

            <Button
              onClick={this.setTemp}
              variant="contained"
              color="secondary"
              disabled={loading || (room.isFreezeProtection || isSummer ? true : room.open_window) || viewOnly}
            >
              {!isMicropelt ? "Set Temperature" : "Set new Comfort Temperature"}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

Room.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Room);
