// import moment from "moment";

export const FIRE = 5;
export const OPEN_WINDOW = 6;
export const NO_HEATING = 7;
export const NO_HEATING_CONTROL = 9;
export const STUCK_PIN = 10;
export const OFFLINE_TRV = 11;
export const OFFLINE_GW = 12;
export const MECHANICAL_ERROR = 13;
export const CELLULAR_GW = 14;
export const EXTRA_HEAT_SOURCE = 15;
export const REFERENCE_RUN = 16;
export const OFFLINE_GW_MOSQUITTO = 17;
export const OFFLINE_GW_GATEWAY = 18;
export const DEVICE_PINGED = 19;
export const MANUAL_REFERENCE_RUN = 20;
export const AWAY_LOW_TEMP = 21;
export const SET_CUSTOM_SCHEDULE = 22;
export const SEND_REBOOT_SMS = 23;
export const INCOMING_GW_SMS = 24;
export const ONLINE_GW = 25;
export const SUMMER_MODE_AUTOMATIC_OFF = 26;
export const OPEN_WINDOW_OPENED = 27;
export const OPEN_WINDOW_CLOSED = 28;
export const SET_CALENDAR_SCHEDULE = 29;
export const REFERENCE_RUN_DETECTED = 31;
export const USER_ACTION_SET_TEMP = 100;
export const USER_ACTION_SET_FREEZE_ON = 101;
export const USER_ACTION_SET_FREEZE_OFF = 102;
export const USER_ACTION_ADMIN_SET_TEMP = 103;
export const USER_ACTION_ADMIN_SET_CUSTOM_TEMP = 104;
export const USER_ACTION_ADMIN_SET_CUSTOM_TEMP_OFF = 105;
export const USER_ACTION_ADMIN_SET_TEMP_BULK = 106;
export const USER_ACTION_ADMIN_SET_FREEZE_ON = 107;
export const USER_ACTION_ADMIN_SET_FREEZE_ON_BULK = 108;
export const USER_ACTION_ADMIN_SET_FREEZE_OFF = 109;
export const USER_ACTION_ADMIN_SET_FREEZE_OFF_BULK = 110;
export const USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_ON = 111;
export const USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_ON_BULK = 112;
export const USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_OFF = 113;
export const USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_OFF_BULK = 114;
export const USER_ACTION_SLACK_SET_TEMP = 115;
export const USER_ACTION_BOOST = 116;

export const SETTER_USER_DEFAULT = "EcoSync System";
export const SETTER_USER_QR = "Anonymous User";
export const DEFAULT_TEST_HOME_ID = "micropelt_demo_budapest";

const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const getTemps = (event) => {
  let from, to, temps;
  if (typeof event.text === "object") {
    temps = event.text;
  } else {
    try {
      temps = JSON.parse(event.text);
    } catch (e) {}
  }
  if (typeof temps === "object") {
    if (typeof temps[0] === "object") {
      from = temps[0][0].temp + "/" + temps[0][1].temp;
      to = temps[1][0].temp + "/" + temps[1][1].temp;
    } else {
      from = temps[0];
      to = temps[1];
    }
  }
  return [from, to];
};

/*const getFieldValue = async (event, field, defaultValue, notificationTexts) => {
  if ((typeof (notificationTexts[event]) === "undefined") || (typeof (notificationTexts[event][field]) === "undefined")) {
    console.log("new updateNoti")
    console.log([event, field, defaultValue]);
    return defaultValue;
  } else {
    return notificationTexts[event][field];
  }
}*/

export const eventText = (event, notificationTexts) => {
  let to,
    from,
    device,
    ret = "";

  try {
    ret = notificationTexts[event.type]["eventText"];
    switch (event.type) {
      case OFFLINE_TRV:
        device = event.id;
        break;
      case MECHANICAL_ERROR:
        device = event.id;
        break;
      case REFERENCE_RUN:
        device = event.text;
        break;
      case MANUAL_REFERENCE_RUN:
        device = event.text;
        break;
      case USER_ACTION_SET_TEMP:
        [from, to] = getTemps(event);
        break;
      case SET_CUSTOM_SCHEDULE:
        to = event.text;
        break;
      case USER_ACTION_ADMIN_SET_TEMP:
        [from, to] = getTemps(event);
        break;
      case USER_ACTION_ADMIN_SET_TEMP_BULK:
        [from, to] = getTemps(event);
        break;
      case USER_ACTION_SLACK_SET_TEMP:
        [from, to] = getTemps(event);
        break;
    }
  } catch (e) {
    console.error("missing eventText:");
    console.error(event);
  }
  ret = ret.replace("{device}", device).replace("{from}", from).replace("{to}", to);
  if (
    typeof event.text !== "undefined" &&
    event.text &&
    event.text.length &&
    typeof event.text === "string" &&
    !isJson(event.text)
  ) {
    ret += " (" + event.text + ")";
  }
  return ret;
};
export const eventTitle = (event, notificationTexts) => {
  if (
    typeof notificationTexts !== "undefined" &&
    typeof notificationTexts[event] !== "undefined" &&
    typeof notificationTexts[event]["eventTitle"] !== "undefined"
  ) {
    return notificationTexts[event];
  } else {
    let defaultValue = "";
    switch (event.type) {
      case FIRE:
        defaultValue = "Fire detection";
        break;
      case OPEN_WINDOW:
        defaultValue = "Open window detection";
        break;
      case NO_HEATING:
        defaultValue = "No heating detection";
        break;
      case NO_HEATING_CONTROL:
        defaultValue = "No heating control detection";
        break;
      case STUCK_PIN:
        defaultValue = "Stuck pin detection";
        break;
      case OFFLINE_TRV:
        defaultValue = "Offline TRV detection";
        break;
      case OFFLINE_GW:
        defaultValue = "Offline GW detection";
        break;
      case MECHANICAL_ERROR:
        defaultValue = "Mechanical error";
        break;
      case CELLULAR_GW:
        defaultValue = "Cellular GW";
        break;
      case EXTRA_HEAT_SOURCE:
        defaultValue = "Extra heat source";
        break;
      case REFERENCE_RUN:
        defaultValue = "Reference run";
        break;
      case MANUAL_REFERENCE_RUN:
        defaultValue = "Manual reference run by dev";
        break;
      case AWAY_LOW_TEMP:
        defaultValue = "Too low temperature during away setting (no heating)";
        break;
      case SET_CUSTOM_SCHEDULE:
        defaultValue = "Set automatic schedule";
        break;
      case USER_ACTION_SET_TEMP:
        defaultValue = "Room temp update";
        break;
      case USER_ACTION_SET_FREEZE_ON:
        defaultValue = "Freeze protection / away set ON";
        break;
      case USER_ACTION_SET_FREEZE_OFF:
        defaultValue = "Freeze protection / away set OFF";
        break;
      case USER_ACTION_ADMIN_SET_TEMP:
        defaultValue = "ADMIN: Temperature updated";
        break;
      case USER_ACTION_ADMIN_SET_CUSTOM_TEMP:
        defaultValue = "ADMIN: Custom temperature updated";
        break;
      case USER_ACTION_ADMIN_SET_CUSTOM_TEMP_OFF:
        defaultValue = "ADMIN: Custom temperature off";
        break;
      case USER_ACTION_ADMIN_SET_TEMP_BULK:
        defaultValue = "ADMIN: Temperature updated BULK";
        break;
      case USER_ACTION_ADMIN_SET_FREEZE_ON:
        defaultValue = "ADMIN: Freeze protection / away set ON";
        break;
      case USER_ACTION_ADMIN_SET_FREEZE_ON_BULK:
        defaultValue = "ADMIN: Freeze protection / away set ON BULK";
        break;
      case USER_ACTION_ADMIN_SET_FREEZE_OFF:
        defaultValue = "ADMIN: Freeze protection / away set OFF";
        break;
      case USER_ACTION_ADMIN_SET_FREEZE_OFF_BULK:
        defaultValue = "ADMIN: Freeze protection / away set OFF BULK";
        break;
      case USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_ON:
        defaultValue = "ADMIN: Open window detection ON";
        break;
      case USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_ON_BULK:
        defaultValue = "ADMIN: Open window detection ON BULK";
        break;
      case USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_OFF:
        defaultValue = "ADMIN: Open window detection OFF";
        break;
      case USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_OFF_BULK:
        defaultValue = "ADMIN: Open window detection OFF BULK";
        break;
      case USER_ACTION_SLACK_SET_TEMP:
        defaultValue = "Temperature updated on slack";
        break;
      case USER_ACTION_BOOST:
        defaultValue = "Temp boosted";
        break;
    }
    console.log("updateNotification " + event.type + "eventTitle");
    return defaultValue;
  }
};
