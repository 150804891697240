import {
  FETCH_SIGHTS,
  SELECT_SIGHT,
  SELECT_CLIENT,
  FETCH_NOTIFICATIONS,
  FETCH_MANUALS,
  FETCH_SETTINGS,
  FETCH_QR_CSV_DATA,
  FETCH_CLIENTS,
  GATEWAY_DISABLED_CHANGE,
  GATEWAY_RESTART_CHANGE,
} from "../actions/types";
import { eventText, eventTitle } from "../constants/events";

const {
  FIRE,
  OPEN_WINDOW,
  NO_HEATING,
  NO_HEATING_CONTROL,
  STUCK_PIN,
  OFFLINE_TRV,
  OFFLINE_GW,
  OFFLINE_GW_MOSQUITTO,
  OFFLINE_GW_GATEWAY,
  DEVICE_PINGED,
  MECHANICAL_ERROR,
  CELLULAR_GW,
  EXTRA_HEAT_SOURCE,
  REFERENCE_RUN,
  MANUAL_REFERENCE_RUN,
  AWAY_LOW_TEMP,
  SET_CUSTOM_SCHEDULE,
  USER_ACTION_SET_TEMP,
  USER_ACTION_SET_FREEZE_ON,
  USER_ACTION_SET_FREEZE_OFF,
  USER_ACTION_ADMIN_SET_TEMP,
  USER_ACTION_ADMIN_SET_CUSTOM_TEMP,
  USER_ACTION_ADMIN_SET_CUSTOM_TEMP_OFF,
  USER_ACTION_ADMIN_SET_TEMP_BULK,
  USER_ACTION_ADMIN_SET_FREEZE_ON,
  USER_ACTION_ADMIN_SET_FREEZE_ON_BULK,
  USER_ACTION_ADMIN_SET_FREEZE_OFF,
  USER_ACTION_ADMIN_SET_FREEZE_OFF_BULK,
  USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_ON,
  USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_ON_BULK,
  USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_OFF,
  USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_OFF_BULK,
  USER_ACTION_SLACK_SET_TEMP,
  USER_ACTION_BOOST,
} = require("../constants/events");

const initialState = {
  notificationsCount: 0,
  visibleNotifications: [],
  visibleEventsInHistory: [],
  notificationTexts: [],
  notifications: null,
  notifications_ack: null,
  notificationsEnabled: false,
  selectedHomes: null,
  selectedSite: null,
  selectedClient: null,
  manuals: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SIGHTS:
      let arr = [];
      Object.values(action.payload).forEach((item) => {
        if (
          !item.disabled &&
          ((typeof item.admin_users !== "undefined" &&
            Object.keys(item.admin_users).includes(action.uid) &&
            !action.superAdmin) ||
            (action.clientAdmin &&
              ((typeof action.uc === "string" && action.uc === item.client) ||
                (Array.isArray(action.uc) && action.uc.includes(item.client)))) ||
            (action.superAdmin && action.selectedClient === item.client))
        ) {
          arr.push(item);
        }
        if (!item.sort && typeof item.name != "undefined") item.sort = item.name.toLowerCase();
      });
      arr.sort((a, b) => (a.sort > b.sort ? 1 : b.sort > a.sort ? -1 : 0));
      return { ...state, sights: arr, sightsObject: action.payload } || {};
    case SELECT_CLIENT:
      return {
        ...state,
        selectedClient: action.payload,
      };
    case SELECT_SIGHT:
      let selectedHomes = Object.keys(state.sightsObject[action.payload].home_ids);

      return {
        ...state,
        selectedSite: action.payload,
        selectedHomes: selectedHomes,
        isBarsOnMobile: state.sightsObject[action.payload].settings.isBarsOnMobile,
        isFreezeAvailable: state.sightsObject[action.payload].settings.isFreezeAvailable,
        summerModeActive: state.sightsObject[action.payload].summerModeActive,
        notifications: null,
        notificationsCount: 0,
        notifications_ack: null,
        notificationsEnabled: false,
        rooms: action.rooms,
        floorplanURL: action.floorplanURL,
        deviceData: action.deviceData,
        device_positions: action.device_positions,
        gateway_positions: action.gateways_position,
        notify_emails: state.sightsObject[action.payload].notify_emails || [],
        notifications_settings: state.sightsObject[action.payload].notifications_settings || {},
        default_settings: [],
        manuals: {},
        selectedSightFloors: action.selectedSightFloors,
        bookedHistory: action.bookedHistory,
        timezone: action.timezone,
        partner_logo: action.partner_logo,
      };
    case FETCH_NOTIFICATIONS:
      if (action.payload) {
        let num = 0;
        if (action.payload.notifications) {
          num = Object.values(action.payload.notifications).length;
        }

        let notifications = action.payload.notifications;

        if (state.selectedSite === 15) {
          // console.log(state.selectedSite);
          notifications = {};
          Object.keys(action.payload.notifications).forEach((key) => {
            if (action.payload.notifications[key].type !== 9) {
              notifications[key] = action.payload.notifications[key];
            }
          });
        }
        let defaultSettings = [];
        action.visibleNotifications.forEach((noti) => {
          defaultSettings[eval(noti)] = {
            frequency: "none",
            heatingOffFrequency: "none",
            info: action.notificationTexts[eval(noti)]["notiInfo"],
            name: action.notificationTexts[eval(noti)]["notiTitle"],
            type: eval(noti),
            typeName: noti,
          };
        });
        if (!action.payload.notifications_enabled) {
          num = "X";
        }

        return {
          ...state,
          notifications: notifications,
          notificationsCount: num,
          default_settings: defaultSettings,
          visibleNotifications: action.visibleNotifications,
          visibleEventsInHistory: action.visibleEventsInHistory,
          notificationTexts: action.notificationTexts,
          isBarsOnMobile: action.payload.isBarsOnMobile,
          isFreezeAvailable: action.payload.isFreezeAvailable,
          notifications_ack: action.notifications_ack,
          notificationsEnabled: action.payload.notifications_enabled,
          notifications_settings: action.payload.notifications_settings || {},
        };
      } else {
        return {
          ...state,
          notifications: null,
          notificationsCount: 0,
          notifications_ack: null,
          notificationsEnabled: false,
        };
      }
    case FETCH_CLIENTS:
      return { ...state, clients: action.payload };

    case FETCH_MANUALS:
      return { ...state, manuals: action.payload };
    case FETCH_SETTINGS:
      return { ...state, settings: action.payload };
    case FETCH_QR_CSV_DATA:
      return {
        ...state,
        QRCsvData: action.payload[0],
        QRCsvFixData: action.payload[1],
        QRAllCsvData: action.payload[2],
        QRAllCsvFixData: action.payload[3],
      };
    case GATEWAY_DISABLED_CHANGE:
      return {
        ...state,
        deviceData: {
          ...state.deviceData,
          [action.payload.gw_id]: { ...state.deviceData[action.payload.gw_id], disabled: action.payload.status },
        },
      };
    case GATEWAY_RESTART_CHANGE:
      return {
        ...state,
        deviceData: {
          ...state.deviceData,
          [action.payload.gw_id]: {
            ...state.deviceData[action.payload.gw_id],
            sim_restart_enabled: action.payload.status,
          },
        },
      };
    default:
      return state;
  }
};
