export const HOME = "/";
export const ROOMS = "/rooms";
export const ROOMS_ID = "/rooms/:id";
export const DEVICES = "/devices";
export const DEVICES_ID = "/devices/:id";
export const MANUALS = "/manuals";
export const SETTINGS = "/settings";
export const HISTORY = "/history";
export const CALCULATOR = "/calculator";
export const LOGIN = "/login";
export const NOTIFICATIONS = "/notifications";
export const CARBON = "/carbon_meter";
