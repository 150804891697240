import { combineReducers } from "redux";
import pageData from "./pageReducer";
import authData from "./authReducer";
import sightsData from "./sightsReducer";
import userData from "./userReducer";
import roomsData from "./roomsReducer";
import devicesData from "./devicesReducer";
import schedulesData from "./schedulesReducer";

export default combineReducers({
  pageData,
  authData,
  userData,
  sightsData,
  roomsData,
  devicesData,
  schedulesData,
});
