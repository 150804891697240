import React from "react";
import Typography from "@material-ui/core/Typography";
import {
  FIRE,
  OPEN_WINDOW,
  NO_HEATING,
  NO_HEATING_CONTROL,
  STUCK_PIN,
  OFFLINE_TRV,
  OFFLINE_GW,
  OFFLINE_GW_GATEWAY,
  OFFLINE_GW_MOSQUITTO,
  MECHANICAL_ERROR,
  CELLULAR_GW,
  EXTRA_HEAT_SOURCE,
  AWAY_LOW_TEMP,
} from "../../constants/events";

export const resolutionType = (type, notificationTexts) => {
  if (
    typeof notificationTexts !== "undefined" &&
    typeof notificationTexts[type] !== "undefined" &&
    typeof notificationTexts[type]["resolution"] !== "undefined"
  ) {
    let notificationInfos = [];
    for (let i = 0; i < notificationTexts[type]["resolution"].length; i++) {
      notificationInfos.push(
        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: notificationTexts[type]["resolution"][i] }} />
      );
    }
    return <div>{notificationInfos}</div>;
  } else {
    if (type === FIRE) {
      return (
        <div>
          <Typography variant="body1">
            POTENTIAL FIRE Check the reason of the sudden temperature increasement as soon as possible! Please note that
            a valve sitting loose on the adaptor ring can open the pin and cause high temperature. Make sure the valve
            is sitting straight and tight. Avoid cross-threading! (If the adaptor ring is damaged due to
            cross-threading, ask for a replacement one – contact@ecosync.energy)
          </Typography>
        </div>
      );
    } else if (type === OPEN_WINDOW) {
      return (
        <div>
          <Typography variant="body1">Make sure the window is closed.</Typography>
        </div>
      );
    } else if (type === NO_HEATING) {
      return (
        <div>
          <Typography variant="body1">
            1. The radiator pin is stuck or the outlet valve on the same radiator is closed (solution: check the
            movement of the pin with a wrench and make sure that the outlet valve is in open position)
          </Typography>
          <Typography variant="body1">
            2. In case of “no heating” notification arriving from several rooms simultaneously, it can indicate that the
            building itself has no heating, indication a larger issue with the boiler/pump of the heating system. In
            case of summer shutdown, you can ignore the notification – or contact EcoSync to deactivate the
            notifications until it is time to turn the heating back on again.
          </Typography>
          <br />
        </div>
      );
    } else if (type === NO_HEATING) {
      return (
        <div>
          <Typography variant="body1">
            1. The radiator pin is stuck or the outlet valve on the same radiator is closed (solution: check the
            movement of the pin with a wrench and make sure that the outlet valve is in open position)
          </Typography>
          <Typography variant="body1">
            2. In case of “no heating” notification arriving from several rooms simultaneously, it can indicate that the
            building itself has no heating, indication a larger issue with the boiler/pump of the heating system. In
            case of summer shutdown, you can ignore the notification – or contact EcoSync to deactivate the
            notifications until it is time to turn the heating back on again.
          </Typography>
          <br />
        </div>
      );
    } else if (type === NO_HEATING_CONTROL) {
      return (
        <div>
          <Typography variant="body1">
            Valve might have been removed (Radiator valve is fully open), place (or replace) the valve
          </Typography>
          <Typography variant="body1">
            Check that there is a sticker with notification on the radiator: “Tampering with the radiator valve will
            cause malfunction”
          </Typography>
          <Typography variant="body1">Replace any missing stickers (add more visible ones if necessary)</Typography>
          <Typography variant="body1">
            ( Check weather - It might be summer causing higher temperature in the rooms ;)
          </Typography>
          <br />
        </div>
      );
    } else if (type === STUCK_PIN) {
      return (
        <div>
          <Typography variant="body1">Pin might have been stuck (Valve is closed, but the heating is on)</Typography>
          <Typography variant="body1">
            The radiator pin is stuck or the outlet valve on the same radiator is closed (solution: check the movement
            of the pin with a wrench and make sure that the outlet valve is in open position) {"\n"}
            <a target="_blank" rel="noopener noreferrer" href="https://room.mt/manual_vainstall">
              Please see manual.
            </a>
          </Typography>
          <br />
        </div>
      );
    } else if (type === OFFLINE_TRV) {
      return (
        <div>
          <Typography variant="body1">TRV is offline</Typography>
          <Typography variant="body1">
            The device is offline. Please hold the magnetic tool on the top of the device for 5 seconds to restart.
            {"\n"}
            <a target="_blank" rel="noopener noreferrer" href="https://room.mt/manual_vainstall">
              Please see manual.
            </a>
          </Typography>
          <br />
        </div>
      );
    } else if (type === OFFLINE_GW) {
      return (
        <div>
          <Typography variant="body1">Gateway is offline</Typography>
          <Typography variant="body1">
            Please check the gateway's internet connection
            {"\n"}
            <a target="_blank" rel="noopener noreferrer" href="https://room.mt/manual_rider">
              Please see manual.
            </a>
          </Typography>
          <br />
        </div>
      );
    } else if (type === OFFLINE_GW_MOSQUITTO) {
      return (
        <div>
          <Typography variant="body1">Gateway is offline</Typography>
          <Typography variant="body1">
            Please remove gateway adapter from socket then plug it back in.
            {"\n"}
            <a target="_blank" rel="noopener noreferrer" href="https://room.mt/manual_rider">
              Please see manual.
            </a>
          </Typography>
          <br />
        </div>
      );
    } else if (type === OFFLINE_GW_GATEWAY) {
      return (
        <div>
          <Typography variant="body1">Gateway is offline</Typography>
          <Typography variant="body1">
            Please remove gateway adapter from socket then plug it back in.
            {"\n"}
            <a target="_blank" rel="noopener noreferrer" href="https://room.mt/manual_rider">
              Please see manual.
            </a>
          </Typography>
          <br />
        </div>
      );
    } else if (type === CELLULAR_GW) {
      return (
        <div>
          <Typography variant="body1">Gateway is on cellular network</Typography>
          <Typography variant="body1">
            Please check the gateway's internet connection. No ethernet connection might result in extra monthly data
            fees. {"\n"}
            <a target="_blank" rel="noopener noreferrer" href="https://room.mt/manual_vainstall">
              Please see manual.
            </a>
          </Typography>
          <br />
        </div>
      );
    } else if (type === MECHANICAL_ERROR) {
      return (
        <div>
          <Typography variant="body1">Mechanical error:</Typography>
          <Typography variant="body1">
            The device is not properly on the valve or that the device can not reach / push the pin, therefore it
            switched off. Each device checks it's mechanical connection at least every six weeks. Please check if it is
            on properly fitted on the radiator and restart it with the pen magnet. {"\n"}
            <a target="_blank" rel="noopener noreferrer" href="https://room.mt/manual_vainstall">
              Please see manual.
            </a>
          </Typography>
          <br />
        </div>
      );
    } else if (type === AWAY_LOW_TEMP) {
      return (
        <div>
          <Typography variant="body1">Too low temperature during away setting (no heating):</Typography>
          <Typography variant="body1">Away / Night temperature not reached. (No heating.)</Typography>
          <br />
        </div>
      );
    } else {
      return "Unkown.";
    }
  }
};
