import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
// import CardHeader from "@material-ui/core/CardHeader";
import Error from "@material-ui/icons/Error";
import { Dialog, Button, Icon, Typography } from "@material-ui/core";
// import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { TimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
// import moment from "moment";
import { functions } from "../../config/firebase";
import LinearProgress from "@material-ui/core/LinearProgress";

const styles = (theme) => ({
  celsius: {
    textAlign: "center",
    fontSize: 28,
  },
  myTile: {
    backgroundColor: "#00838fa6",
    minWidth: 80,
    minHeight: 70,
    cursor: "pointer",
  },
  largeTile: {
    minWidth: 240,
    minHeight: 140,
    backgroundColor: "#00838fa6",
    cursor: "pointer",
  },
  headertitle: {
    fontSize: 13,
    width: 100,
    color: "#eee",
  },
  largeHeaderTitle: {
    fontSize: 34,
    width: 200,
    color: "#eee",
  },
  largeSubheaderTitle: {
    fontSize: 45,
    color: "#fff",
    fontWeight: "bold",
  },
  subHeaderTitle: {
    fontSize: 21,
    color: "#fff",
    fontWeight: "bold",
  },
  headerRoot: {
    padding: 10,
  },
  icon: {},
  dialogContent: {
    display: "flex",
    justifyContent: "space-around",
    marginBottom: 15,
    marginLeft: 10,
    marginRight: 10,
  },
  freezeOn: {
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 10,
    marginRight: 10,
  },
});

class FloorRoom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      room: props.room,
      homeId: props.homeId,
      top: props.top,
      left: props.left,
      size: props.size,
      isMicropelt: props.isMicropelt,
      scale: props.scale ? props.scale : 1,
      isSummer: props.isSummer,
      open: false,
      loading: false,
      viewOnly: props.viewOnly,
      comfortTemp: parseFloat(this.props.room.zones[0].temp).toFixed(1),
    };
  }

  async setData(isDefault) {
    try {
      const setTemp = functions.httpsCallable("setComfortTemp");
      const resp = await setTemp({
        roomId: this.state.room.id,
        temp: parseFloat(this.state.comfortTemp),
        siteId: this.state.room.home_id,
      });
      this.setState({ open: false, loading: false });
    } catch (e) {
      alert(e);
      this.setState({
        loading: false,
      });
    }
  }

  handleChange = (time) => {
    this.setState({
      setpointTime: time,
    });
  };

  componentDidUpdate(prevProps, prevState, snapShot) {
    if (prevProps.room !== this.props.room) {
      this.setState({
        room: this.props.room,
        isSummer: this.props.isSummer,
      });
    }
    if (prevProps.scale !== this.props.scale) {
      this.setState({
        scale: this.props.scale,
      });
    }
  }

  render() {
    const { classes } = this.props;
    const { room, loading, isSummer } = this.state;
    const openDialog = () => {
      return this.setState({ open: true });
    };

    const closeDialog = () => {
      return this.setState({ open: false });
    };
    const increaseTemp = () => {
      this.setState((prevState) => {
        if (prevState.comfortTemp < 26) {
          return {
            comfortTemp: (parseFloat(prevState.comfortTemp) + 0.5).toFixed(1),
          };
        }
      });
    };
    const setDefault = () => {
      this.setState({ loading: true });
      this.setData(true);
    };
    const setTemp = () => {
      this.setState({ loading: true });
      this.setData();
    };
    const isMicropelt = this.state.homeId.toString().startsWith("micropelt");
    const decreaseTemp = () => {
      this.setState((prevState) => {
        if (prevState.comfortTemp > 12) {
          return {
            comfortTemp: (parseFloat(prevState.comfortTemp) - 0.5).toFixed(1),
          };
        }
      });
    };
    // console.log(room.isFreezeProtection);
    // console.log(this.state.scale);
    return (
      <>
        <div
          id={"roomcard_" + room.id}
          style={{
            position: "absolute",
            top: this.state.size === "large" ? this.state.top - 140 : this.state.top,
            left: this.state.size === "large" ? this.state.left - 100 : this.state.left,
          }}
        >
          <Card
            style={
              this.state.size === "large"
                ? {
                    minWidth: 240,
                    minHeight: 140,
                    backgroundColor: "#00838fa6",
                    cursor: "pointer",
                  }
                : {
                    backgroundColor: "#00838fa6",
                    minWidth: 40,
                    minHeight: 35,
                    width: 90 / this.state.scale,
                    height: 80 / this.state.scale,
                    cursor: "pointer",
                    borderRadius: "0%",
                  }
            }
            key={room.id}
            onClick={openDialog}
          >
            <div style={{ marginLeft: 10 / this.state.scale, marginTop: 10 / this.state.scale }}>
              <Typography
                style={
                  this.props.size === "large"
                    ? {
                        fontSize: 34,
                        width: 200,
                        color: "#eee",
                      }
                    : {
                        fontSize: this.state.scale < 2.2 ? 11 / this.state.scale : 5,
                        width: 80 / this.state.scale,
                        minWidth: 38,
                        color: "#eee",
                      }
                }
              >
                {room.name}
              </Typography>
              {room.therm_measured_temperature ? (
                <Typography
                  style={
                    this.props.size === "large"
                      ? {
                          fontSize: 45,
                          color: "#fff",
                          fontWeight: "bold",
                        }
                      : {
                          fontSize: this.state.scale < 2.2 ? 15 / this.state.scale : 7,
                          color: "#fff",
                          fontWeight: "bold",
                          minWidth: 38,
                        }
                  }
                >
                  {room.therm_measured_temperature}°C
                </Typography>
              ) : (
                <Error
                  style={{
                    fontSize: 34 / this.state.scale,
                    backgroundColor: "#fff",
                    borderRadius: 17 / this.state.scale,
                  }}
                  color="secondary"
                />
              )}
            </div>
            {/* <CardHeader
              // component={Link}
              // to={ROUTES.ROOMS}
              avatar={""}
              subheader={
                room.therm_measured_temperature ? (
                  room.therm_measured_temperature + "°C"
                ) : (
                  <Error className={classes.icon} color="secondary" />
                )
              }
              title={room.name}
              classes={{
                title: this.props.size === "large" ? {
                  fontSize: 34,
                  width: 200,
                  color: "#eee",
                } : {
                  fontSize: 13 / this.state.scale,
                  width: 100 / this.state.scale,
                  color: "#eee",
                },
                subheader: this.props.size === "large" ? {
                  fontSize: 45,
                  color: "#fff",
                  fontWeight: "bold",
                } : {
                  fontSize: 21 / this.state.scale,
                  color: "#fff",
                  fontWeight: "bold",
                },
                root: classes.headerRoot,
              }} */}
          </Card>
        </div>
        <Dialog open={this.state.open}>
          <DialogTitle id="alert-dialog-title">{room.name} - Comfort temperature</DialogTitle>
          {room.isFreezeProtection === true && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="subtitle1" className={classes.freezeOn}>
                When freeze protection is on you are not allowed to set the temperature
              </Typography>
              <Typography variant="subtitle1" className={classes.freezeOn}>
                Please go to settings to deactivate
              </Typography>
            </div>
          )}
          {isSummer === true && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="subtitle1" className={classes.freezeOn}>
                When Summer mode is enabled you are not allowed to set the temperature
              </Typography>
              <Typography variant="subtitle1" className={classes.freezeOn}>
                Please go to notifications settings to deactivate
              </Typography>
            </div>
          )}
          <DialogContent className={classes.dialogContent}>
            <Button onClick={decreaseTemp} disabled={room.isFreezeProtection || isSummer ? true : room.open_window}>
              <Icon
                color={!room.isFreezeProtection && !isSummer ? "secondary" : ""}
                style={{
                  fontSize: 36,
                  color:
                    (room.isFreezeProtection !== undefined && room.isFreezeProtection !== false) ||
                    isSummer ||
                    (room.open_window && "gray"),
                }}
              >
                remove_circle
              </Icon>
            </Button>
            <Typography variant="h2" component="h2">
              {this.state.comfortTemp}
            </Typography>

            <Button onClick={increaseTemp} disabled={room.isFreezeProtection || isSummer ? true : room.open_window}>
              <Icon
                color={!room.isFreezeProtection && !isSummer ? "secondary" : ""}
                style={{
                  fontSize: 36,
                  color:
                    (room.isFreezeProtection !== undefined && room.isFreezeProtection !== false) ||
                    isSummer ||
                    (room.open_window && "gray"),
                }}
              >
                add_circle
              </Icon>
            </Button>
          </DialogContent>

          {!isMicropelt && (
            <DialogContent>
              <Typography variant="body1" component="p">
                Set until:
              </Typography>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <TimePicker
                  ampm={false}
                  value={this.state.setpointTime}
                  onChange={(time) => this.handleChange(time)}
                  className={classes.time}
                />
              </MuiPickersUtilsProvider>
            </DialogContent>
          )}
          <div>{loading && <LinearProgress className={classes.progress} />}</div>
          <div className={classes.dialogContent}>
            {!isMicropelt && (
              <Button onClick={setDefault} color="primary" disabled={loading}>
                Set back to Schedule temp.
              </Button>
            )}
            <Button onClick={closeDialog} disabled={loading}>
              Close
            </Button>

            <Button
              onClick={setTemp}
              variant="contained"
              color="secondary"
              disabled={
                this.state.viewOnly || loading || (room.isFreezeProtection || isSummer ? true : room.open_window)
              }
            >
              {!isMicropelt ? "Set Temperature" : "Set new Comfort Temperature"}
            </Button>
          </div>
        </Dialog>
      </>
    );
  }
}

FloorRoom.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FloorRoom);
