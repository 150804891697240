import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import InfoRounded from "@material-ui/icons/InfoRounded";
import { Tabs, CircularProgress, Tab, Typography, Popover } from "@material-ui/core";
import FloorRoom from "./FloorRoom";
import TabPanel from "../TabPanel/TabPanel";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Device from "./Device";
import Gateway from "./Gateway";
const styles = (theme) => ({
  root: {
    paddingTop: 4,
    minHeight: 500,
    display: "flex",
    flexDirection: "row",
  },
  base: {
    marginTop: -theme.spacing(3),
    marginLeft: -theme.spacing(3),
    backgroundColor: "#ffffff",
  },
  hold: {
    width: 1150,
  },
  panel: {
    marginLeft: 0,
  },
  wrapperClassTabs: {
    border: "1px solid black",
    width: "90vw",
    height: "79vh",
    [theme.breakpoints.only("lg")]: {
      height: "73vh",
    },
    [theme.breakpoints.down("xs")]: {
      width: "84vw",
      height: "65vh",
    },
    backgroundColor: "white",
  },
  infoButton: {
    zIndex: 1,
    left: 95,
    marginTop: 5,
    [theme.breakpoints.down("xs")]: {
      right: 50,
    },
    display: "flex",
    alignItems: "center",
  },
  infoTitle: {
    marginTop: 5,
    marginBottom: 5,
    fontSize: 15,
    marginLeft: 10,
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      fontSize: 13,
    },
  },
  info: {
    fontSize: 14,
    marginLeft: 10,
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
    },
  },
  info2: {
    fontSize: 14,
    marginLeft: 5,
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
    },
  },
  popover: {
    width: 450,
    height: 130,
    [theme.breakpoints.down("xs")]: {
      width: 310,
      height: 100,
    },
  },
  infoLine: {
    display: "flex",
  },
});

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  didMount: () =>
    dispatch({
      type: "PAGE_CHANGED",
      payload: "Dashboard",
    }),
});

class Home extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      value: 0,
      anchorEl: null,
      scale: 1,
    };
  }

  componentDidMount() {
    this.props.didMount();
    this.props.history.push("/");
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.sightsData.floorplanURL !== prevProps.sightsData.floorplanURL) {
      this.setState({
        loading: true,
      });
    }
  }

  render() {
    const { classes, sightsData, roomsData, userData } = this.props;
    const floors = sightsData.selectedSightFloors;
    const selectedSightFloors = sightsData.selectedSightFloors;
    const selectedId = sightsData.selectedSite;
    const sights = sightsData.sightsObject;
    const url = sightsData.floorplanURL;
    const homeId = sightsData.selectedSite;
    const isSummer = sightsData.summerModeActive;
    // const roomsDataExtended = sightsData.rooms;
    const scale = this.state.scale;
    const isMicropelt = () => {
      if (selectedId && sights) return sights[selectedId].hardware === "micropelt";
    };
    const handleChange = (event, newValue) => {
      this.setState({
        value: newValue,
      });
    };
    const sortFloors = () => {
      if (isMicropelt() && typeof floors !== "undefined" && floors.floors) {
        const array = Object.keys(floors.floors).map((key, i) => floors.floors[key]);
        array.sort(function (a, b) {
          return a.sort - b.sort;
        });
        return array;
      }
    };
    const floorsToShow = sortFloors();
    const handleClick = (event) => {
      this.setState({ anchorEl: event.currentTarget });
    };

    const handleClose = () => {
      this.setState({ anchorEl: null });
    };

    const open = Boolean(this.state.anchorEl);
    const id = open ? "info-popover" : undefined;
    return (
      <div className={classes.base}>
        {sightsData && selectedSightFloors === null && <CircularProgress className="progress" />}

        {floorsToShow && isMicropelt() ? (
          <Tabs value={this.state.value} onChange={handleChange}>
            {floorsToShow.map((floor) => {
              return <Tab label={floor.name} />;
            })}
          </Tabs>
        ) : (
          url !== undefined && (
            /**
             * We do not use foorplan.jpg-s any more like this.
             * @obsolete
             * will need to remove
             */
            <div>
              <div className={classes.hold}>
                <div className={classes.root} style={!this.state.loading ? {} : { display: "none" }}>
                  <div style={{ position: "relative" }}>
                    <img
                      src={url}
                      width="100%"
                      alt="bg"
                      className={classes.img}
                      onLoad={() => this.setState({ loading: false })}
                    />
                    {
                      // roomsData.rooms
                      homeId && roomsData.rooms && (
                        <div style={{ zIndex: 20, position: "absolute", top: 0 }}>
                          {roomsData.rooms.map((room) => (
                            <FloorRoom
                              homeId={homeId}
                              key={room.id}
                              room={room}
                              left={room.position.left}
                              top={room.position.top}
                              isSummer={isSummer}
                              viewOnly={userData.viewOnly}
                            />
                          ))}
                        </div>
                      )
                    }
                    {sightsData.device_positions && (
                      <div
                        style={{
                          zIndex: 3,
                          position: "absolute",
                          top: 0,
                        }}
                      >
                        {sightsData.device_positions.map((device) => (
                          <Device key={device[0]} left={device[1].left} top={device[1].top} />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
        )}
        {floorsToShow &&
          isMicropelt() &&
          floorsToShow.map((floor, index) => {
            const getRoomsToShow = () => {
              if (isMicropelt() && floor.rooms) {
                const roomsToShow = roomsData.rooms.filter((room) => floor.rooms.includes(room.id));
                return roomsToShow;
              }
            };
            const getDeviceIds = () => {
              let deviceIds = [];
              if (roomsToShow) {
                deviceIds = roomsToShow
                  .map((room) => {
                    return room.module_ids;
                  })
                  .flat();
              }
              return deviceIds;
            };
            const roomsToShow = getRoomsToShow();
            const isSummer = sightsData.summerModeActive;
            const deviceIds = getDeviceIds();
            return (
              <TabPanel value={this.state.value} index={index} className={classes.panel}>
                <div className={classes.infoButton}>
                  <Typography>NEW FEATURE: Zoom or Scroll. Click here for more info:</Typography>
                  <InfoRounded color="white" onClick={handleClick} />
                </div>
                <Popover
                  PaperProps={{ classes: { root: classes.popover } }}
                  id={id}
                  open={open}
                  anchorEl={this.state.anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Typography className={classes.infoTitle}>Instructions:</Typography>
                  <div className={classes.infoLine}>
                    <Typography className={classes.info}>Zoom in/out on PC:</Typography>
                    <Typography className={classes.info2}>use the scroll wheel on the mouse</Typography>
                  </div>
                  <div className={classes.infoLine}>
                    <Typography className={classes.info}>Navigation left/right/up/down on PC:</Typography>
                    <Typography className={classes.info2}>mouse left click, and drag</Typography>
                  </div>
                  <div className={classes.infoLine}>
                    <Typography className={classes.info}>Zoom in/out on Mobile/Tablet:</Typography>
                    <Typography className={classes.info2}>pinch with two fingers</Typography>
                  </div>
                  <div className={classes.infoLine}>
                    <Typography className={classes.info}>Navigation left/right/up/down on Mobile/Tablet:</Typography>
                    <Typography className={classes.info2}>touch and drag</Typography>
                  </div>
                </Popover>
                <TransformWrapper
                  limitToBounds={false}
                  minScale={0.2}
                  maxScale={2.2}
                  wheel={{ step: 0.07 }}
                  initialScale={sightsData.selectedSite === "micropelt_besse_gen2" ? 0.3 : 0.7}
                  onWheel={(e) => this.setState({ scale: e.state.scale })}
                >
                  <TransformComponent wrapperClass={classes.wrapperClassTabs}>
                    <div className={classes.hold}>
                      <div className={classes.root} style={!this.state.loading ? {} : { display: "none" }}>
                        <div style={{ position: "relative" }}>
                          <img
                            src={floor.floorplan}
                            alt="bg"
                            className={classes.img}
                            onLoad={() => this.setState({ loading: false })}
                          />
                          {roomsToShow && (
                            <div
                              style={{
                                zIndex: 2,
                                position: "absolute",
                                top: 0,
                              }}
                            >
                              {homeId &&
                                roomsToShow.map((room) => (
                                  <FloorRoom
                                    homeId={homeId}
                                    size={sightsData.selectedSite === "micropelt_besse_gen2" ? "large" : "small"}
                                    isSummer={isSummer}
                                    key={room.id}
                                    room={room}
                                    left={room.position.left}
                                    top={room.position.top}
                                    scale={scale}
                                    viewOnly={userData.viewOnly}
                                  />
                                ))}
                            </div>
                          )}
                          {sightsData.device_positions && deviceIds && (
                            <div
                              style={{
                                zIndex: 3,
                                position: "absolute",
                                top: 0,
                              }}
                            >
                              {sightsData.device_positions.map((position) => {
                                if (deviceIds.includes(position[0]))
                                  return (
                                    <Device
                                      scale={scale}
                                      size={sightsData.selectedSite === "micropelt_besse_gen2" ? "large" : "small"}
                                      left={position[1].left - 10}
                                      top={position[1].top - 10}
                                      device={sightsData.deviceData[`${position[0]}`]}
                                    />
                                  );
                                else return null;
                              })}
                            </div>
                          )}
                          {sightsData.gateway_positions && (
                            <div
                              style={{
                                zIndex: 3,
                                position: "absolute",
                                top: 0,
                              }}
                            >
                              {sightsData.gateway_positions.map((position) => {
                                if (floor.id === position[1].floorPlanId)
                                  return (
                                    <Gateway
                                      scale={scale}
                                      size={sightsData.selectedSite === "micropelt_besse_gen2" ? "large" : "small"}
                                      left={position[1].left - 24}
                                      top={position[1].top - 24}
                                      device={0}
                                      isSuperAdmin={userData.isSuperAdmin}
                                      gateway={sightsData.deviceData[position[0]]}
                                    />
                                  );
                                else return null;
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </TransformComponent>
                </TransformWrapper>
              </TabPanel>
            );
          })}
      </div>
    );
  }
}
Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Home)));
