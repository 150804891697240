import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
import "firebase/functions";

const config = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DB_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_ID,
};

if (!app.apps.length) {
  app.initializeApp(config);
}

export const auth = app.auth();
export const database = app.database();
export const storage = app.storage().ref();
let funcs = app.functions();

if (process.env.NODE_ENV === "development") {
  app.functions().useFunctionsEmulator("http://localhost:5000");
}

export const functions = funcs;
