import React, { Component } from "react";
import { auth, database, functions } from "../../config/firebase";
import PropTypes from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import Switch from "@material-ui/core/Switch";
import Dialog from "@material-ui/core/Dialog";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import ReactGA from "react-ga";
import MobileStepper from "@material-ui/core/MobileStepper";
import PageVisibility from "react-page-visibility";
import LinearProgress from "@mui/material/LinearProgress";
import structuredClone from "realistic-structured-clone";
import Box from "@mui/material/Box";
import { addRoomEventFrontend } from "../../actions";
import {
  SETTER_USER_QR,
  USER_ACTION_SET_FREEZE_OFF,
  USER_ACTION_SET_FREEZE_ON,
  USER_ACTION_SET_TEMP,
  OPEN_WINDOW_CLOSED,
  OPEN_WINDOW_OPENED,
  DEFAULT_TEST_HOME_ID,
  USER_ACTION_BOOST,
} from "../../constants/events";

const styles = (theme) => ({
  switchBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  box: {
    flexDirection: "column",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  card: {
    maxWidth: 320,
    margin: "auto",
    marginTop: 10,
  },
  media: {
    height: 180,
    backgroundPosition: "top center",
  },
  content: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: 10,
    marginBottom: 10,
  },
  button: {
    margin: "auto",
    marginBottom: 0,
    marginTop: 0,
  },
  text: {
    margin: "auto",
    marginTop: 5,
    marginBottom: 10,
    fontSize: 13,
    textAlign: "center",
    paddingLeft: 10,
    paddingRight: 10,
  },
  textGreen: {
    margin: "auto",
    marginTop: 5,
    marginBottom: 10,
    fontSize: 13,
    textAlign: "center",
    paddingLeft: 10,
    paddingRight: 10,
    borderWidth: 3,
    borderColor: "green",
    borderStyle: "solid",
  },
  textRed: {
    margin: "auto",
    marginTop: 5,
    marginBottom: 10,
    fontSize: 13,
    textAlign: "center",
    paddingLeft: 10,
    paddingRight: 10,
    borderWidth: 3,
    borderColor: "red",
    borderStyle: "solid",
  },
  text2: {
    margin: "auto",
    marginTop: 10,
    textAlign: "center",
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: 15,
  },
  steps: {
    width: 290,
    flexGrow: 1,
    height: 40,
  },
  linear: {
    width: 270,
    height: 20,
  },
  video_responsive: {
    width: "calc( 100% - 20px )",
    height: "auto",
    margin: "auto",
    marginTop: 10,
    marginBottom: 10,
  },
  leaderboard_responsive: {
    width: "calc( 100% - 20px )",
    height: "100vh",
    margin: "auto",
    marginTop: 10,
    marginBottom: 10,
  },
  warning: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "center",
  },
  warningDiv: {
    display: "flex",
    alignItems: "center",
  },
  message: {
    margin: "15px",
  },
  dialogDiv: {
    display: "flex",
    flexDirection: "column",
    height: "30%",
  },
});

class MobileRoom extends Component {
  constructor(props) {
    super(props);

    this.state = {
      progress: 0,
      showMessage: false,
      roomId: "",
      loading: true,
      roomName: "",
      roomTemp: null,
      zones: null,
      open: false,
      isChanged: false,
      barChart: false,
      room: null,
      scheduleIDs: [],
      schedules: null,
      boosted: null,
      boostClicked: false,
      scheduleEvent: null,
      hasCalendarIntegration: false,
      open_window: false,
      gotZones: false,
      activeStep: 0,
      isVisible: true,
      expired: false,
      sightName: "",
      client: "",
      contactEmail: "",
      maintenanceMessage: "",
      homeID: "",
      sightInfo: "",
      originalTemp: 0,
      youtube: "",
      leaderboard: "",
      leaderboardOff: false,
      isSummer: false,
      isFreezeProtection: false,
      easterEgg: true,
      timezone: "Europe/London",
    };
  }

  login = () => {
    auth.signInAnonymously().catch(function (error) {
      //var errorCode = error.code;
      //var errorMessage = error.message;
      console.log(error);
    });

    var that = this;

    auth.onAuthStateChanged(function (user) {
      if (user) {
        that.getData();
      } else {
      }
    });
  };

  handleVisibilityChange = (isVisible) => {
    //console.log("visib " + isVisible);

    this.setState({
      isVisible: isVisible,
    });

    if (isVisible) {
      if (!this.state.expired) {
        this.setState({
          gotZones: false,
        });
        this.getData();
      }
    }
  };

  componentDidMount = () => {
    this.login();
    ReactGA.initialize("UA-134985871-2", {
      debug: false,
    });
  };

  componentDidUpdate = () => {
    if (this.state.showMessage && !this.intervalId) {
      this.intervalId = setInterval(() => {
        this.setState({ progress: this.state.progress + 5 });
      }, 150);
    }
    if (this.state.progress > 100) {
      this.setState({ progress: 0, showMessage: false });
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  };

  getData = async () => {
    var roomid = null;

    if (this.props.match.params.uid) {
      //lejaros url
      const uid = this.props.match.params.uid;

      const getId = functions.httpsCallable("getRoomId");

      try {
        const res = await getId({
          generatedID: uid,
        });

        roomid = res.data.roomid;
      } catch (e) {
        console.log("no room");
      }
    }

    if (this.props.match.params.id) {
      roomid = this.props.match.params.id;
    }

    if (roomid) {
      ReactGA.pageview("/eco_room_set/" + roomid);

      this.setState({
        roomId: roomid,
      });

      this.roomsRef = database.ref("rooms/" + roomid);

      const snapshot = await this.roomsRef.once("value");

      const obj = snapshot.val();

      if (obj) {
        if (obj.redirect) {
          this.setState({
            redirect: obj.redirect,
          });

          ReactGA.pageview("/eco_room_set/redirect");

          ReactGA.event({
            category: "MobilePage",
            action: "ViewRedirect",
          });
        } else {
          const snapsight = await database.ref("sights").child(obj.sight_id).once("value");
          const currentSight = snapsight.val();

          //console.log(currentSight);

          const current = typeof obj.zones !== "undefined" ? obj.zones[0].temp : 0;

          const st = (current - 15) * 2 - 1;
          this.setState({
            isSummer: currentSight.summerModeActive,
            isFreezeProtection: obj.isFreezeProtection,
            isFreezeAvailable: currentSight.isFreezeAvailable,
            roomName: obj.name,
            roomTemp: obj.therm_measured_temperature,
            loading: false,
            zones: obj.zones,
            barChart: !!currentSight.isBarsOnMobile,
            mobileViewMinTemp: currentSight.settings.mobileViewMinTemp ? currentSight.settings.mobileViewMinTemp : 15.5,
            mobileViewMaxTemp: currentSight.settings.mobileViewMaxTemp ? currentSight.settings.mobileViewMaxTemp : 21.5,
            room: obj,
            oldRoom: structuredClone(obj),
            open_window: obj.open_window,
            gotZones: !!obj.zones,
            activeStep: st,
            sightName: currentSight.name,
            client: currentSight.client,
            sightInfo: currentSight.info,
            contactEmail: currentSight.settings.contactEmail,
            maintenanceMessage: currentSight.settings.maintenanceMessage,
            boostEnabled:
              typeof currentSight.settings.boostEnabled !== "undefined" &&
              currentSight.settings.boostEnabled &&
              typeof currentSight.settings.boostRoomTypes !== "undefined" &&
              typeof obj.type !== "undefined" &&
              currentSight.settings.boostRoomTypes.indexOf(obj.type) > -1,
            originalTemp: current,
            homeID: obj.home_id,
            youtube: currentSight.settings.youtube,
            /**
             * currently customer URLs are not available to be set on the UI
             */
            leaderboard:
              typeof currentSight.settings.leaderboard !== "undefined" && currentSight.settings.leaderboard !== ""
                ? currentSight.settings.leaderboard
                : "https://ecosync-leaders-board.web.app/sites/" + obj.home_id,
            leaderboardOff:
              typeof currentSight.settings.leaderboardOff !== "undefined"
                ? currentSight.settings.leaderboardOff
                : false,
            easterEgg: typeof currentSight.settings.easterEgg !== "undefined" ? currentSight.settings.easterEgg : true,
            timezone: currentSight.settings.timezone !== "undefined" ? currentSight.settings.timezone : "Europe/London",
          });

          ReactGA.event({
            category: "MobilePage",
            action: "View",
            label: this.state.sightName + " - " + this.state.roomName + " (" + this.state.roomId + ")",
          });

          let ids = [];

          const snap = await database.ref("schedules").once("value");

          Object.keys(snap.val()).forEach((key, index) => {
            ids.push(key);
          });

          const snapBoost = await database.ref("room_calendar/" + this.state.roomId + "/calendarmap").once("value");
          let scheduleEvent = null;
          let boostedFormatted = null;
          let hasCalendarIntegration = false;
          if (snapBoost.exists()) {
            scheduleEvent = this.hasScheduleNow(snapBoost.val());
            if (scheduleEvent && scheduleEvent.text && scheduleEvent.text === "boost") {
              let d = new Date(scheduleEvent.end);
              boostedFormatted = d.toLocaleTimeString();
            }
            hasCalendarIntegration = true;
          } else {
            const snapBoost = await database.ref("room_calendar/" + this.state.roomId).once("value");
            if (snapBoost.exists()) {
              hasCalendarIntegration = true;
            }
          }

          this.setState({
            scheduleIDs: ids,
            schedules: snap.val(),
            scheduleEvent: scheduleEvent,
            hasCalendarIntegration: hasCalendarIntegration,
            boosted: boostedFormatted,
          });
        }
      }
    } else {
      //ERROR MESSAGE, NO ROOM OR EXPIRED
      //console.log("no room or expired");
      this.setState({
        expired: true,
        loading: false,
        gotZones: true,
      });

      ReactGA.pageview("/eco_room_set/expired");

      ReactGA.event({
        category: "MobilePage",
        action: "ViewExpired",
      });
    }
  };

  boostTemp = async () => {
    this.setState({ boostClicked: true });
    await addRoomEventFrontend(this.state.homeID, this.state.roomId, this.state.room.name, USER_ACTION_BOOST);

    const setTemp = functions.httpsCallable("setTemperature");

    try {
      const resp = await setTemp({
        roomId: this.state.room.id,
        temp: this.state.comfortTemp,
        siteId: this.state.room.home_id,
        isFreeze: this.state.room.isFreezeProtection,
      });
      this.setState({ open: true });
      window.location.reload();
    } catch (e) {
      alert(e);
      this.setState({
        open: false,
      });
    }
  };

  windowOpened = async (room_id) => {
    await addRoomEventFrontend(this.state.homeID, this.state.roomId, this.state.room.name, OPEN_WINDOW_OPENED);
    alert("Thanks!");
  };

  windowClosed = async (room_id) => {
    await addRoomEventFrontend(this.state.homeID, this.state.roomId, this.state.room.name, OPEN_WINDOW_CLOSED);
    alert("Thanks!");
  };

  setFreeze = async () => {
    this.setState({ loading: true });
    console.log("set freeze");
    let updates = {};
    try {
      await addRoomEventFrontend(
        this.state.homeID,
        this.state.roomId,
        this.state.room.name,
        !this.state.isFreezeProtection ? USER_ACTION_SET_FREEZE_ON : USER_ACTION_SET_FREEZE_OFF,
        SETTER_USER_QR
      );
    } catch (e) {
      alert(e);
    }
    // updates["rooms/" + this.state.roomId + "/isFreezeProtection"] = !this.state.isFreezeProtection;
    try {
      const setTemp = functions.httpsCallable("setComfortTemp");
      const resp = await setTemp({
        roomId: this.state.room.id,
        temp: this.state.room.zones[0].temp,
        siteId: this.state.room.home_id,
        isFreeze: !this.state.isFreezeProtection,
      });
    } catch (e) {
      const sendAlert = functions.httpsCallable("sendAlertToSlackCall");
      await sendAlert({
        text: "setFreeze failed on mobile view",
        id: this.state.room.id,
        functionName: "setFreeze",
        obj: {},
      });
      alert("An error occured, please try again later");
    }
    this.setState({ isFreezeProtection: !this.state.isFreezeProtection });
    // console.log(updates);

    ReactGA.event({
      category: "MobilePage",
      action: "ChangeTemp",
      label: this.state.sightName + " - " + this.state.roomName + " (" + this.state.roomId + ") From Freeze Protection",
      value: !this.state.isFreezeProtection,
    });

    await database
      .ref()
      .update(updates)
      .then(() => {
        this.setState({
          open: true,
        });
        this.getData();
      })
      .catch((err) => {
        alert("error");
      });
    this.setState({ loading: false });
  };

  increaseTemp = () => {
    this.setState((prevState) => {
      let x = prevState.zones;

      if (prevState.zones[0].temp <= this.state.mobileViewMaxTemp + 0.5) {
        x[0].temp = parseFloat(prevState.zones[0].temp, 10) + 0.5;
      }
      const st = (x[0].temp - this.state.mobileViewMaxTemp) * 2 - 1;
      return {
        zones: x,
        isChanged: true,
        activeStep: st,
      };
    });
  };

  decreaseTemp = () => {
    this.setState((prevState) => {
      let x = prevState.zones;
      // console.log(this.state.mobileViewMinTemp);
      if (prevState.zones[0].temp >= this.state.mobileViewMinTemp + 0.5) {
        x[0].temp = parseFloat(prevState.zones[0].temp, 10) - 0.5;
      }
      const st = (x[0].temp - this.state.mobileViewMinTemp) * 2 - 1;
      // console.log(x[0].temp +"- 15) * 2 - 1");
      // console.log(st);

      return {
        zones: x,
        isChanged: true,
        activeStep: st,
      };
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  mailTo = () => {
    window.location.href =
      "mailto:" +
      this.state.contactEmail +
      "?subject=Question:" +
      this.state.client +
      " - " +
      this.state.sightName +
      "-" +
      this.state.roomName +
      "(" +
      this.state.roomId +
      ")";
  };
  reportIssue = () => {
    window.location.href =
      "https://ecosync.energy/report-issue/?room_number=" +
      this.state.roomName +
      "&building_name=" +
      this.state.client +
      " - " +
      this.state.sightName;
  };
  rickRoll = () => {
    window.location.href = "https://www.youtube.com/watch?v=dQw4w9WgXcQ";
  };
  openBear = () => {
    window.location.href = " https://support.wwf.org.uk/adopt-a-polar-bear";
  };
  demo = () => {
    window.location.href = "https://meetings.hubspot.com/len-don";
  };
  openInfo = (infoLink) => {
    window.location.href = infoLink;
  };
  getEventLabel = () => {
    return (
      this.state.client +
      " - " +
      this.state.sightName +
      " - " +
      this.state.roomName +
      " (" +
      this.state.roomId +
      ") From " +
      this.state.originalTemp +
      " To: " +
      this.state.zones[0].temp
    );
  };

  saveData = async () => {
    this.setState({ showMessage: true });
    let oldRoom = this.state.oldRoom;
    let room = oldRoom;
    try {
      const setTemp = functions.httpsCallable("setComfortTemp");
      const resp = await setTemp({
        roomId: room.id,
        temp: this.state.zones[0].temp,
        siteId: room.home_id,
        isFreeze: false,
      });

      this.setState({ dialogOpen: false, loading: false });
    } catch (e) {
      alert(e);
      this.setState({
        loading: false,
      });
    }

    let updates = {};

    if (this.state.originalTemp !== this.state.zones[0].temp) {
      await addRoomEventFrontend(
        oldRoom.home_id,
        this.state.roomId,
        oldRoom.name,
        USER_ACTION_SET_TEMP,
        SETTER_USER_QR,
        null,
        [this.state.originalTemp, this.state.zones[0].temp]
      );
    }

    updates["rooms/" + this.state.roomId + "/last_user_interaction"] = Date.now();
    updates["rooms/" + this.state.roomId + "/open_window"] = false;

    if (oldRoom.open_window) {
      updates["rooms/" + this.state.roomId + "/window_close_time"] = Date.now();
    }
    this.setState({ open_window: false });
    ReactGA.event({
      category: "MobilePage",
      action: "ChangeTemp",
      label: this.getEventLabel(),
      value: this.state.zones[0].temp,
    });

    await database
      .ref()
      .update(updates)
      .then(() => {
        this.setState({
          isChanged: false,
          open: true,
        });
      })
      .catch((err) => {
        alert("error");
      });
  };

  hasScheduleNow = (schedule) => {
    let ret = null;
    if (schedule) {
      schedule.forEach((b) => {
        if (b.start < parseInt(moment().format("x")) && b.end > parseInt(moment().format("x"))) {
          ret = b;
        }
      });
    }
    return ret;
  };

  calculateSchedule = () => {
    const schedules = this.state.schedules;
    const timezone = this.state.timezone;
    const timezoneOffset = moment.tz(timezone).utcOffset() * 60;
    if (schedules) {
      let homeSchedule = Object.keys(schedules).map((key) => {
        if (key.startsWith(this.state.homeID)) return schedules[key];
        else return null;
      });
      homeSchedule = homeSchedule.filter((s) => s);
      const currentSchedule = homeSchedule[0];
      if (currentSchedule) {
        const timeTable = currentSchedule.timetable;
        const scheduleEvent = this.state.scheduleEvent;
        const date = moment().add(timezoneOffset, "minutes").toDate(); // Adjust date for timezone
        const day = date.getDay();
        const timeElapsed = ((day ? day - 1 : 6) * 24 + date.getHours()) * 60 + date.getMinutes();
        const startToday = (day ? day - 1 : 6) * 24 * 60 + timezoneOffset;
        const endToday = (day ? day : 7) * 24 * 60 + timezoneOffset;
        if (timeTable) {
          // const currentIntervalMax = timeTable.filter(time => time.m_offset > timeElapsed);
          const currentIntervalMin = timeTable.filter((time) => time.m_offset < timeElapsed);
          const todayIntervalMin = timeTable.filter((time) => time.m_offset > startToday);
          const todayIntervalMax = timeTable.filter((time) => time.m_offset < endToday);
          // const end = currentIntervalMax[0];
          const start = currentIntervalMin[currentIntervalMin.length - 1];
          const comfortStart = Math.floor((todayIntervalMin[0].m_offset / 60) % 24);
          const comfortEnd = Math.ceil((todayIntervalMax[todayIntervalMax.length - 1].m_offset / 60) % 24);
          const comfortStartString =
            parseInt(comfortStart).toString().padStart(2, "0") +
            ":" +
            ((comfortStart % 1) * 60).toString().padStart(2, "0");
          const comfortEndString =
            parseInt(comfortEnd).toString().padStart(2, "0") +
            ":" +
            ((comfortEnd % 1) * 60).toString().padStart(2, "0");
          if (start.zone_id === 1 && !scheduleEvent) {
            return {
              disabled: true,
              message:
                "Currently you are only able to adjust the comfort settings of this room today between " +
                comfortStartString +
                " and " +
                comfortEndString +
                " if you need to adjust the temperature outside of this, please contact the buildings manager.",
            };
          } else {
            return {
              disabled: false,
              message:
                comfortStartString !== "00:00" && comfortEndString !== "24:00"
                  ? "You can set the temperature between " + comfortStartString + " and " + comfortEndString + ". "
                  : "",
            };
          }
        }
      }
    }
  };

  render() {
    const { classes } = this.props;
    const {
      sightInfo,
      roomName = "",
      roomTemp = null,
      roomId = "",
      open_window = false,
      loading = false,
      gotZones = false,
      expired = false,
      redirect = null,
      contactEmail,
      maintenanceMessage,
      youtube,
      leaderboard,
      leaderboardOff,
      isSummer,
      scheduleEvent,
      hasCalendarIntegration,
      boosted,
      isFreezeProtection,
      isFreezeAvailable,
      easterEgg = true,
    } = this.state;
    const schedule = this.calculateSchedule();

    const isManager = this.props.match.path.startsWith("/eco_rmbf");
    // const isMicropelt = roomId.startsWith("micropelt");
    const isDemo = roomId.startsWith(DEFAULT_TEST_HOME_ID.replaceAll("_", ""));
    const showCurrentTemp = roomId.startsWith("micropeltsh");
    const NHS_demo = roomId === "micropeltdemokenningtonecosyncdemoroom";
    if (redirect) {
      window.location.href = "https://ecosync-portal-nextjs-ecosync.vercel.app/en/ers/" + redirect;
      return;
    }
    return (
      <PageVisibility onChange={this.handleVisibilityChange}>
        <div>
          <Card className={classes.card}>
            {NHS_demo && (
              <Typography gutterBottom variant="title" component="h1">
                <center>Stop heating empty rooms!</center>
              </Typography>
            )}
            {sightInfo && (
              <CardActions>
                <Button
                  size="small"
                  color="primary"
                  onClick={() => this.openInfo(sightInfo)}
                  className={classes.button}
                >
                  How does it work?
                </Button>
              </CardActions>
            )}
            <CardMedia
              className={classes.media}
              onClick={this.openBear}
              position="top"
              image="https://firebasestorage.googleapis.com/v0/b/ecosync-dev.appspot.com/o/logo_roommate_large.jpg?alt=media&token=119ce89b-b98b-4417-9310-41ec5d889f68"
            />
            <CardContent
              style={{
                paddingBottom: 0,
                paddingTop: 0,
              }}
            >
              <Typography gutterBottom variant="subtitle1" component="h2">
                <center>room: {roomName}</center>
              </Typography>
              {open_window && !isFreezeProtection && !isSummer && (
                <Typography variant="h6" component="h2" className={classes.text}>
                  Open window detected. To re-activate heating please make sure the window is closed then press “Set
                  temperature”
                </Typography>
              )}
              {NHS_demo && (
                <CardActions>
                  <Button
                    size="small"
                    color="secondary"
                    onClick={this.demo}
                    className={classes.button}
                    variant="contained"
                  >
                    Book a demo
                  </Button>
                </CardActions>
              )}
              {maintenanceMessage && (
                <Typography variant="h6" component="h2" className={classes.text}>
                  {maintenanceMessage}
                </Typography>
              )}
              {loading || !gotZones ? (
                <CircularProgress className="progress" />
              ) : (
                <div>
                  {!expired && !isFreezeProtection && (
                    <div>
                      <div>
                        {this.state.barChart === true && (
                          <MobileStepper
                            variant="progress"
                            steps={14}
                            position="static"
                            activeStep={this.state.activeStep}
                            className={classes.steps}
                            LinearProgressProps={{
                              color: "secondary",
                              classes: {
                                root: classes.linear,
                              },
                            }}
                          />
                        )}
                      </div>
                      <div className={classes.content}>
                        <Button
                          color="secondary"
                          onClick={this.decreaseTemp}
                          disabled={
                            this.state.mobileViewMinTemp >= this.state.zones[0].temp ||
                            isFreezeProtection ||
                            isSummer ||
                            open_window
                          }
                        >
                          <Icon style={{ fontSize: 36 }}>remove_circle</Icon>
                        </Button>

                        {this.state.barChart === false && (
                          <Typography variant="h3" component="h2">
                            {this.state.zones[0].temp}&#8451;
                          </Typography>
                        )}

                        <Button
                          onClick={this.increaseTemp}
                          disabled={
                            this.state.mobileViewMaxTemp <= this.state.zones[0].temp ||
                            isFreezeProtection ||
                            isSummer ||
                            open_window
                          }
                          color="secondary"
                        >
                          <Icon style={{ fontSize: 36 }}>add_circle</Icon>
                        </Button>
                      </div>
                      {showCurrentTemp && (
                        <Typography variant="subtitle2" component="p" className={classes.text2} color="secondary">
                          Latest measured room temperature: {roomTemp}&#8451;
                        </Typography>
                      )}
                    </div>
                  )}
                  {this.state.expired && (
                    <Typography variant="subtitle2" component="p" className={classes.text2} color="secondary">
                      The URL you are using has now expired, please scan the QR code in the room to control the heating.
                    </Typography>
                  )}
                </div>
              )}
            </CardContent>
            <CardActions>
              {gotZones && !expired && !isFreezeProtection && (
                <Button
                  size="small"
                  color="secondary"
                  disabled={
                    isSummer || isFreezeProtection || boosted
                      ? true
                      : schedule && schedule.disabled && !scheduleEvent
                      ? true
                      : open_window
                      ? false
                      : !this.state.isChanged
                  }
                  onClick={this.saveData}
                  className={classes.button}
                  variant="contained"
                >
                  Set temperature
                </Button>
              )}
            </CardActions>
            {!isManager && schedule && (
              <CardActions>
                <Divider />
                <Typography variant="subtitle1" className={classes.warning} align="center">
                  {schedule.message}
                  {schedule.disabled && this.state.boostEnabled && (
                    <div>
                      <br />
                      To add an hour of extra heating, hit the Boost button!
                    </div>
                  )}
                </Typography>
                <Divider />
              </CardActions>
            )}
            {boosted !== null && (
              <>
                <Divider />
                <CardActions>
                  <Typography gutterBottom variant="title" component="h2">
                    <center>Heating is BOOSTED till {boosted}</center>
                  </Typography>
                  <Divider />
                </CardActions>
              </>
            )}
            <CardActions>
              <Typography variant="body1" component="p" className={classes.text}>
                By reducing the set temperature you can help us fighting climate change and protect the home of polar
                bears!
              </Typography>
            </CardActions>
            {gotZones && !expired && (isManager || isFreezeAvailable) && (
              <>
                <Divider />
                <div className={classes.box}>
                  {loading ? (
                    <CircularProgress />
                  ) : (
                    <div className={classes.switchBox}>
                      <Typography className={isFreezeProtection ? classes.text : classes.textGreen} variant="body1">
                        IN
                      </Typography>
                      <Switch checked={isFreezeProtection} onChange={() => this.setFreeze()} />
                      <Typography className={isFreezeProtection ? classes.textRed : classes.text} variant="body1">
                        OUT
                      </Typography>
                    </div>
                  )}
                  {this.state.isFreezeProtection ? (
                    <Typography className={classes.text} variant="body1" align="center">
                      Your room has been marked as <strong>EMPTY</strong>, the heating has been turned{" "}
                      <strong>OFF</strong>, to reactivate the heating switch to <strong>IN</strong>, if you are still
                      experiencing problems please speak to your <strong>Facility Manager</strong>
                    </Typography>
                  ) : (
                    <Typography className={classes.text} variant="body1" align="center">
                      Please set yourself <strong>in/out</strong> when you are <strong>in/out of the room</strong>, as
                      this will improve your carbon footprint, you can reactivate it when you are back in the room{" "}
                    </Typography>
                  )}
                </div>
              </>
            )}

            {isDemo && (
              <>
                <Divider />
                <CardActions>
                  <Button
                    size="small"
                    color="secondary"
                    onClick={this.windowOpened}
                    className={classes.button}
                    variant="contained"
                  >
                    Window opened
                  </Button>
                  <Button
                    size="small"
                    color="secondary"
                    onClick={this.windowClosed}
                    className={classes.button}
                    variant="contained"
                  >
                    Window closed
                  </Button>
                </CardActions>
              </>
            )}
            {!isManager && isFreezeProtection && !isFreezeAvailable && (
              <>
                <Divider />
                <Typography variant="subtitle1" className={classes.warning} align="center">
                  Freeze protection has been activated by your facilities management team, please contact them directly
                  about re-activating the heating.
                </Typography>
              </>
            )}
            {!isManager && isSummer && (
              <>
                <Divider />
                <Typography variant="subtitle1" className={classes.warning} align="center">
                  Heating has been deactivated in the building by your facilities management team, please contact them
                  directly about re-activating the heating.
                </Typography>
              </>
            )}

            {gotZones &&
              !expired /*&& !isMicropelt*/ &&
              ((schedule && schedule.disabled) || (!scheduleEvent && hasCalendarIntegration)) &&
              this.state.boostEnabled && (
                <>
                  <Divider />
                  <CardActions>
                    <Button
                      size="small"
                      color="secondary"
                      onClick={this.boostTemp}
                      className={classes.button}
                      variant="contained"
                      disabled={this.state.boostClicked}
                    >
                      Boost temperature for 1 hr
                    </Button>
                  </CardActions>{" "}
                </>
              )}
            {!NHS_demo && (
              <CardActions>
                {contactEmail && (
                  <Button size="small" color="primary" onClick={this.mailTo} className={classes.button}>
                    Report issue
                  </Button>
                )}
                <Button size="small" color="primary" onClick={this.reportIssue} className={classes.button}>
                  Send us feedback
                </Button>
                {easterEgg && (
                  <>
                    <Divider />
                    <Button size="small" color="primary" onClick={this.rickRoll} className={classes.button}>
                      -do-not-click-this-
                    </Button>
                  </>
                )}
              </CardActions>
            )}
            {youtube && (
              <>
                <Divider />
                <div className={classes.video_responsive}>
                  <iframe
                    width="100%"
                    src={`https://www.youtube.com/embed/${youtube}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                  />
                </div>
              </>
            )}
            {leaderboard && !leaderboardOff && (
              <>
                <Divider />
                <div className={classes.leaderboard_responsive}>
                  <iframe
                    width="100%"
                    height="100%"
                    src={`${leaderboard}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="EcoSync Leaderboard"
                  />
                </div>
              </>
            )}
          </Card>

          <Typography variant="body1" component="p" className={classes.text}>
            Roommate © powered by{" "}
            <a href="http://ecosync.energy" target="_blank" rel="noopener noreferrer">
              EcoSync
            </a>{" "}
            - All Right Reserved
          </Typography>

          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={this.state.open}
            onClose={this.handleClose}
            snackbarcontentprops={{
              "aria-describedby": "message-id",
            }}
            message={<span id="message-id">Saved..</span>}
          />

          <Dialog open={this.state.showMessage}>
            <div className={classes.dialogDiv}>
              <Typography className={classes.message} align="center">
                Your new setting will take effect in a few minutes
              </Typography>
              <Box sx={{ width: "100%" }}>
                <LinearProgress variant="determinate" value={this.state.progress} />
              </Box>
            </div>
          </Dialog>
        </div>
      </PageVisibility>
    );
  }
}

MobileRoom.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MobileRoom);
