import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import { connect } from "react-redux";

import SettingsSchedule from "./SettingsSchedule";
import SettingsZone from "./SettingsZone";
// import SettingsCustom from "./SettingsCustom";
import MainSettings from "./MainSettings";
import BuildingSettings from "./BuildingSettings";
import ConfirmDialog from "../ConfirmDialog";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    minWidth: 1100,
  },
  flex: {
    flex: 1,
  },
});

const mapDispatchToProps = (dispatch) => ({
  didMount: () =>
    dispatch({
      type: "PAGE_CHANGED",
      payload: "Settings",
    }),
});

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

class SettingsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      homes: this.props.homes,
      sightId: this.props.sightId,
      isAdmin: this.props.isAdmin,
      isSuperAdmin: this.props.isSuperAdmin,
      hasChanges: false,
      openConfirm: false,
    };
  }

  componentDidMount() {
    this.props.didMount();
  }

  handleChange = (event, value) => {
    if (this.state.hasChanges) {
      this.setState({ openConfirm: true, nextValue: value });
    } else {
      this.setState({ value });
    }
  };

  confirmHandler = (value) => {
    this.setState({
      openConfirm: false,
    });
    if (value) {
      this.setHasChanges(false);
      this.setState({ value: this.state.nextValue });
    }
  };

  setHasChanges = (changes) => {
    if (!this.props.userData.viewOnly) {
      this.setState({ hasChanges: changes });
    }
  };

  render() {
    const { classes, userData } = this.props;
    const { value } = this.state;
    let selectedSight;
    if (this.props.sightsData.sights) {
      selectedSight = this.props.sightsData.sights.find((sight) => {
        return sight.id === this.props.sightsData.selectedSite;
      });
    }
    return (
      <div id="sectionHolder">
        <Paper className={classes.root}>
          <AppBar position="static" color="default" elevation={0}>
            <Tabs value={value} onChange={this.handleChange} indicatorColor="primary" textColor="primary">
              <Tab label="Default Temperature Settings" href="#zones" />
              <Tab label="Default Schedule" href="#def_sched" />
              {/*<Tab label="Custom Schedule" href="#custom" />*/}
              <Tab label="Notification Settings" href="#mainsettings" />
              {selectedSight && <Tab label="Building Settings" href="#buildingsettings" />}
            </Tabs>
          </AppBar>
          {value === 0 && (
            <TabContainer>
              <SettingsZone setHasChanges={this.setHasChanges} viewOnly={userData.viewOnly} />
            </TabContainer>
          )}
          {value === 1 && (
            <TabContainer>
              <SettingsSchedule setHasChanges={this.setHasChanges} viewOnly={userData.viewOnly} />
            </TabContainer>
          )}

          {/*value === 2 && (
            <TabContainer>
              <SettingsCustom setHasChanges={this.setHasChanges}/>
            </TabContainer>
          )*/}
          {value === 2 && (
            <TabContainer>
              {<MainSettings setHasChanges={this.setHasChanges} viewOnly={userData.viewOnly} />}
            </TabContainer>
          )}
          {value === 3 && (
            <TabContainer>
              {
                <BuildingSettings
                  roomTypes={this.props.roomsData.roomTypes}
                  setHasChanges={this.setHasChanges}
                  props={this.props}
                  selectedSight={selectedSight}
                  viewOnly={userData.viewOnly}
                />
              }
            </TabContainer>
          )}
        </Paper>

        <ConfirmDialog
          open={this.state.openConfirm}
          action={this.confirmHandler}
          text="You have unsaved changes, are you sure you want to leave?"
        />
      </div>
    );
  }
}

SettingsPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SettingsPage));
