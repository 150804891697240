import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import React from "react";
import moment from "moment";
import "moment-timezone";
import CircularProgress from "@material-ui/core/CircularProgress";
import { functions } from "../../config/firebase";
import { eventText } from "../../constants/events";
const {
  FIRE,
  OPEN_WINDOW,
  NO_HEATING,
  NO_HEATING_CONTROL,
  STUCK_PIN,
  OFFLINE_TRV,
  OFFLINE_GW,
  MECHANICAL_ERROR,
  CELLULAR_GW,
  REFERENCE_RUN,
  MANUAL_REFERENCE_RUN,
  EXTRA_HEAT_SOURCE,
  AWAY_LOW_TEMP,
  USER_ACTION_SET_TEMP,
  USER_ACTION_SET_FREEZE_ON,
  USER_ACTION_SET_FREEZE_OFF,
  USER_ACTION_ADMIN_SET_TEMP,
  USER_ACTION_ADMIN_SET_CUSTOM_TEMP,
  USER_ACTION_ADMIN_SET_CUSTOM_TEMP_OFF,
  USER_ACTION_ADMIN_SET_TEMP_BULK,
  USER_ACTION_ADMIN_SET_FREEZE_ON,
  USER_ACTION_ADMIN_SET_FREEZE_ON_BULK,
  USER_ACTION_ADMIN_SET_FREEZE_OFF,
  USER_ACTION_ADMIN_SET_FREEZE_OFF_BULK,
  USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_ON,
  USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_ON_BULK,
  USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_OFF,
  USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_OFF_BULK,
  USER_ACTION_SLACK_SET_TEMP,
  USER_ACTION_BOOST,
} = require("../../constants/events");

class EventHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      graphdata: [],
      roomId: this.props.roomId,
      homeId: this.props.homeId,
      isLoading: true,
      date_begin: this.props.date_begin,
      date_end: this.props.date_end,
      date_type: this.date_type,
      timezone: this.props.timezone, // default timezone: "Europe/London"
      FIRE: FIRE,
      OPEN_WINDOW: OPEN_WINDOW,
      NO_HEATING: NO_HEATING,
      NO_HEATING_CONTROL: NO_HEATING_CONTROL,
      STUCK_PIN: STUCK_PIN,
      OFFLINE_TRV: OFFLINE_TRV,
      OFFLINE_GW: OFFLINE_GW,
      MECHANICAL_ERROR: MECHANICAL_ERROR,
      CELLULAR_GW: CELLULAR_GW,
      REFERENCE_RUN: REFERENCE_RUN,
      MANUAL_REFERENCE_RUN: MANUAL_REFERENCE_RUN,
      EXTRA_HEAT_SOURCE: EXTRA_HEAT_SOURCE,
      AWAY_LOW_TEMP: AWAY_LOW_TEMP,
      USER_ACTION_SET_TEMP: USER_ACTION_SET_TEMP,
      USER_ACTION_SET_FREEZE_ON: USER_ACTION_SET_FREEZE_ON,
      USER_ACTION_SET_FREEZE_OFF: USER_ACTION_SET_FREEZE_OFF,
      USER_ACTION_ADMIN_SET_TEMP: USER_ACTION_ADMIN_SET_TEMP,
      USER_ACTION_ADMIN_SET_CUSTOM_TEMP: USER_ACTION_ADMIN_SET_CUSTOM_TEMP,
      USER_ACTION_ADMIN_SET_CUSTOM_TEMP_OFF: USER_ACTION_ADMIN_SET_CUSTOM_TEMP_OFF,
      USER_ACTION_ADMIN_SET_TEMP_BULK: USER_ACTION_ADMIN_SET_TEMP_BULK,
      USER_ACTION_ADMIN_SET_FREEZE_ON: USER_ACTION_ADMIN_SET_FREEZE_ON,
      USER_ACTION_ADMIN_SET_FREEZE_ON_BULK: USER_ACTION_ADMIN_SET_FREEZE_ON_BULK,
      USER_ACTION_ADMIN_SET_FREEZE_OFF: USER_ACTION_ADMIN_SET_FREEZE_OFF,
      USER_ACTION_ADMIN_SET_FREEZE_OFF_BULK: USER_ACTION_ADMIN_SET_FREEZE_OFF_BULK,
      USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_ON: USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_ON,
      USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_ON_BULK: USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_ON_BULK,
      USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_OFF: USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_OFF,
      USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_OFF_BULK: USER_ACTION_ADMIN_SET_OPEN_WINDOW_DETECTION_OFF_BULK,
      USER_ACTION_SLACK_SET_TEMP: USER_ACTION_SLACK_SET_TEMP,
      USER_ACTION_BOOST: USER_ACTION_BOOST,
    };
  }

  dateFormat = (time) => {
    if (this.state.date_type === "isoWeek") {
      return moment.unix(time).format("ddd, DD");
    }
    if (this.state.date_type === "month") {
      return moment.unix(time).format("MMM DD");
    }
    if (this.state.date_type === "year") {
      return moment.unix(time).format("MMMM");
    }
    return moment.unix(time).format("ddd, DD, MMM");
  };

  timeFormat = (time) => {
    return moment.unix(time).tz(this.state.timezone).format("dddd, MMMM Do YYYY, H:mm");
  };

  percentFormat = (percent) => {
    return percent + "%";
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.roomId !== this.props.roomId) {
      this.setState(
        {
          roomId: nextProps.roomId,
          homeId: nextProps.homeId,
          timezone: nextProps.timezone,
          isLoading: true,
        },
        () => this.loadData()
      );
    }
    if (nextProps.date_begin !== this.props.date_begin) {
      this.setState(
        {
          date_begin: nextProps.date_begin,
          date_end: nextProps.date_end,
          timezone: nextProps.timezone,
          isLoading: true,
        },
        () => this.loadData()
      );
    }
    if (nextProps.date_type !== this.props.date_type) {
      this.setState({
        date_type: nextProps.date_type,
        timezone: nextProps.timezone,
        isLoading: true,
      });
    }
  }

  componentDidMount() {
    //set selects
    this.loadData();
  }

  async loadData() {
    if (this.state.roomId) {
      const getRoomMeasure = functions.httpsCallable("getRoomEventsCall");

      try {
        const data = await getRoomMeasure({
          room_id: this.state.roomId,
          home_id: this.state.homeId,
          date_begin: this.state.date_begin,
          date_end: this.state.date_end,
        });
        if (typeof data === "undefined") {
          console.error([
            "getRoomEventsCall error",
            {
              room_id: this.state.roomId,
              home_id: this.state.homeId,
              date_begin: this.state.date_begin,
              date_end: this.state.date_end,
            },
          ]);
        }

        this.setState({
          data: typeof data.data !== "undefined" ? data.data : {},
          isLoading: false,
        });
      } catch (e) {
        console.log(e);
      }
    }
  }

  render() {
    const { data, isLoading } = this.state;
    const { classes, visibleEventsInHistory, isSuperAdmin, notificationTexts } = this.props;
    if (isLoading) {
      return <CircularProgress className="progress" />;
    }
    if (typeof data !== "undefined" && Object.values(data).length && visibleEventsInHistory) {
      let listItems = Object.values(data).map((event) => {
        if (isSuperAdmin) {
          return (
            <li key={event.created_at}>
              {this.timeFormat(event.created_at)} ({event.created_by}): {eventText(event, notificationTexts)}
            </li>
          );
        } else {
          let ret = visibleEventsInHistory.map((vn) => {
            if (event.type === this.state[vn] /* && event.live === true*/) {
              return (
                <li key={event.created_at}>
                  {this.timeFormat(event.created_at)} ({event.created_by}): {eventText(event, notificationTexts)}
                </li>
              );
            } else return null;
          });
          ret = ret.filter((item) => item);
          if (ret.length) return ret[0];
          else return null;
        }
      });

      return (
        listItems.length > 0 && (
          <Paper className={classes.root}>
            <Typography className={classes.heading}>History</Typography>
            <div>
              <ul>
                {listItems.map((item) => {
                  return item;
                })}
              </ul>
            </div>
          </Paper>
        )
      );
    } else {
      return null;
    }
  }
}

export default EventHistory;
