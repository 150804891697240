import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import CircularProgress from "@material-ui/core/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import {
  saveSchedules,
  fetchSettings,
  saveHeatingSeasonSettings,
  saveCustomNotificationSettings,
  setSummerMode,
} from "../../actions";
// import InfoTooltip from "../Common/InfoTooltip";
import NotificationSettings from "./NotificationsSettings";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionActions from "@material-ui/core/AccordionActions";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Snackbar from "@material-ui/core/Snackbar";

// import _ from "lodash";

// const parentStyle = { overflow: "hidden" };

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    float: "right",
    marginLeft: 20,
    marginTop: 20,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  listTitle: {
    marginRight: 30,
    width: 240,
    fontSize: 18,
  },
  heading: {
    fontSize: 14,
    fontWeight: 500,
    textTransform: "uppercase",
  },
  secondaryHeading: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "left",
  },
  column: {
    flexBasis: "33.33%",
  },
  body: {
    fontSize: 12,
    marginBottom: 20,
    display: "block",
  },
});

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  saveSchedules: (updates) => dispatch(saveSchedules(updates)),
  fetchSettings: () => dispatch(fetchSettings()),
  saveHeatingSeason: (val) => dispatch(saveHeatingSeasonSettings(val)),
  saveCustomNotifications: (settings, email) => dispatch(saveCustomNotificationSettings(settings, email)),
  setSummerMode: (homeid, isHeatingS) => dispatch(setSummerMode(homeid, isHeatingS)),
});

class MainSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      isAdmin: false,
      isSuperAdmin: false,
      isHeatingS: false,
      confirmHeatingSeasonOpen: false,
      notifications_settings: [],
      isChanged: false,
    };
  }

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.checked,
    });
    if (event.target.name === "isHeatingS") {
      this.setState({ isChanged: true });
    }
    this.props.setHasChanges(true);
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  confirmHeatingSeason = () => {
    this.setState({ confirmHeatingSeasonOpen: true });
  };

  handleHeatingSeasonClose = () => {
    this.setState({
      confirmHeatingSeasonOpen: false,
      isHeatingS: !this.state.isHeatingS,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.sightsData.settings !== prevProps.sightsData.settings) {
      const { sightsData: { settings: { isHeatingSeason } = {} } = {} } = this.props;

      this.setState({
        isHeatingS: isHeatingSeason,
      });
    }

    if (this.props.sightsData.selectedSite !== prevProps.sightsData.selectedSite) {
      this.addDefaultNotifications();
    }
  }

  addDefaultNotifications() {
    let new_notifications_settings = [];
    new_notifications_settings["default"] = this.props.sightsData.default_settings;
    Object.keys(this.props.sightsData.notifications_settings).forEach((email) => {
      if (email !== "default") {
        new_notifications_settings[email] = this.props.sightsData.default_settings;
        Object.keys(this.props.sightsData.notifications_settings[email]).forEach((key) => {
          new_notifications_settings[email][key] = this.props.sightsData.notifications_settings[email][key];
        });
      }
    });
    this.setState({
      notifications_settings: new_notifications_settings,
    });
  }

  getData() {
    this.props.fetchSettings();
    this.setState({
      notifications_settings: this.props.sightsData.notifications_settings,
    });
  }
  componentDidMount() {
    this.getData();
    this.addDefaultNotifications();
  }

  saveHeatingSeasonChange = async () => {
    // console.log("save stuff");
    this.props.saveHeatingSeason(this.state.isHeatingS);
    this.props.setSummerMode(this.props.sightsData.selectedSite, this.state.isHeatingS);
    this.setState({
      isHeatingSeason: this.state.isHeatingS,
      confirmHeatingSeasonOpen: false,
      isChanged: false,
    });
    this.props.setHasChanges(false);
  };

  saveCustomNotification = async (settings, email) => {
    // console.log("save stuff custom");

    this.setState({
      open: true,
    });
    this.props.saveCustomNotifications(settings, email);
  };

  setHasChanges = (changes) => {
    this.props.setHasChanges(changes);
  };

  render() {
    const {
      classes,
      userData: { isAdmin = false, isReceptionist = false, isSuperAdmin = false } = {},
      sightsData: {
        default_settings = [],
        selectedSite = 0,
        settings: { feature_isHeatingSeasonSwitchEnabled = false, isHeatingSeason = true } = {},
        notify_emails = [],
        // notifications_settings: { default: default_settings = [] } = {},
        // notifications_settings = {},
        notificationTexts = [],
      },
    } = this.props;
    const isHeatingFeatureOn = feature_isHeatingSeasonSwitchEnabled;

    const canEdit = (isAdmin || isReceptionist || isSuperAdmin) && isHeatingFeatureOn;

    const { isHeatingS } = this.state;

    return (
      <div>
        <Accordion defaultExpanded elevation={0} variant="outlined" disabled={!isHeatingFeatureOn}>
          <AccordionSummary aria-controls="panel1c-content" id="panel1c-header" expandIcon={<ExpandMoreIcon />}>
            <div className={classes.column}>
              <Typography className={classes.heading}>Heating season on/off</Typography>
            </div>
            <div className={classes.column}>
              <Typography className={classes.secondaryHeading}></Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <div>
              <Typography className={classes.body} variant="body1">
                Let the Dashboard know when you start or end your heating season and define below what notifications to
                receive during and outside of the heating season.
              </Typography>
              <Typography className={classes.body} variant="body1">
                Switching heating season to OFF will set the valve actuators to summer mode, which is necessary for
                longer battery life.
              </Typography>
              <FormControlLabel
                control={<Switch checked={isHeatingS} onChange={this.handleChange} name="isHeatingS" color="primary" />}
                label={isHeatingS ? "ON" : "OFF"}
              />
            </div>
          </AccordionDetails>
          <Divider />
          <AccordionActions>
            {" "}
            {(isAdmin || isReceptionist || isSuperAdmin) && (
              <Button
                size="small"
                color="primary"
                disabled={
                  !canEdit ||
                  !this.state.isChanged ||
                  this.state.isHeatingS !== this.state.isHeatingS ||
                  this.props.viewOnly
                }
                onClick={() => {
                  this.saveHeatingSeasonChange();
                }}
              >
                Save
              </Button>
            )}
          </AccordionActions>
        </Accordion>

        <NotificationSettings
          notificationsSettings={this.state.notifications_settings}
          defaultSettings={default_settings}
          notifyEmails={notify_emails}
          notificationTexts={notificationTexts}
          isAdmin={isAdmin}
          isSuperAdmin={isSuperAdmin}
          selectedSite={selectedSite}
          saveSettings={this.saveCustomNotification}
          setHasChanges={this.setHasChanges}
          viewOnly={this.props.viewOnly}
        />

        {/* (isAdmin || isReceptionist) && (
          <Button
            onClick={this.saveData}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Save
          </Button>
        ) */}

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={this.state.open}
          onClose={this.handleClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">Custom settings saved</span>}
          autoHideDuration={3000}
        />

        <Dialog
          open={this.state.confirmHeatingSeasonOpen}
          onClose={this.handleHeatingSeasonClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Heating season: {isHeatingS ? "ON" : "OFF"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please note that, if you change the Heating Season, it might affect the notifications you are getting.
              (need copy here)
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleHeatingSeasonClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => {
                this.saveHeatingSeasonChange();
              }}
              color="secondary"
              autoFocus
              disabled={this.props.viewOnly}
            >
              SAVE
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

MainSettings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MainSettings));
