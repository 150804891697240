import { FETCH_ROOMS, FETCH_CALENDARS } from "../actions/types";
import moment from "moment";

const initialState = {
  rooms: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ROOMS:
      let rooms = action.payload;
      let sightID = action.sightID;
      let positions = action.positions;
      const roomTypes = action.roomTypes;
      let currentRooms = [];

      if (rooms) {
        Object.keys(rooms).forEach(function (key) {
          if (rooms[key].sight_id === sightID) {
            if (positions[key]) {
              rooms[key].position = positions[key];
            } else {
              rooms[key].position = {
                left: 0,
                top: 0,
              };
            }
            try {
              if (
                rooms[key].name.toLowerCase() !== "dummy" &&
                (rooms[key].disabled === undefined || !rooms[key].disabled)
              ) {
                currentRooms.push(rooms[key]);
              }
            } catch (e) {
              console.error("Missing room `name` field");
              console.log(key);
              console.log(rooms[key]);
              console.error(e.message);
            }
          }
        });
        //sort currentRooms by name
        currentRooms.sort(compare);
        return {
          ...state,
          rooms: currentRooms,
          firstRoomId: currentRooms[0].id,
          firstRoomHomeId: currentRooms[0].home_id,
          roomTypes: roomTypes,
        };
      } else {
        return initialState;
      }
    case FETCH_CALENDARS:
      const cals = action.calendars;
      // var sID = action.sightID;

      var calObj = {};
      if (cals) {
        var today = moment();
        Object.keys(cals).forEach((key) => {
          if (today.isSame(moment(cals[key].updatedAt), "day")) {
            calObj[key] = cals[key];
          }
        });
        return {
          ...state,
          calendars: calObj,
        };
      } else {
        return {
          ...state,
          calendars: {},
        };
      }

    default:
      return state;
  }
};

function compare(a, b) {
  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
  return 0;
}
