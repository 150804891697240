import React from "react";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import "./ResetDialog.css";
import { sendPasswordResetEmail } from "../../actions";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const styles = {
  card: {
    maxWidth: 320,
    height: 360,
  },
  media: {
    height: 120,
  },
  resetButton: {
    marginLeft: "auto",
  },
};

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  sendPasswordResetEmail: (email) => dispatch(sendPasswordResetEmail(email)),
});
class Reset extends React.Component {
  state = {
    email: "",
  };
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };
  render() {
    const { classes } = this.props;

    return (
      <div className="cont">
        <Card className={classes.card}>
          <CardMedia
            className={classes.media}
            image="https://images.unsplash.com/photo-1495192432427-e9010da2fbc7?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=4a760112bc72e72c43a78c855ec04732&auto=format&fit=crop&w=400&h=200&q=80"
            title="EcoSync"
          />
          <CardContent>
            <Typography variant="h5" component="h2">
              EcoSync Password Reset
            </Typography>
            <TextField
              autoFocus
              type="email"
              value={this.state.email}
              onChange={this.handleChange("email")}
              label="Email Address"
            />
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              color="primary"
              className={classes.resetButton}
              onClick={() => this.props.sendPasswordResetEmail(this.state.email)}
            >
              Send password reset email
            </Button>
          </CardActions>
        </Card>
      </div>
    );
  }
}

Reset.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Reset));
