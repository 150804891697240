import {
  FETCH_CALENDARS,
  FETCH_CLIENTS,
  FETCH_DEVICES,
  FETCH_MANUALS,
  FETCH_NOTIFICATIONS,
  FETCH_QR_CSV_DATA,
  FETCH_ROOMS,
  FETCH_SCHEDULES,
  FETCH_SETTINGS,
  FETCH_SIGHTS,
  FETCH_USER,
  FETCH_USER_DATA,
  GATEWAY_DISABLED_CHANGE,
  GATEWAY_RESTART_CHANGE,
  SELECT_CLIENT,
  SELECT_SIGHT,
} from "./types";
import { auth, database, functions, storage } from "../config/firebase";
import moment from "moment";
import { SETTER_USER_DEFAULT } from "../constants/events";
import { BigQuery } from "@google-cloud/bigquery";

//AUTH
export const fetchUser = () => (dispatch) => {
  auth.onAuthStateChanged((user) => {
    if (user) {
      dispatch({
        type: FETCH_USER,
        payload: user,
      });
    } else {
      dispatch({
        type: FETCH_USER,
        payload: null,
      });
    }
  });
};

export const logout = () => (dispatch) => {
  auth
    .signOut()
    .then(() => {
      // Sign-out successful.
    })
    .catch((error) => {
      console.log(error);
    });
};

export const login = (email, password) => (dispatch) => {
  return auth.signInWithEmailAndPassword(email, password).catch(function (error) {
    let errorCode = error.code;
    let errorMessage = error.message;
    if (errorCode === "auth/wrong-password") {
      alert("Wrong password.");
    } else {
      alert(errorMessage);
    }
  });
};

export const sendPasswordResetEmail = (email) => (dispatch) => {
  auth
    .sendPasswordResetEmail(email)
    .then(function () {
      alert("Password reset link sent!");
    })
    .catch(function (error) {
      console.error(error);
      alert(error.message);
    });
};

export const fetchSights = (uid) => async (dispatch, getState) => {
  //console.log(uid);
  const state = getState();

  const userSnap = await database.ref("users/" + uid).once("value");

  const user = userSnap.val();

  const snap = await database.ref("sights").once("value");

  const clientID = state.sightsData.selectedClient;

  dispatch({
    type: FETCH_SIGHTS,
    payload: snap.val(),
    uid: uid,
    superAdmin: !!user.isSuperAdmin,
    clientAdmin: !!user.isClientAdmin,
    uc: user.client,
    selectedClient: clientID,
  });
};

export const fetchUserData = (uid) => async (dispatch) => {
  const snapshot = await database.ref("users/" + uid).once("value");
  let user;
  let logo;
  if (snapshot.exists()) {
    user = snapshot.val();
    const logoSnap = user.partner && (await database.ref("/partners/" + user.partner + "/logo").once("value"));
    logo = logoSnap && logoSnap.exists() && logoSnap.val();
    user.partner_logo = logo;
  }

  dispatch({
    type: FETCH_USER_DATA,
    payload: user,
  });
};

export const fetchScheduleData = (sightID) => async (dispatch) => {
  dispatch({
    type: FETCH_SCHEDULES,
  });
  if (sightID) {
    const schedule = await database.ref("schedule/" + sightID + "/default").once("value");

    const schedules = await database.ref("schedules").once("value");

    dispatch({
      type: FETCH_SCHEDULES,
      payload: schedule.val(),
      schedules: schedules.val(),
      sightID: sightID,
    });
  }
};

export const fetchSettings = () => async (dispatch, getState) => {
  const state = getState();

  const sightID = state.sightsData.selectedSite;

  if (sightID) {
    const settings = await database.ref("sights/" + sightID + "/settings").once("value");

    dispatch({
      type: FETCH_SETTINGS,
      payload: settings.val(),
      sightID: sightID,
    });
  }
};

export const fetchClients = () => async (dispatch, getState) => {
  const clients = await database.ref("clients").once("value");
  let arr = [];
  Object.values(clients.val()).forEach((item) => {
    if (!item.sort && item.name) {
      item.sort = item.name.toLowerCase();
    }
    arr.push(item);
  });
  arr.sort((a, b) => (a.sort > b.sort ? 1 : b.sort > a.sort ? -1 : 0));
  dispatch({
    type: FETCH_CLIENTS,
    payload: arr,
  });
};
export const fetchQRCsvData = () => async (dispatch, getState) => {
  const state = getState();

  const sightID = state.sightsData.selectedSite;

  const URL = "https://ecosync-portal-nextjs-ecosync.vercel.app/en/ers/";
  const URL_FIX = "https://ecosync-portal-nextjs-ecosync.vercel.app/en/eco_rmbf/";
  const allowNoVARoomsSnap = await database.ref("settings/allow_all_rooms_in_qrs").once("value");
  let allowNoVARooms = false;
  if (allowNoVARoomsSnap.exists()) {
    allowNoVARooms = allowNoVARoomsSnap.val();
  }
  if (sightID) {
    const homes = await database.ref("sights/" + sightID + "/home_ids").once("value");
    const home_ids = homes.val();
    let retAll = [];
    if (Object.keys(home_ids).length) {
      const shit = Object.keys(home_ids).map(async (home_id) => {
        let retIn = [];
        const roomsRef = await database.ref("buildings/" + home_id + "/rooms").once("value");
        const rooms = roomsRef.val();
        if (Object.keys(rooms).length) {
          Object.keys(rooms).map(async (room_id) => {
            const room = rooms[room_id];
            if (typeof room.room_link_id !== "undefined" && (typeof room.modules !== "undefined" || allowNoVARooms)) {
              retIn.push([URL + room.room_link_id, room.name, URL_FIX + room.id, typeof room.modules !== "undefined"]);
            }
          });
          if (retIn.length) {
            retIn.sort((a, b) => (a[1] > b[1] ? 1 : -1));
          }
          return retIn;
        }
      });
      retAll = await Promise.all(shit);
    }
    let ret = [];
    ret.push(["id", "name"]);
    retAll = retAll.flat(1);
    ret = ret.concat(
      retAll
        .filter((r) => r[3])
        .map((room) => {
          return [room[0], room[1]];
        })
    );
    let ret2 = [];
    ret2.push(["id", "name"]);
    ret2 = ret2.concat(
      //retAll.map((room) => {
      retAll
        .filter((r) => r[3])
        .map((room) => {
          return [room[2], room[1]];
        })
    );
    let ret3 = [];
    ret3.push(["id", "name"]);
    ret3 = ret3.concat(
      retAll.map((room) => {
        return [room[0], room[1]];
      })
    );
    let ret4 = [];
    ret4.push(["id", "name"]);
    ret4 = ret4.concat(
      retAll.map((room) => {
        return [room[2], room[1]];
      })
    );
    dispatch({
      type: FETCH_QR_CSV_DATA,
      payload: [ret, ret2, ret3, ret4],
      sightID: sightID,
    });
  }
};

export const fetchShortUrls = () => async (dispatch, getState) => {
  const state = getState();
  let fixedArray = state.sightsData.QRCsvFixData;
  fixedArray.shift();
  let nums = fixedArray.map((item) => {
    let url = item[0];
    let splitUrl = url.split("/en/")[1]; // split the URL and get the part after '.energy/'
    return encodeURIComponent(encodeURIComponent(splitUrl));
  });
};

export const saveHeatingSeasonSettings = (heatingSeason) => async (dispatch, getState) => {
  const state = getState();

  const sightID = state.sightsData.selectedSite;

  if (sightID) {
    await database.ref("sights/" + sightID + "/settings/isHeatingSeason").set(heatingSeason);

    /* dispatch({
      type: FETCH_SETTINGS,
      payload: settings.val(),
      sightID: sightID,
    });*/

    //dispatch(fetchSettings());
  }
};

export const saveBoostDataSettings = (boostdata) => async (dispatch, getState) => {
  const state = getState();

  const sightID = state.sightsData.selectedSite;
  console.log(boostdata);
  if (sightID) {
    await database.ref("sights/" + sightID + "/settings/boostRoomTypes").set(boostdata);
  }
};

export const saveBuildingDataSettings = (buildingdata) => async (dispatch, getState) => {
  const state = getState();

  const sightID = state.sightsData.selectedSite;

  if (sightID) {
    await database.ref("sights/" + sightID + "/settings/youtube").set(buildingdata.youtube);
    if (typeof buildingdata.leaderboard !== "undefined") {
      await database.ref("sights/" + sightID + "/settings/leaderboard").set(buildingdata.leaderboard);
    }
    await database.ref("sights/" + sightID + "/settings/leaderboardOff").set(buildingdata.leaderboardOff);
    if (typeof buildingdata.boostEnabled !== "undefined") {
      await database.ref("sights/" + sightID + "/settings/boostEnabled").set(buildingdata.boostEnabled);
    }
  }
};

export const saveCalendarSettings = (calendardata) => async (dispatch, getState) => {
  const state = getState();

  const sightID = state.sightsData.selectedSite;

  if (sightID) {
    await database.ref("sights/" + sightID + "/settings/calendarSetAwayTemp").set(calendardata);
  }
};

export const saveMobileViewSettings = (mobileview) => async (dispatch, getState) => {
  const state = getState();

  const sightID = state.sightsData.selectedSite;

  if (sightID) {
    await database.ref("sights/" + sightID + "/settings/mobileViewMinTemp").set(mobileview.minTemp || 15.5);

    await database.ref("sights/" + sightID + "/settings/mobileViewMaxTemp").set(mobileview.maxTemp || 21.5);

    await database.ref("sights/" + sightID + "/settings/isBarsOnMobile").set(mobileview.isBarsOnMobile);

    await database.ref("sights/" + sightID + "/settings/isFreezeAvailable").set(mobileview.isFreezeAvailable);

    await database.ref("sights/" + sightID + "/settings/contactEmail").set(mobileview.contactEmail);

    await database.ref("sights/" + sightID + "/settings/maintenanceMessage").set(mobileview.maintenanceMessage);

    /* dispatch({
      type: FETCH_SETTINGS,
      payload: settings.val(),
      sightID: sightID,
    });*/

    //dispatch(fetchSettings());
  }
};

// export const setSummerMode = (homeId, isHeatingSeason) => async (dispatch) => {
//   console.log("Setting summer mode: " + isHeatingSeason + ", " + homeId);
//   return await database.ref("/sights/" + homeId + "/summerModeActive").set(!isHeatingSeason);
// };

export const changeGatewayDisabledSettings = (gw_id, status) => async (dispatch, getState) => {
  const state = getState();
  const sightID = state.sightsData.selectedSite;
  if (sightID) {
    await database.ref("devices/" + gw_id + "/disabled").set(status ? true : null);
    dispatch({
      type: GATEWAY_DISABLED_CHANGE,
      payload: {
        gw_id: gw_id,
        status: status,
      },
    });
  }
};

export const changeGatewayRestartSettings = (gw_id, status) => async (dispatch, getState) => {
  const state = getState();
  const sightID = state.sightsData.selectedSite;
  if (sightID) {
    await database.ref("buildings/" + sightID + "/devices/" + gw_id + "/sim_restart_enabled").set(status);
    await database.ref("devices/" + gw_id + "/sim_restart_enabled").set(status);
    dispatch({
      type: GATEWAY_RESTART_CHANGE,
      payload: {
        gw_id: gw_id,
        status: status,
      },
    });
  }
};

export const saveFreezeTemperature = (freezeTemp) => async (dispatch, getState) => {
  const state = getState();

  const sightID = state.sightsData.selectedSite;

  if (sightID) {
    await database.ref("sights/" + sightID + "/settings/freezeProtectionTemp").set(freezeTemp);
  }
};

export const getFreezeTemperature = (sightId) => async (dispatch, getState) => {
  if (sightId) {
    const tempSnap = await database.ref("sights/" + sightId + "/setting/freezeProtectionTemp").once("value");
    const temp = tempSnap.val();
    return temp;
  }
};

export const saveCustomNotificationSettings = (settings, email) => async (dispatch, getState) => {
  const state = getState();

  const sightID = state.sightsData.selectedSite;

  if (sightID) {
    await database.ref("sights/" + sightID + "/notifications_settings/" + email).set(settings);
  }
};

export const fetchCustomSchedule = (start, end) => async (dispatch) => {
  return await database.ref("custom_schedule").orderByKey().startAt(start).endAt(end).once("value");
};

export const selectSight = (id) => async (dispatch) => {
  const url = await storage
    .child("sigths/" + id + "/floorplan.jpg")
    .getDownloadURL()
    .catch((err) => {
      /**
       * We do not use foorplan.jpg-s any more like this.
       * @obsolete
       * will need to remove
       */
      // console.log(err);
    });

  const allFloors = await database.ref("buildingtopology").once("value");
  const selectedSightFloors = allFloors.val()[`${id}`];
  const homes = await database.ref("sights/" + id + "/home_ids").once("value");
  const homesObj = homes.val();
  const homeid = Object.keys(homesObj)[0];

  const deviceSnap = await database.ref("devices").once("value");

  const devices = deviceSnap.val();

  const selectedDevices = Object.keys(devices).filter((key) => devices[key].home_id === homeid);
  const selectedGateways = Object.keys(devices).filter(
    (key) => devices[key].homeId === homeid && devices[key].type === "gateway"
  );
  const positionSnap = await database.ref("device_positions").once("value");
  const positions = positionSnap.val();

  const selectedPositions = Object.entries(positions).filter((key) => selectedDevices.includes(key[0]));
  const selectedGatewaysPositions = Object.entries(positions).filter((key) => selectedGateways.includes(key[0]));
  const roomsSnap = await database.ref("rooms/").orderByChild("sight_id").equalTo(id.toString()).once("value");
  const rooms = roomsSnap.val();

  const bookedSnap = await database.ref("booked-history").orderByKey().equalTo(id.toString()).once("value");
  let bookedHistory;
  if (bookedSnap.exists()) {
    bookedHistory = bookedSnap.val();
  }
  const timezoneSnap = await database.ref(`/sights/${id}/settings/timezone`).once("value");
  let timezone = "Europe/London";
  if (timezoneSnap.exists()) {
    timezone = timezoneSnap.val();
  }

  const d = await database.ref(`/sights/${id}/client`).once("value");
  let partner_logo;
  if (d.exists()) {
    const c = await database.ref(`/clients/${d.val()}/partner`).once("value");
    const partnerLogoSnap = c.exists() && (await database.ref(`/partners/${c.val()}/logo`).once("value"));
    if (partnerLogoSnap && partnerLogoSnap.exists()) {
      partner_logo = partnerLogoSnap.val();
    }
  }

  dispatch({
    type: SELECT_SIGHT,
    payload: id,
    floorplanURL: url,
    device_positions: selectedPositions,
    gateways_position: selectedGatewaysPositions,
    deviceData: devices,
    rooms: rooms,
    selectedSightFloors: selectedSightFloors,
    bookedHistory: bookedHistory,
    timezone: timezone,
    partner_logo: partner_logo,
  });
};
export const selectClient = (id, uid) => async (dispatch, getState) => {
  const userSnap = await database.ref("users/" + uid).once("value");

  const user = userSnap.val();

  const snap = await database.ref("sights").once("value");

  dispatch({ type: SELECT_CLIENT, payload: id });

  dispatch({
    type: FETCH_SIGHTS,
    superAdmin: !!user.isSuperAdmin,
    clientAdmin: !!user.isClientAdmin,
    uc: user.client,
    selectedClient: id,
    uid: uid,
    payload: snap.val(),
  });
};

export const subscribeToSightNotifications = (sightID, prevSightID) => async (dispatch) => {
  dispatch({
    type: FETCH_NOTIFICATIONS,
  });

  //console.log("unsubscribe " + prevSightID);
  let vn = [];
  const vnSnap = await database.ref("visible_notifications").once("value");
  if (vnSnap.exists()) {
    vn = vnSnap.val();
  }
  const dnSnap = await database.ref("sights/" + sightID + "/disabled_notifications").once("value");

  if (dnSnap.exists()) {
    const dn = dnSnap.val();
    vn = vn.filter((v) => !dn.includes(v));
  }

  let nt = [];
  const ntSnap = await database.ref("notifications_texts").once("value");
  if (ntSnap.exists()) {
    nt = ntSnap.val();
  }
  let veh = [];
  const vehSnap = await database.ref("visible_events_in_history").once("value");
  if (ntSnap.exists()) {
    veh = vehSnap.val();
  }
  database.ref("sights/" + prevSightID).off();
  let notifications_ack = [];
  //console.log("subscribe " + sightID);
  const executeBigQuery = functions.httpsCallable("getAckNotifications");
  executeBigQuery({ buildingId: sightID })
    .then((result) => {
      notifications_ack = result.data;
      database.ref("sights/" + sightID).on("value", function (snapshot) {
        dispatch({
          type: FETCH_NOTIFICATIONS,
          payload: snapshot.val(),
          visibleNotifications: vn,
          visibleEventsInHistory: veh,
          notificationTexts: nt,
          notifications_ack: notifications_ack,
        });
      });
    })
    .catch((error) => {
      console.error(error);
    });
};

export const getFreezeTemp = (homeId) => async () => {
  try {
    const freezeTempSnap = await database.ref("sights/" + homeId + "/settings/freezeProtectionTemp").once("value");
    return freezeTempSnap.val();
  } catch (err) {
    return err;
  }
};

export const resolveNotification =
  ({ sightId, id, resolved_at, by }) =>
  async (dispatch) => {
    try {
      const resolveNotificationCall = functions.httpsCallable("resolveNotification");
      await resolveNotificationCall({ sight_id: sightId, id, by, resolved_at });

      return true;
    } catch (error) {
      return error;
    }
  };

export const saveSchedules = (updates) => async (dispatch) => {
  return await database.ref().update(updates);
};

export const setSummerMode = (homeId, isHeatingSeason) => async (dispatch) => {
  console.log("Setting summer mode: " + isHeatingSeason + ", " + homeId);
  return await database.ref("/sights/" + homeId + "/summerModeActive").set(!isHeatingSeason);
};

export const subscribeToRoomsAndDevices = (sightID) => async (dispatch) => {
  //to default
  dispatch({
    type: FETCH_ROOMS,
  });
  dispatch({
    type: FETCH_DEVICES,
  });

  dispatch({
    type: FETCH_CALENDARS,
  });

  await database.ref("rooms/").off();
  await database.ref("devices/").off();
  await database.ref("room_calendar/").off();

  const positions = await database.ref("room_positions/").once("value");
  const roomTypes = await database.ref("room_types/").once("value");
  database
    .ref("rooms/")
    .orderByChild("sight_id")
    .equalTo(sightID.toString())
    .on("value", function (snapshot) {
      dispatch({
        type: FETCH_ROOMS,
        payload: snapshot.val(),
        sightID: sightID.toString(),
        positions: positions.val(),
        roomTypes: roomTypes.val(),
      });
    });
  database
    .ref("room_calendar/")
    .orderByChild("sight_id")
    .equalTo(sightID)
    .on("value", function (snapshot) {
      // console.log(snapshot.val())
      const data = snapshot.val();
      if (data) {
        Object.keys(data).forEach((key) => {
          if (data[key].calendarmap && Array.isArray(data[key].calendarmap)) {
            data[key].calendarmap.sort((a, b) => a.start - b.start);
          }
        });
        dispatch({
          type: FETCH_CALENDARS,
          calendars: data,
          sightID: sightID.toString(),
        });
      }
    });

  const devicePositions = await database.ref("device_positions/").once("value");

  database
    .ref("devices/")
    .orderByChild("sight_id")
    .equalTo(sightID.toString())
    .on("value", function (snapshot) {
      dispatch({
        type: FETCH_DEVICES,
        payload: snapshot.val(),
        sightID: sightID.toString(),
        positions: devicePositions.val(),
      });
    });
};

export const fetchManuals = (id) => async (dispatch) => {
  let manual = null;
  let students = null;
  let printable_relay = null;
  let printing_qr = null;
  let printable_qrs = null;
  let printable_fix_qrs = null;
  let flyer = null;
  let booster = null;
  try {
    if (id.toString().startsWith("micropelt")) {
      manual = await storage
        .child("manuals/general_micropelt_manuals/ecosync_unified_startup_manual.pdf")
        .getDownloadURL();
    } else {
      manual = await storage.child("manuals/" + id + "/manual.pdf").getDownloadURL();
    }
  } catch (err) {}
  try {
    students = await storage.child("manuals/" + id + "/students.pdf").getDownloadURL();
  } catch (err) {}

  try {
    printable_relay = await storage.child("manuals/" + id + "/printable_relay.pdf").getDownloadURL();
  } catch (err) {}

  try {
    printing_qr = await storage.child("manuals/general/printing_qr_codes.pdf").getDownloadURL();
  } catch (err) {}

  try {
    printable_qrs = await storage.child("manuals/" + id + "/printable_qrs.pdf").getDownloadURL();
  } catch (err) {}

  try {
    printable_fix_qrs = await storage.child("manuals/" + id + "/printable_fix_qrs.pdf").getDownloadURL();
  } catch (err) {}

  try {
    flyer = await storage.child("manuals/general/ecosync_student_flyer_digital.pdf").getDownloadURL();
  } catch (err) {}
  try {
    booster = await storage.child("manuals/general/ecosync_booster.pdf").getDownloadURL();
  } catch (err) {}

  dispatch({
    type: FETCH_MANUALS,
    payload: {
      students: students,
      manual: manual,
      printable_relay: printable_relay,
      printing_qr: printing_qr,
      printable_qrs: printable_qrs,
      printable_fix_qrs: printable_fix_qrs,
      flyer: flyer,
      booster: booster,
      any: students || manual || printable_qrs || printable_fix_qrs || printing_qr || printable_relay || flyer,
    },
  });
};
const addEventToBigQuery = async (event) => {
  const bigquery = new BigQuery();
  const datasetName = "notifications";
  const tableName = "events";

  const dataset = bigquery.dataset(datasetName);
  const table = dataset.table(tableName);

  let bqObj = {
    UUID: event.id,
    building_id: event.homeId,
    room_id: event.roomId,
    type: event.type,
    user: event.created_by,
    timestamp: event.created_at,
    payload: JSON.stringify({
      data: typeof event.data !== "undefined" ? event.data : null,
      text: typeof event.text !== "undefined" ? event.text : null,
      name: typeof event.roomName !== "undefined" ? event.roomName : null,
    }),
  };
  if (typeof event.closesId !== "undefined") {
    bqObj.closes_UUID = event.closesId;
  }
  const rows = [bqObj];
  const options = { skipInvalidRows: true };
  try {
    const [apiResponse] = await table.insert(rows, options);
    // functions.logger.info(`Inserted ${rows.length} row(s)`);
    if (apiResponse.insertErrors && apiResponse.insertErrors.length > 0) {
      console.error(`Error inserting rows: ${JSON.stringify(apiResponse.insertErrors)}`);
    }
  } catch (err) {
    if (err.name === "PartialFailureError") {
      console.error(`Error inserting rows: ${JSON.stringify(err.errors)}`);
    } else {
      console.error(`Error inserting rows: ${err}`);
    }
  }
};
/**
 * Frontend event-history writing
 * @param homeId: string
 * @param roomId: string
 * @param roomName: string|null
 * @param type: int
 * @param user: string|object
 * @param created_at: timestamp
 * @param text: string|null
 * @returns {Promise<*>}
 */
export const addRoomEventFrontend = async (
  homeId,
  roomId,
  roomName = null,
  type,
  user = SETTER_USER_DEFAULT,
  created_at = null,
  text = null
) => {
  let event = {
    created_at: created_at ? created_at : moment().unix(),
    roomName: roomName,
    homeId: homeId,
    roomId: roomId,
    type: type,
    text: text,
    live: true,
    created_by: typeof user === "object" ? user["email"] : user,
  };
  await addEventToBigQuery(event);
  return database.ref("event-history/" + event.homeId + "/" + event.roomId + "/" + event.created_at).set(event);
};
