import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import { DialogTitle, DialogContent, DialogActions, Dialog, Switch } from "@mui/material";
import axios from "axios";
import { functions } from "../../config/firebase";
import { changeGatewayDisabledSettings, changeGatewayRestartSettings } from "../../actions";

const styles = (theme) => ({
  dialog: {
    minwidth: "300",
  },
  dialogContent: {
    display: "flex",
    marginBottom: 15,
    marginLeft: 10,
    marginRight: 10,
    padding: 10,
  },
  info: {
    fontSize: 14,
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
    },
  },
  info2: {
    fontSize: 14,
    marginLeft: 5,
    [theme.breakpoints.down("xs")]: {
      fontSize: 10,
    },
  },
});

function GatewayDialog(props) {
  const dispatch = useDispatch();
  const { classes, gateway, isSuperAdmin, isOpen, setDialog } = props;
  const [connectivity, setConnectivity] = useState("");
  const [lastPingTime, setLastPingTime] = useState("");
  const [gwDisabled, setGwDisabled] = useState(gateway.disabled);
  const [gwRestart, setGwRestart] = useState(gateway.sim_restart_enabled);

  useEffect(() => {
    const getConnectivity = async () => {
      const data = await axios
        .get(`https://room.mt/gw_online/?debug=1&ami=${gateway.name.toLowerCase()}`)
        .then((resp) => {
          return resp;
        })
        .catch((err) => {
          console.log(err);
        });
      if (data) {
        if (data.data.includes("CELLULAR")) {
          setConnectivity("CELLULAR");
        } else if (data.data.includes("ETHERNET")) {
          setConnectivity("ETHERNET");
        } else {
          setConnectivity("Nothing connected!");
        }
        setLastPingTime(extractPingTime(data.data));
      }
    };
    getConnectivity();
  }, [gateway.name]);

  useEffect(() => {
    setGwDisabled(gateway.disabled);
    setGwRestart(gateway.sim_restart_enabled);
  }, [gateway]);

  const extractPingTime = (data) => {
    return data.split("Last ping time: ")[1].split("<")[0];
  };

  const rebootGW = async () => {
    let restartSiteGws = functions.httpsCallable("restartSiteGws");
    try {
      await restartSiteGws({
        sight_id: gateway.homeId,
        gw_id: gateway.deviceEUI,
      });
      setDialog(false);
      alert("Reboot command sent!");
    } catch (e) {}
  };

  const gwDisabledChange = (event) => {
    setGwDisabled((state) => !state);
    dispatch(changeGatewayDisabledSettings(gateway.deviceEUI, event.target.checked));
  };

  const gwRestartChange = (event) => {
    setGwRestart((state) => !state);
    dispatch(changeGatewayRestartSettings(gateway.deviceEUI, event.target.checked));
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => setDialog(false)}
      sx={{ "& .MuiDialog-paper": { width: "25%", minWidth: "360px" } }}
    >
      <DialogTitle id="alert-dialog-title">Gateway</DialogTitle>

      <DialogContent sx={{ display: "flex", padding: "10px 24px" }}>
        <Typography className={classes.info}>Gateway Name :</Typography>
        <Typography className={classes.info2}>{gateway.name}</Typography>
      </DialogContent>
      <DialogContent sx={{ display: "flex", padding: "10px 24px" }}>
        <Typography className={classes.info}>Gateway Status :</Typography>
        <Typography className={classes.info2}>
          {gateway.isOffline ? <>Offline({lastPingTime})</> : <>Online</>}
        </Typography>
      </DialogContent>
      <DialogContent sx={{ display: "flex", padding: "10px 24px" }}>
        <Typography className={classes.info}>Gateway Mac :</Typography>
        <Typography className={classes.info2}>{gateway.mac}</Typography>
      </DialogContent>
      <DialogContent sx={{ display: "flex", padding: "10px 24px" }}>
        <Typography className={classes.info}>Gateway Connectivity :</Typography>
        <Typography className={classes.info2}>{connectivity}</Typography>
      </DialogContent>
      {isSuperAdmin ? (
        <>
          <DialogContent sx={{ display: "flex", padding: "10px 24px" }}>
            <Typography className={classes.info}>Lora EUI :</Typography>
            <Typography className={classes.info2}>{gateway.deviceEUI}</Typography>
          </DialogContent>
          <DialogContent sx={{ display: "flex", padding: "10px 24px" }}>
            <Typography className={classes.info}>Phone Number :</Typography>
            <Typography className={classes.info2}>{gateway.sim_number}</Typography>
          </DialogContent>
          <DialogContent sx={{ display: "flex", alignItems: "center", padding: "10px 24px" }}>
            <Typography className={classes.info}>Gateway Disabled :</Typography>
            <Switch
              checked={gwDisabled}
              onChange={(e) => gwDisabledChange(e)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </DialogContent>
          <DialogContent sx={{ display: "flex", alignItems: "center", padding: "10px 24px" }}>
            <Typography className={classes.info}>Gateway Restart Enabled :</Typography>
            <Switch
              checked={gwRestart}
              onChange={(e) => gwRestartChange(e)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </DialogContent>
        </>
      ) : (
        <></>
      )}
      <DialogActions>
        <Button onClick={() => setDialog(false)}>Close</Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={!gwRestart || gwDisabled}
          onClick={async () => {
            rebootGW();
          }}
        >
          Reboot
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(GatewayDialog);
