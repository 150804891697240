import React, { useState } from "react";
import { mdiWifi } from "@mdi/js";
import Icon from "@mdi/react";
import { withStyles } from "@material-ui/core/styles";

import GatewayDialog from "../GatewayDialog";

const styles = (theme) => ({
  gateway: {
    position: "absolute",
    borderRadius: "50%",
    zIndex: 3,
    padding: "10px",
    transform: "translate(-50%, -50%)",
    width: "48px",
    height: "48px",
    border: "2px solid transparent",
    zoom: 1,
    color: "#ff4081",
    "&:hover": {
      border: "2px solid #ff4081",
    },
  },
});

function Gateway(props) {
  const { top, left, classes, gateway, isSuperAdmin } = props;
  const [open, setOpen] = useState(false);
  return (
    <>
      <Icon
        path={mdiWifi}
        horizontal
        vertical
        rotate={180}
        className={classes.gateway}
        style={{
          left: `${left}px`,
          top: `${top}px`,
        }}
        onClick={() => setOpen(true)}
      />
      <GatewayDialog gateway={gateway} isSuperAdmin={isSuperAdmin} isOpen={open} setDialog={() => setOpen(false)} />
    </>
  );
}

export default withStyles(styles)(Gateway);
