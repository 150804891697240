import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Button from "@material-ui/core/Button";
import { FormControlLabel, Checkbox } from "@mui/material";

import Snackbar from "@material-ui/core/Snackbar";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import _ from "lodash";
import { fetchScheduleData, saveSchedules } from "../../actions";
import InfoTooltip from "../Common/InfoTooltip";

const style = {
  float: "left",
  width: 110,
  height: 650,
  marginLeft: 35,
  marginTop: 10,
  marginBottom: 150,
};
const parentStyle = { overflow: "hidden" };

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    float: "right",
    marginLeft: 20,
  },
});

const marks = {
  0: <strong>0:00</strong>,
  1: "1:00",
  2: "2:00",
  3: "3:00",
  4: "4:00",
  5: "5:00",
  6: "6:00",
  7: "7:00",
  8: "8:00",
  9: <strong>9:00</strong>,
  10: "10:00",
  11: "11:00",
  12: "12:00",
  13: "13:00",
  14: "14:00",
  15: "15:00",
  16: "16:00",
  17: <strong>17:00</strong>,
  18: "18:00",
  19: "19:00",
  20: "20:00",
  21: "21:00",
  22: "22:00",
  23: "23:00",
  24: {
    label: <strong>23:59</strong>,
  },
};
/*
let def_timetable = [
  { zone_id: 1, m_offset: 0 },
  { zone_id: 0, m_offset: 510 },
  { zone_id: 1, m_offset: 1050 },
  { zone_id: 0, m_offset: 1950 },
  { zone_id: 1, m_offset: 2490 },
  { zone_id: 0, m_offset: 3390 },
  { zone_id: 1, m_offset: 3930 },
  { zone_id: 0, m_offset: 4830 },
  { zone_id: 1, m_offset: 5370 },
  { zone_id: 0, m_offset: 6270 },
  { zone_id: 1, m_offset: 6810 }
];
*/

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  didMount: (id) => dispatch(fetchScheduleData(id)),
  saveSchedules: (updates) => dispatch(saveSchedules(updates)),
});

class SettingsSchedule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      open: false,
      show: false,
      scheduleIDs: [],
      editable: this.props.userData.isAdmin || this.props.userData.isSuperAdmin,
      isChanged: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.sightsData.selectedSite !== prevProps.sightsData.selectedSite) {
      this.props.didMount(this.props.sightsData.selectedSite);
    }

    if (this.props.schedulesData.schedule !== prevProps.schedulesData.schedule) {
      if (this.props.schedulesData.schedule) {
        this.setState({
          ranges: this.convertToShow(this.props.schedulesData.schedule),
          show: true,
        });
      } else {
        this.setState({
          show: false,
        });
      }
    }

    if (this.props.userData.isAdmin !== prevProps.userData.isAdmin) {
      this.setState({
        editable: this.props.userData.isAdmin || this.props.userData.isSuperAdmin,
      });
    }
  }

  componentDidMount() {
    this.props.didMount(this.props.sightsData.selectedSite);

    if (this.props.schedulesData.schedule) {
      this.setState({
        ranges: this.convertToShow(this.props.schedulesData.schedule),
        show: true,
      });
    }
  }

  convertToShow = (timetable) => {
    let timetableObj = {
      0: [0, 0],
      1: [0, 0],
      2: [0, 0],
      3: [0, 0],
      4: [0, 0],
      5: [0, 0],
      6: [0, 0],
    };

    for (let index = 0; index < timetable.length; index++) {
      const element = timetable[index];
      const elementNext = timetable[index + 1];

      if (element.zone_id === 0) {
        let k = Math.floor(element.m_offset / 60 / 24);
        if (timetableObj[k][0] === 0 && timetableObj[k][1] === 0) {
          timetableObj[k][0] = element.m_offset / 60 - k * 24;
          timetableObj[k][1] = elementNext.m_offset / 60 - k * 24;
        } else {
          timetableObj[k][2] = element.m_offset / 60 - k * 24;
          timetableObj[k][3] = elementNext.m_offset / 60 - k * 24;
        }
      }
    }
    return timetableObj;
  };

  convertBackTo = (timetableObj) => {
    let newTimeTable = [{ zone_id: 1, m_offset: 0 }];
    for (let index = 0; index < 7; index++) {
      const element = timetableObj[index];
      let lOffset = 0;
      if (element[0] === 0) {
        lOffset = 1;
      }
      if (element[0] !== 0 || element[1] !== 0) {
        newTimeTable.push({
          zone_id: 0,
          m_offset: lOffset + element[0] * 60 + 24 * 60 * index,
        });
        newTimeTable.push({
          zone_id: 1,
          m_offset: element[1] * 60 + 24 * 60 * index,
        });
      }
      if (element.length === 4) {
        if (element[2] !== 0 || element[3] !== 0) {
          newTimeTable.push({
            zone_id: 0,
            m_offset: lOffset + element[2] * 60 + 24 * 60 * index,
          });
          newTimeTable.push({
            zone_id: 1,
            m_offset: element[3] * 60 + 24 * 60 * index,
          });
        }
      }
    }

    return newTimeTable;
  };

  saveData = async () => {
    let newTimeTable = this.convertBackTo(this.state.ranges);

    let updates = {};
    _.each(this.props.schedulesData.schedules, (item) => {
      updates["schedules/" + item.id + "/timetable"] = newTimeTable;
    });

    updates["schedule/" + this.props.sightsData.selectedSite + "/default"] = newTimeTable;

    try {
      await this.props.saveSchedules(updates);
      this.setState({
        open: true,
      });
    } catch (error) {
      alert("error: " + error);
    }
    this.setState({ isChanged: false });
    this.props.setHasChanges(false);
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange = (key) => (value) => {
    let obj = this.state.ranges;
    if (value.length === 4) {
      if (value[1] === 24) {
        value[1] = 23.99;
      }
      if (value[3] === 24) {
        value[3] = 23.99;
      }
    } else {
      if (value[1] === 24) {
        value[1] = 23.99;
      }
    }
    obj[key] = value;
    this.setState({
      ranges: obj,
      isChanged: true,
    });
    this.props.setHasChanges(true);
  };

  showTime = (range) => {
    if (range.length === 4) {
      return (
        moment
          .utc()
          .startOf("day")
          .add(range[0] * 60, "minutes")
          .format("HH:mm") +
        " - " +
        moment
          .utc()
          .startOf("day")
          .add(range[1] * 60, "minutes")
          .format("HH:mm") +
        " " +
        moment
          .utc()
          .startOf("day")
          .add(range[2] * 60, "minutes")
          .format("HH:mm") +
        " - " +
        moment
          .utc()
          .startOf("day")
          .add(range[3] * 60, "minutes")
          .format("HH:mm")
      );
    }
    return (
      moment
        .utc()
        .startOf("day")
        .add(range[0] * 60, "minutes")
        .format("HH:mm") +
      " - " +
      moment
        .utc()
        .startOf("day")
        .add(range[1] * 60, "minutes")
        .format("HH:mm")
    );
  };

  handleRangeChange = (event, value) => {
    if (event.target.checked) {
      const additionalRange = [23, 24];
      if (this.state.ranges[value][1] > 22) {
        if (this.state.ranges[value][0] > 21) {
          this.state.ranges[value][0] = 21;
        }
        this.state.ranges[value][1] = 22;
      }
      this.state.ranges[value] = [...this.state.ranges[value], ...additionalRange];
      this.setState({ ...this.state });
    } else {
      this.state.ranges[value].splice(2, 2);
      this.setState({ ...this.state });
    }
  };

  render() {
    const { classes } = this.props;
    const { ranges, open, show, editable } = this.state;

    return (
      <div style={parentStyle}>
        <InfoTooltip text="Use this tab to schedule the automatic change over between temperatures defined under Default Temperature Settings" />

        {!show ? (
          <CircularProgress className="progress" />
        ) : (
          <div>
            <div style={style}>
              <Typography variant="subtitle2" style={{ marginBottom: 20 }}>
                Monday
              </Typography>
              <Typography variant="body2" style={{ marginBottom: 20, height: 40 }}>
                {this.showTime(this.state.ranges[0])}
              </Typography>
              <Slider.Range
                vertical
                reverse={true}
                min={0}
                max={24}
                id={0}
                marks={marks}
                step={0.5}
                onChange={this.handleChange(0)}
                value={ranges[0]}
                trackStyle={[
                  { backgroundColor: "#00838f" },
                  { backgroundColor: "transparent" },
                  { backgroundColor: "#00838f" },
                ]}
                handleStyle={[
                  { borderColor: "00838f" },
                  { borderColor: "#00838f" },
                  { borderColor: "#00838f" },
                  { borderColor: "#00838f" },
                ]}
                disabled={!editable}
                activeDotStyle={{ borderColor: "#00838f" }}
              />
              <FormControlLabel
                label="Split"
                control={<Checkbox checked={ranges[0].length === 4} onChange={(e) => this.handleRangeChange(e, 0)} />}
                sx={{ mt: 2 }}
              />
            </div>
            <div style={style}>
              <Typography variant="subtitle2" style={{ marginBottom: 20 }}>
                Tuesday
              </Typography>
              <Typography variant="body2" style={{ marginBottom: 20, height: 40 }}>
                {this.showTime(this.state.ranges[1])}
              </Typography>
              <Slider.Range
                vertical
                reverse={true}
                min={0}
                max={24}
                id={1}
                marks={marks}
                step={0.5}
                onChange={this.handleChange(1)}
                value={ranges[1]}
                trackStyle={[
                  { backgroundColor: "#00838f" },
                  { backgroundColor: "transparent" },
                  { backgroundColor: "#00838f" },
                ]}
                handleStyle={[
                  { borderColor: "00838f" },
                  { borderColor: "#00838f" },
                  { borderColor: "#00838f" },
                  { borderColor: "#00838f" },
                ]}
                disabled={!editable}
                activeDotStyle={{ borderColor: "#00838f" }}
              />
              <FormControlLabel
                label="Split"
                control={
                  <Checkbox
                    checked={ranges[1].length === 4 ? true : false}
                    onChange={(e) => this.handleRangeChange(e, 1)}
                  />
                }
                sx={{ mt: 2 }}
              />
            </div>
            <div style={style}>
              <Typography variant="subtitle2" style={{ marginBottom: 20 }}>
                Wednesday
              </Typography>
              <Typography variant="body2" style={{ marginBottom: 20, height: 40 }}>
                {this.showTime(this.state.ranges[2])}
              </Typography>
              <Slider.Range
                vertical
                reverse={true}
                min={0}
                max={24}
                id={2}
                marks={marks}
                step={0.5}
                onChange={this.handleChange(2)}
                value={ranges[2]}
                trackStyle={[
                  { backgroundColor: "#00838f" },
                  { backgroundColor: "transparent" },
                  { backgroundColor: "#00838f" },
                ]}
                handleStyle={[
                  { borderColor: "00838f" },
                  { borderColor: "#00838f" },
                  { borderColor: "#00838f" },
                  { borderColor: "#00838f" },
                ]}
                disabled={!editable}
                activeDotStyle={{ borderColor: "#00838f" }}
              />
              <FormControlLabel
                label="Split"
                control={
                  <Checkbox
                    checked={ranges[2].length === 4 ? true : false}
                    onChange={(e) => this.handleRangeChange(e, 2)}
                  />
                }
                sx={{ mt: 2 }}
              />
            </div>
            <div style={style}>
              <Typography variant="subtitle2" style={{ marginBottom: 20 }}>
                Thursday
              </Typography>
              <Typography variant="body2" style={{ marginBottom: 20, height: 40 }}>
                {this.showTime(this.state.ranges[3])}
              </Typography>
              <Slider.Range
                vertical
                reverse={true}
                min={0}
                max={24}
                id={3}
                marks={marks}
                step={0.5}
                onChange={this.handleChange(3)}
                value={ranges[3]}
                trackStyle={[
                  { backgroundColor: "#00838f" },
                  { backgroundColor: "transparent" },
                  { backgroundColor: "#00838f" },
                ]}
                handleStyle={[
                  { borderColor: "00838f" },
                  { borderColor: "#00838f" },
                  { borderColor: "#00838f" },
                  { borderColor: "#00838f" },
                ]}
                disabled={!editable}
                activeDotStyle={{ borderColor: "#00838f" }}
              />
              <FormControlLabel
                label="Split"
                control={
                  <Checkbox
                    checked={ranges[3].length === 4 ? true : false}
                    onChange={(e) => this.handleRangeChange(e, 3)}
                  />
                }
                sx={{ mt: 2 }}
              />
            </div>
            <div style={style}>
              <Typography variant="subtitle2" style={{ marginBottom: 20 }}>
                Friday
              </Typography>
              <Typography variant="body2" style={{ marginBottom: 20, height: 40 }}>
                {this.showTime(this.state.ranges[4])}
              </Typography>
              <Slider.Range
                vertical
                reverse={true}
                min={0}
                max={24}
                id={4}
                marks={marks}
                step={0.5}
                onChange={this.handleChange(4)}
                value={ranges[4]}
                trackStyle={[
                  { backgroundColor: "#00838f" },
                  { backgroundColor: "transparent" },
                  { backgroundColor: "#00838f" },
                ]}
                handleStyle={[
                  { borderColor: "00838f" },
                  { borderColor: "#00838f" },
                  { borderColor: "#00838f" },
                  { borderColor: "#00838f" },
                ]}
                disabled={!editable}
                activeDotStyle={{ borderColor: "#00838f" }}
              />
              <FormControlLabel
                label="Split"
                control={
                  <Checkbox
                    checked={ranges[4].length === 4 ? true : false}
                    onChange={(e) => this.handleRangeChange(e, 4)}
                  />
                }
                sx={{ mt: 2 }}
              />
            </div>
            <div style={style}>
              <Typography variant="subtitle2" style={{ marginBottom: 20 }}>
                Saturday
              </Typography>
              <Typography variant="body2" style={{ marginBottom: 20, height: 40 }}>
                {this.showTime(this.state.ranges[5])}
              </Typography>
              <Slider.Range
                vertical
                reverse={true}
                min={0}
                max={24}
                id={5}
                marks={marks}
                step={0.5}
                onChange={this.handleChange(5)}
                value={ranges[5]}
                trackStyle={[
                  { backgroundColor: "#00838f" },
                  { backgroundColor: "transparent" },
                  { backgroundColor: "#00838f" },
                ]}
                handleStyle={[
                  { borderColor: "00838f" },
                  { borderColor: "#00838f" },
                  { borderColor: "#00838f" },
                  { borderColor: "#00838f" },
                ]}
                disabled={!editable}
                activeDotStyle={{ borderColor: "#00838f" }}
              />
              <FormControlLabel
                label="Split"
                control={
                  <Checkbox
                    checked={ranges[5].length === 4 ? true : false}
                    onChange={(e) => this.handleRangeChange(e, 5)}
                  />
                }
                sx={{ mt: 2 }}
              />
            </div>
            <div style={style}>
              <Typography variant="subtitle2" style={{ marginBottom: 20 }}>
                Sunday
              </Typography>
              <Typography variant="body2" style={{ marginBottom: 20, height: 40 }}>
                {this.showTime(this.state.ranges[6])}
              </Typography>
              <Slider.Range
                vertical
                reverse={true}
                min={0}
                max={24}
                id={6}
                marks={marks}
                step={0.5}
                onChange={this.handleChange(6)}
                value={ranges[6]}
                trackStyle={[
                  { backgroundColor: "#00838f" },
                  { backgroundColor: "transparent" },
                  { backgroundColor: "#00838f" },
                ]}
                handleStyle={[
                  { borderColor: "00838f" },
                  { borderColor: "#00838f" },
                  { borderColor: "#00838f" },
                  { borderColor: "#00838f" },
                ]}
                disabled={!editable}
                activeDotStyle={{ borderColor: "#00838f" }}
              />
              <FormControlLabel
                label="Split"
                control={
                  <Checkbox
                    checked={ranges[6].length === 4 ? true : false}
                    onChange={(e) => this.handleRangeChange(e, 6)}
                  />
                }
                sx={{ mt: 2 }}
              />
            </div>
          </div>
        )}
        {editable && (
          <Button
            onClick={this.saveData}
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={this.props.viewOnly}
          >
            Save
          </Button>
        )}

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={open}
          onClose={this.handleClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">Default schedule has been saved.</span>}
          autoHideDuration={3000}
        />
      </div>
    );
  }
}

SettingsSchedule.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SettingsSchedule));
