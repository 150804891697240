import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import NotificationIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
// import Chip from "@material-ui/core/Chip";
import ConfirmDialog from "../ConfirmDialog";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {
  logout,
  selectSight,
  selectClient,
  subscribeToSightNotifications,
  subscribeToRoomsAndDevices,
} from "../../actions";
import Drawer from "@material-ui/core/Drawer";
import Notifications from "../Notifications";
import { functions } from "../../config/firebase";
const {
  FIRE,
  OPEN_WINDOW,
  NO_HEATING,
  NO_HEATING_CONTROL,
  STUCK_PIN,
  OFFLINE_TRV,
  OFFLINE_GW,
  MECHANICAL_ERROR,
  CELLULAR_GW,
  REFERENCE_RUN,
  MANUAL_REFERENCE_RUN,
  EXTRA_HEAT_SOURCE,
  AWAY_LOW_TEMP,
} = require("../../constants/events");

const styles = (theme) => ({
  root: {
    width: "100%",
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
      marginLeft: -10,
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    button: {
      color: "#fff",
      marginLeft: 10,
    },
  },
  chip: {
    margin: theme.spacing(1),
  },
  formControl: {
    minWidth: 170,
    marginRight: 16,
    marginTop: 5,
  },
  select: {
    color: "#ffffff",
  },
  icon: {
    fill: "#ffffff",
  },
  drawer: {
    backgroundColor: "#ECEFF1",
  },
});

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  selectSight: (id) => dispatch(selectSight(id)),
  selectClient: (id, uid) => dispatch(selectClient(id, uid)),
  pageAction: () =>
    dispatch({
      type: "PAGE_CHANGED",
      payload: "Notification",
    }),
  subscribeToSightNotifications: (id, previd) => dispatch(subscribeToSightNotifications(id, previd)),
  subscribeToRoomsAndDevices: (id) => dispatch(subscribeToRoomsAndDevices(id)),
});

class Navigation extends React.Component {
  state = {
    anchorEl: null,
    mobileMoreAnchorEl: null,
    openConfirm: false,
    notificationsCount: 0,
    drawer: false,
    FIRE: FIRE,
    OPEN_WINDOW: OPEN_WINDOW,
    NO_HEATING: NO_HEATING,
    NO_HEATING_CONTROL: NO_HEATING_CONTROL,
    STUCK_PIN: STUCK_PIN,
    OFFLINE_TRV: OFFLINE_TRV,
    OFFLINE_GW: OFFLINE_GW,
    MECHANICAL_ERROR: MECHANICAL_ERROR,
    CELLULAR_GW: CELLULAR_GW,
    REFERENCE_RUN: REFERENCE_RUN,
    MANUAL_REFERENCE_RUN: MANUAL_REFERENCE_RUN,
    EXTRA_HEAT_SOURCE: EXTRA_HEAT_SOURCE,
    AWAY_LOW_TEMP: AWAY_LOW_TEMP,
  };
  /*openNotification = event => {
    //this.props.pageAction();
    this.props.history.push("/notifications")
  };*/

  toggleDrawer = (open) => () => {
    this.setState({
      drawer: open,
    });
  };

  handleProfileMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = (event) => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleLogout = () => {
    this.handleMenuClose();
    this.setState({
      openConfirm: true,
    });
  };

  confirmHandler = (value) => {
    this.setState({
      openConfirm: false,
    });
    if (value) {
      this.setState({
        anchorEl: null,
      });
      //call logout
      this.props.logout();
      //this.props.history.push("/");
    }
  };
  componentDidMount() {
    if (this.props.sightsData.clients && this.props.authData) {
      // this.handleChangeClient(Object.keys(this.props.sightsData.clients)[0], this.props.authData.uid);
    }
    this.timer = setInterval(() => {
      if (this.state.counter % 16 === 0) {
        this.getCarbonCounter();
      } else {
        const midTime = new Date(new Date().setHours(0, 0, 0, 0));
        const currentTime = new Date();
        const secondsSinceMidnight = parseInt((currentTime.getTime() - midTime.getTime()) / 1000);
        let total = this.state.totalSavingCO2 + (this.state.todaySavingCO2 * secondsSinceMidnight) / (24 * 60 * 60);
        this.setState({ totalSavings: total });
      }
      this.setState({ counter: this.state.counter + 1 });
    }, 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.props.userData.isSuperAdmin &&
      this.props.userData.client &&
      typeof this.props.userData.client !== "string" &&
      this.props.userData.client.length > 1
    ) {
      if (
        this.props.sightsData.sights !== prevProps.sightsData.sights &&
        this.props.sightsData.sights.length &&
        this.props.sightsData.selectedClient
      ) {
        const { selectedClient, sights } = this.props.sightsData;
        const sightsToShow = selectedClient && sights ? sights.filter((s) => s.client === selectedClient) : [];
        if (sightsToShow) this.props.selectSight(sightsToShow[0].id);
      }
    } else if (this.props.sightsData.sights !== prevProps.sightsData.sights && this.props.sightsData.sights.length) {
      this.props.selectSight(this.props.sightsData.sights[0].id);
    }
    if (this.props.sightsData.selectedSite !== prevProps.sightsData.selectedSite) {
      this.props.subscribeToSightNotifications(this.props.sightsData.selectedSite, prevProps.sightsData.selectedSite);
      this.props.subscribeToRoomsAndDevices(this.props.sightsData.selectedSite);
    }
    //Notification counter
    if (this.props.sightsData.notificationsCount !== prevProps.sightsData.notificationsCount) {
      const isMicropelt = this.props.sightsData.selectedSite
        ? this.props.sightsData.selectedSite.toString().startsWith("micropelt")
        : false;
      if (!isMicropelt) {
        this.setState({
          notificationsCount: this.props.sightsData.notificationsCount,
        });
      } else {
        if (this.props.userData.isSuperAdmin) {
          this.setState({
            notificationsCount: this.props.sightsData.notificationsCount,
          });
        } else {
          let num = 0;
          if (this.props.sightsData.visibleNotifications && this.props.sightsData.notifications) {
            this.props.sightsData.visibleNotifications.forEach((notificationType) => {
              Object.values(this.props.sightsData.notifications).forEach((notification) => {
                if (notification.type.toString() === this.state[notificationType].toString()) {
                  num++;
                }
              });
              this.setState({
                notificationsCount: num,
              });
            });
          }
        }
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  getCarbonCounter = async () => {
    let carbonCounter = functions.httpsCallable("getCarbonCounter");
    try {
      await carbonCounter({
        client_id: this.props.sightsData.selectedClient,
      }).then((res) => {
        this.setState({ todaySavingCO2: res.data.todaySavingCO2 });
        this.setState({ totalSavingCO2: res.data.totalSavingCO2 });
        this.setState({ showCounter: true });
      });
    } catch (e) {
      console.log(e);
    }
  };

  handleChange = (event) => {
    if (this.props.sightsData.selectedSite !== event.target.value) {
      this.props.selectSight(event.target.value);
    }
  };

  handleChangeClient = (event) => {
    if (this.props.sightsData.selectedClient !== event.target.value) {
      this.props.selectClient(event.target.value, this.props.authData.uid);
    }
  };
  render() {
    const { anchorEl, mobileMoreAnchorEl, notificationsCount } = this.state;
    const { classes, pageData, userData, sightsData } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const { selectedClient, sights } = sightsData;
    // const isSuperAdmin = userData.isSuperAdmin;
    // const isMicropelt = sightsData.selectedSite ? sightsData.selectedSite.toString().startsWith("micropelt") : false;
    // TODO remove the superAdmin role from the partner;
    const isPartnerAdmin = !userData.isSuperAdmin && typeof userData.client !== "string";
    const clients = userData.isSuperAdmin
      ? sightsData.clients
      : isPartnerAdmin &&
        userData.client &&
        Array.isArray(userData.client) &&
        sightsData.clients.filter((c) => userData.client.includes(c.id));
    const sightsToShow =
      !isPartnerAdmin && !userData.isSuperAdmin
        ? sights
        : selectedClient && sights
        ? sights.filter((s) => s.client === selectedClient)
        : sights;
    const renderMenu = (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        {/*
<MenuItem onClick={this.handleMenuClose}>Profile</MenuItem>
        <MenuItem onClick={this.handleMenuClose}>My account</MenuItem>
      */}

        {this.props.authData && <MenuItem disabled>{this.props.authData.email}</MenuItem>}
        <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
      </Menu>
    );

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMobileMenuOpen}
        onClose={this.handleMobileMenuClose}
      >
        <MenuItem>
          <IconButton color="inherit">
            <Badge badgeContent={11} color="secondary">
              <NotificationIcon />
            </Badge>
          </IconButton>
          <p>Notifications</p>
        </MenuItem>
        <MenuItem onClick={this.handleProfileMenuOpen}>
          <IconButton color="inherit">
            <AccountCircle />
          </IconButton>
          <p>Profile</p>
        </MenuItem>
      </Menu>
    );
    return (
      <div className={classes.root}>
        <Toolbar>
          {!userData.isSuperAdmin && userData.partner_logo ? (
            <a href="https://ecosync.energy">
              {" "}
              <img src={userData.partner_logo} alt={userData.partner} style={{ height: "50px" }} />{" "}
            </a>
          ) : !userData.isSuperAdmin && sightsData.partner_logo ? (
            <a href="https://ecosync.energy">
              {" "}
              <img src={sightsData.partner_logo} alt="partner_logo" style={{ height: "50px" }} />{" "}
            </a>
          ) : (
            <Typography className={classes.title} variant="h6" color="inherit" noWrap>
              EcoSync - {pageData.name}
            </Typography>
          )}

          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {(this.props.userData.isSuperAdmin || isPartnerAdmin) &&
              clients &&
              typeof clients !== "string" &&
              clients.length > 1 && (
                <FormControl className={classes.formControl}>
                  <Select
                    value={this.props.sightsData.selectedClient}
                    onChange={this.handleChangeClient}
                    className={classes.select}
                    inputProps={{
                      name: "client",
                      id: "client-simple",
                      classes: {
                        icon: classes.icon,
                      },
                    }}
                  >
                    {clients &&
                      Object.keys(clients).map((i) => (
                        <MenuItem key={clients[i].id} value={clients[i].id}>
                          {clients[i].name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            {this.props.sightsData.selectedSite && sightsToShow && (
              <FormControl className={classes.formControl}>
                <Select
                  value={this.props.sightsData.selectedSite}
                  onChange={this.handleChange}
                  className={classes.select}
                  inputProps={{
                    name: "name",
                    id: "sight-simple",
                    classes: {
                      icon: classes.icon,
                    },
                  }}
                >
                  {sightsToShow.map((sight) => (
                    <MenuItem key={sight.id} value={sight.id}>
                      {sight.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <IconButton color="inherit" onClick={this.toggleDrawer(true)}>
              {notificationsCount !== 0 && (
                <Badge className={classes.padding} color="secondary" badgeContent={notificationsCount} hidden>
                  <NotificationIcon />
                </Badge>
              )}
              {notificationsCount === 0 && <NotificationIcon />}
            </IconButton>
            <IconButton
              aria-owns={isMenuOpen ? "material-appbar" : undefined}
              aria-haspopup="true"
              onClick={this.handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            {(this.props.userData.isSuperAdmin || isPartnerAdmin) && !this.props.sightsData.selectedSite && (
              <FormControl className={classes.formControl}>
                <Select
                  value={this.props.sightsData.selectedClient}
                  onChange={this.handleChangeClient}
                  className={classes.select}
                  inputProps={{
                    name: "client",
                    id: "client-simple",
                    classes: {
                      icon: classes.icon,
                    },
                  }}
                >
                  {this.props.sightsData.clients &&
                    Object.keys(this.props.sightsData.clients).map((i) => (
                      <MenuItem key={this.props.sightsData.clients[i].id} value={this.props.sightsData.clients[i].id}>
                        {this.props.sightsData.clients[i].name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            {this.props.sightsData.selectedSite && (
              <FormControl className={classes.formControl}>
                <Select
                  value={this.props.sightsData.selectedSite}
                  onChange={this.handleChange}
                  className={classes.select}
                  inputProps={{
                    name: "name",
                    id: "sight-simple",
                    classes: {
                      icon: classes.icon,
                    },
                  }}
                >
                  {this.props.sightsData.sights.map((sight) => (
                    <MenuItem key={sight.id} value={sight.id}>
                      {sight.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
        {!this.props.isViewer && (
          <ConfirmDialog
            open={this.state.openConfirm}
            action={this.confirmHandler}
            text="Clicking OK will log you out. Are you sure?"
          />
        )}

        {renderMenu}
        {renderMobileMenu}

        <Drawer
          anchor="right"
          open={this.state.drawer}
          onClose={this.toggleDrawer(false)}
          classes={{ paper: classes.drawer }}
        >
          <Notifications />
        </Drawer>
      </div>
    );
  }
}

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(Navigation)));
