import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { CircularProgress } from "@mui/material";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import InfoIcon from "@material-ui/icons/Info";
import { IconButton, Tooltip } from "@material-ui/core";
import { fetchManuals, fetchQRCsvData, fetchShortUrls } from "../../actions";
import { functions } from "../../config/firebase";

const styles = (theme) => ({
  root: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16,
    minWidth: 400,
  }),
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  link: {
    textDecoration: "none",
  },
  loadingButton: {
    backgroundColor: "#00838f",
  },
});
const InfoIconButton = (props) => {
  const { tooltipText } = props;

  return (
    <Tooltip title={tooltipText}>
      <IconButton aria-label="info">
        <InfoIcon />
      </IconButton>
    </Tooltip>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  didMount: () =>
    dispatch({
      type: "PAGE_CHANGED",
      payload: "Manuals",
    }),
  fetchManuals: (id) => dispatch(fetchManuals(id)),
  fetchQRCsvData: () => dispatch(fetchQRCsvData()),
  fetchShortUrls: () => dispatch(fetchShortUrls()),
});

class Manuals extends Component {
  constructor(props) {
    super(props);
    this.state = { fixedLoading: false, expiredLoading: false };
  }

  componentDidMount() {
    this.props.didMount();
    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.sightsData.selectedSite !== prevProps.sightsData.selectedSite) {
      this.getData();
    }
  }

  getData() {
    if (this.props.sightsData.selectedSite) {
      this.props.fetchManuals(this.props.sightsData.selectedSite);
      this.props.fetchQRCsvData();
    }
  }

  downloadQrPDF = async (fixed) => {
    if (this.props.sightsData.selectedSite) {
      let selectedBuilding = this.props.sightsData.sights.find(
        (building) => building.id === this.props.sightsData.selectedSite
      );

      let selectedBuildingName = selectedBuilding ? selectedBuilding.name : null;
      let fixedArray;
      if (fixed) {
        this.setState({ fixedLoading: true });
        fixedArray = this.props.sightsData.QRCsvFixData.slice(1);
      } else {
        this.setState({ expiredLoading: true });
        fixedArray = this.props.sightsData.QRCsvData.slice(1);
      }
      let names = [];
      let nums = [];
      fixedArray.map((item) => {
        if (item[1] !== undefined) {
          let url = item[0];
          names.push(item[1]);
          const urlToAppend = url?.split("/").reverse()[0];
          const splitUrl = fixed ? "eco_rmbf/" + urlToAppend : "ers/" + urlToAppend;
          nums.push(encodeURIComponent(encodeURIComponent(splitUrl)));
        }
      });

      try {
        let callQrGenerateApi = functions.httpsCallable("callQrGenerateApi");
        const {
          data: { pdfBase64 },
        } = await callQrGenerateApi({ nums, names, fixed, selectedBuildingName });

        const response = await fetch(`data:application/pdf;base64,${pdfBase64}`);
        await this.displayPDF(response, fixed);
      } catch (error) {
        console.error("Error when calling QR API:", error);
      }
    }
  };

  displayPDF = async (response, fixed) => {
    return response.blob().then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.classList.add("downloadPDF");
      link.href = url;
      link.setAttribute("download", fixed ? "qr_code_labels.pdf" : "qr_code_labels_expiring.pdf");
      document.body.appendChild(link);
      link.click();
      if (fixed) {
        this.setState({ fixedLoading: false });
      } else {
        this.setState({ expiredLoading: false });
      }
    });
  };

  render() {
    const { classes } = this.props;
    const { manuals } = this.props.sightsData;
    const { loading } = this.state;

    return (
      <Paper className={classes.root}>
        <div style={{ position: "relative" }}>
          <a target="_blank" href={manuals.manual} className={classes.link} rel="noopener noreferrer">
            <Button className={classes.button} variant="contained" color="primary" disabled={!manuals.manual}>
              Manual
              <DownloadIcon className={classes.rightIcon} />
            </Button>
          </a>
          <br /> <br />
          <a target="_blank" href={manuals.printing_qr} className={classes.link} rel="noopener noreferrer">
            <Button className={classes.button} variant="contained" color="primary" disabled={!manuals.printing_qr}>
              Printing sticker instructions
              <DownloadIcon className={classes.rightIcon} />
            </Button>
          </a>
          <br /> <br />
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            disabled={!this.props.sightsData.QRCsvData}
            onClick={() => this.downloadQrPDF(false)}
          >
            Expiring QR codes
            {this.state.expiredLoading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              <DownloadIcon className={classes.rightIcon} />
            )}
          </Button>
          <InfoIconButton tooltipText="These QRs display expiring URLs. They only work for a limited amount of time after being scanned. Use this file to print out stickers." />
          <br />
          <br />
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            disabled={!this.props.sightsData.QRCsvFixData}
            onClick={() => this.downloadQrPDF(true)}
          >
            NON-Expiring QR codes
            {this.state.fixedLoading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              <DownloadIcon className={classes.rightIcon} />
            )}
          </Button>
          <InfoIconButton tooltipText="These QRs display non-expiring URLs. They can be bookmarked in the browser for remote access of the room's settings. Use this file to print out stickers." />
          <br /> <br />
          <a target="_blank" href={manuals.flyer} className={classes.link} rel="noopener noreferrer">
            <Button className={classes.button} variant="contained" color="primary" disabled={!manuals.flyer}>
              Information flyer (printout)
              <DownloadIcon className={classes.rightIcon} />
            </Button>
          </a>
          <br /> <br />
          <a target="_blank" href={manuals.booster} className={classes.link} rel="noopener noreferrer">
            <Button className={classes.button} variant="contained" color="primary" disabled={!manuals.booster}>
              Boost information flyer
              <DownloadIcon className={classes.rightIcon} />
            </Button>
          </a>
          <br /> <br />
        </div>
      </Paper>
    );
  }
}

Manuals.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Manuals));
